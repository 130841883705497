import Button from "react-bootstrap/Button";
import React, { Children, cloneElement, useEffect } from "react";
import "../styles/AccountSetUp.css";

/*
 * To-do:
 * ☐ Boolean prop that at least one object is required
 * ☐ Deslect all by clicking outside
 */

/*
 * Props
 * - name: Name of object, used for button (optional, default "Object")
 */

function FormObjectList(props) {
	const [objects, setObjects] = React.useState([{}]); // List of object keys
	const [selectedObject, setselectedObject] = React.useState(-1);
	const [nextKey, setNextKey] = React.useState(1);

	const { object, objectTemplate, group } = props;
	useEffect(()=>{
		setObjects(object)
	},[object])
	// Add new object instance to list
	function handleAdd() {
		const newObjects = [...objects];
		newObjects.push(nextKey);
		setNextKey(nextKey + 1);
		setObjects(newObjects); // Trigger rerender
		object.push(objectTemplate);
	}

	// Select object from list
	function handleSelect(keyToSelect) {
		setselectedObject(keyToSelect); // Trigger rerender
	}

	// Remove given object from list
	function handleRemove() {
		const newObjects = [...objects];
		newObjects.splice(newObjects.indexOf(selectedObject), 1);
		object.splice(object.indexOf(selectedObject), 1);
		setselectedObject(-1); // Deselect object
		setObjects(newObjects); // Trigger rerender
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 24,
			}}
		>
			{/* FormObjects */}
			{objects.map((key, index) => (
				<div
					className={
						selectedObject === key ? "selectedObjectGlow" : ""
					}
					key={key}
					style={{
						padding: 12,
						display: "flex",
						flexDirection: "column",
						gap: 12,
						border: "#4169F0 solid 4px",
						borderRadius: 12,
						backgroundColor: "rgba(47, 69, 143, 10%)",
					}}
					onClick={() => handleSelect(key)}
				>
					{/* FormObject Content */}
					{Children.map(props.children, child =>
						cloneElement(child, { index: index, group: group, value:object })
					)}
				</div>
			))}
			<div style={{ display: "flex", gap: 24 }}>
				<Button variant="secondary" onClick={handleAdd}>
					Add {props.name ? props.name : "Object"}
				</Button>
				<Button
					variant="secondary"
					disabled={selectedObject === -1 || objects.length === 0}
					onClick={handleRemove}
				>
					Remove Selected {props.name ? props.name : "Object"}
				</Button>
			</div>
		</div>
	);
}

export default FormObjectList;
