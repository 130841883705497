import { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";

const AuthenticatedStatus = () => {
    const [isAuthenticated, setAuthStatus] = useState(false);

    const checkAuth = async () => {
        try {  
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            if (authenticatedUser !== undefined) {
                setAuthStatus(true);
            } else {
                setAuthStatus(false);
            }
        } catch {
            setAuthStatus(false);
        }
    }

    useEffect(() => {
        checkAuth();
    });

    useEffect(() => {
        const listener = data => {
            switch(data.payload.event) {
                case 'signIn' || 'autoSignIn' || 'tokenRefresh':
                    setAuthStatus(true);
                    break;
                case 'signOut' || 'signIn_failure' || 'tokenRefresh_failure' || 'autoSignIn_failure':
                default:
                    setAuthStatus(false);
                    break;
            }
        }

        Hub.listen('auth', listener);
    })

    return isAuthenticated;
}

export default AuthenticatedStatus;