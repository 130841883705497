import { combineReducers, configureStore } from "@reduxjs/toolkit";

import jobsReducer from "./reducers/jobsSlice";

const rootReducer = combineReducers({
  jobs: jobsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["jobs/fetchJobs/fulfilled"],
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        ignoredPaths: ["jobs.jobs"],
      },
    }),
});

export default store;
