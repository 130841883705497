import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AccountSetUpPage,
  AppTrackingPage,
  UserInfoPage,
  LoginPage,
  RecPage,
  RegisterPage,
  ForgotPasswordPage,
} from "./pages";
import { AuthenticatedStatus } from "./hooks";
import { useState, useEffect } from "react";

import Redirect from "./components/Redirect";
import { Hub } from "aws-amplify";
import { getAccessToken, getUser } from "./auth/AuthUtils";

const App = () => {
  // eslint-disable-next-line
  const [accessToken, setAccessToken] = useState("");
  const [uuid, setUUID] = useState("");
  const authStatus = AuthenticatedStatus();

  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        getUser((data) => {
          let user = data;

          // setUser(user);
          setUUID(user["attributes"].sub);
        });

        getAccessToken((data) => {
          let token = data;

          setAccessToken(token);
        });
      } catch {
        // setUser(null);
      }
    };

    Hub.listen("auth", updateUser);
    updateUser();

    return () => {};
  }, []);

  return (
    <BrowserRouter>
      <div
        className="App"
        style={{
          display: "flex",
          alignItems: "stretch",
          height: "100vh",
          width: "100%",
        }}
      >
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={!authStatus ? <LoginPage /> : <Redirect url="/jobrec" />}
          />
          <Route
            path="/login"
            element={<LoginPage />}
          />
          <Route
            path="/jobrec"
            element={
              !authStatus ? (
                <Redirect url="/" />
              ) : (
                <RecPage
                  accessToken={accessToken}
                  uuid={uuid}
                />
              )
            }
          />
          <Route
            path="/apptracker"
            element={
              !authStatus ? (
                <Redirect url="/" />
              ) : (
                <AppTrackingPage
                  accessToken={accessToken}
                  uuid={uuid}
                />
              )
            }
          />
          <Route
            path="/userinfo"
            element={
              !authStatus ? (
                <Redirect url="/" />
              ) : (
                <UserInfoPage
                  accessToken={accessToken}
                  uuid={uuid}
                />
              )
            }
          />
          <Route
            path="/letsbegin"
            element={
              <AccountSetUpPage
                accessToken={accessToken}
                uuid={uuid}
              />
            }
          />
          <Route
            path="/register"
            element={<RegisterPage />}
          />
          <Route
            path="/password_reset"
            element={<ForgotPasswordPage />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
