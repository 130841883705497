import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import React, { useEffect } from "react";

/*
 * TO-DO:
 * ☐ 'x' icon on badge hover
 * ☐ Set badge font weight to normal
 * ☐ Make badge dim on hover like button
 * ☐ Replace arrow with plus icon
 *
 * ☐ Boolean prop to sort badges alphabetically or in the order selected
 * ☐ Boolean prop to make dropdown expand upwards
 *
 * ☐ Add "key" prop to remove error
 */

/*
 * Props
 * - options: Elements populated in dropdown
 * - selected: Elements populated in badges (optional, default empty)
 */

function MultiSelectDropdown(props) {
	const [options, setOptions] = React.useState(props.options); // Elements in dropdown
	const [selected, setSelected] = React.useState(
		props.selected ? props.selected : []
	); // Elements in badges
	//const [hover, setHover] = React.useState(-1); // Controls 'x' icon display

	const { group, setSelections } = props;

	// TO-DO: Use options.map((option) => {})
	// Add option to dropdown for every element in 'options'
	const dropdownOptions = []; // React element list
	for (let index in options) {
		dropdownOptions.push(
			<Dropdown.Item title={options[index]} onClick={handleSelect} key={index}>
				{options[index]}
			</Dropdown.Item>
		);
	}

	// Add badge for every element in 'selected'
	const badges = []; // React element list
	for (let index in selected) {
		badges.push(
			<div key={index}>
				<Badge
					pill
					bg="primary"
					key={index}
					title={selected[index]}
					onClick={handleDeselect}
					style={{ cursor: "pointer" }}
				>
					{selected[index]}
				</Badge>
			</div>
		);
	}
	useEffect(() => {
		if (props.selected) {
		  setSelected(props.selected);
		  setOptions(options.filter(option => !props.selected.includes(option)));
		}
	  }, [props.selected]);
	// Remove option from dropdown, add badge
	function handleSelect(event) {
		const newOptions = [...options];
		newOptions.splice(newOptions.indexOf(event.target.title), 1); // Remove selected option from dropdown
		setOptions(newOptions); // Trigger rerender

		const newSelected = [...selected];
		newSelected.push(event.target.title); // Add selected option as badge
		newSelected.sort((a, b) => a.localeCompare(b)); // Alphabetize
		setSelected(newSelected); // Trigger rerender
		setSelections(group, newSelected);
	}

	// Remove badge, add option to dropdown
	function handleDeselect(event) {
		const newSelected = [...selected];
		newSelected.splice(newSelected.indexOf(event.target.title), 1); // Remove selected badge
		setSelected(newSelected); // Trigger rerender
		setSelections(group, newSelected);

		const newOptions = [...options];
		newOptions.push(event.target.title); // Add deselected option to dropdown
		newOptions.sort((a, b) => a.localeCompare(b)); // Alphabetize
		setOptions(newOptions); // Trigger rerender
	}

	// Remove all badges, repopulate dropdown options
	function handleClear(event) {
		const newOptions = options.concat(selected); // Add all selected options into dropdown
		newOptions.sort((a, b) => a.localeCompare(b)); // Alphabetize
		setOptions(newOptions); // Trigger rerender

		setSelected([]); // Clear all selected options, Trigger rerender
		setSelections(group, []);
	}

	return (
		<div>
			<DropdownButton
				title="Add"
				drop="up"
				autoClose="outside"
				style={{ display: "flex" }}
			>
				{options.length > 0 ? (
					dropdownOptions
				) : (
					<Dropdown.ItemText style={{ color: "#888888" }}>
						All items selected
					</Dropdown.ItemText>
				)}
				<Dropdown.Divider />
				<Dropdown.Item onClick={handleClear}>
					Clear selection
				</Dropdown.Item>
			</DropdownButton>
			<h5
				style={{
					display: "flex",
					flexWrap: "wrap",
					gap: 6,
					marginTop: 6,
				}}
			>
				{selected.length > 0 ? (
					badges
				) : (
					<Badge pill bg="primary">
						None
					</Badge>
				)}
			</h5>
		</div>
	);
}

export default MultiSelectDropdown;
