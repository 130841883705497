import React from "react";

/*
 * Props
 * - title: String of company name, first letter is displayed and is used as
 *          key for random value
 * - size: Side dimension of logo (optional, default 64)
 */

function DefaultLogo(props) {
	function assignHue(key) {
		let hue = 1;

		Array.from(key.toUpperCase()).forEach(char => {
			hue *= char.charCodeAt(0);
		});

		return hue % 359;
	}

	return (
		<div
			rounded
			style={{
				minHeight: "100%",
				minWidth: "100%",
				display: "table",
				textAlign: "center",
				borderRadius: 6,
				background: `radial-gradient(circle, hsl(${assignHue(
					props.title
				)},50%,45%) 0%, hsl(${assignHue(props.title)},80%,30%) 75%)`,
			}}
		>
			<h2
				style={{
					height: "100%",
					display: "table-cell",
					verticalAlign: "middle",
					fontWeight: "bold",
					color: "#EEEEEE",
				}}
			>
				{props.title ? props.title[0].toUpperCase() : null}
			</h2>
		</div>
	);
}

export default DefaultLogo;
