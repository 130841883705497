import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { Spinner } from "react-bootstrap";
import * as Templates from "../components/FOLTemplates";

export default function ApplicationModal(props) {
    const { showModal, handleClose, appID, proID, jobID, accessToken } = props;
    // eslint-disable-next-line
    const [applicationData, setApplicationData] = useState({});
    const [addresses, setAddresses] = useState([
        {
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            country: null,
            zipcode: null,
            workAuthorization: null,
            requireSponsorship: null,
        },
    ]);
    const [experiences, setExperiences] = useState([
        {
            company: null,
            location: null,
            jobCategory: null,
            jobTitle: null,
            level: null,
            type: null,
            startDate: null,
            endDate: null,
            description: null,
        },
    ]);
    const [degrees, setDegrees] = useState([
        {
            university: null,
            degreeLevel: null,
            major: null,
            gpa: null,
            transcript: null,
            startDate: null,
            endDate: null
        },
    ]);
    const [projects, setProjects] = useState([
        {
            title: null,
            startDate: null,
            endDate: null,
            description: null,
            skillsUsed: null,
          }
    ]);
    const [showExperiences, setShowExperiences]=useState(false)
    const [showProjects, setShowProjects]=useState(false)
    const [showAdresses,setShowAddresses]=useState(false)
    const [showDegrees, setShowDegrees]=useState(false)
    
    const [renderComponents, setRenderComponents] = useState(false)
    const handleChange = (e) => {
        const { value, name } = e.target;
        const updatedFormDetails = applicationData.FormDetails.map(
            (formItem) => {
                if (formItem.TextLabel === name) {
                    return { ...formItem, MatchedDetails: value };
                } else {
                    return formItem;
                }
            }
        );
        setApplicationData({
            ...applicationData,
            FormDetails: updatedFormDetails,
        });
        // console.log(updatedFormDetails);
    };
    const handleFOL = (e, i, group) => {
        const { value, name } = e.target;
        console.log(experiences)
        console.log(value, name, group, i);
        switch (group) {
            case "projects":
                setProjects(
                    projects.map((obj, index) => {
                        if (index === i) {
                            return { ...obj, [name]: value };
                        } else {
                            return obj;
                        }
                    })
                );
                break;
            case "degrees":
                setDegrees(
                    degrees.map((obj, index) => {
                        if (index === i) {
                            return { ...obj, [name]: value };
                        } else {
                            return obj;
                        }
                    })
                );
                break;
            case "addresses":
                setAddresses(
                    addresses.map((obj, index) => {
                        if (index === i) {
                            return { ...obj, [name]: value };
                        } else {
                            return obj;
                        }
                    })
                );
                break;
            case "experiences":
                setExperiences(
                    experiences.map((obj, index) => {
                        if (index === i) {
                            return { ...obj, [name]: value };
                        } else {
                            return obj;
                        }
                    })
                );
                break;
            default:
                break;
        }
    };
    function handleFileChange() {
        console.log("file cannot be changed");
    }
    const setSelections = (group, data) => {
        const updatedFormDetails = applicationData.FormDetails.map(
            (formItem) => {
                if (formItem.TextLabel === group) {
                    return { ...formItem, MatchedDetails: data };
                } else {
                    return formItem;
                }
            }
        );
        setApplicationData({
            ...applicationData,
            FormDetails: updatedFormDetails,
        });
        // console.log(updatedFormDetails);
    };
    function handleSubmit() {
        console.log(applicationData)
        let myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);
        myHeaders.append("Content-Type", "text/plain");
        let raw, gateway;
        raw = JSON.stringify(applicationData);
        gateway = `https://api.opus.careers/application/submit`;

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        // Make API Call
        fetch(gateway, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                clearCloseModal()
            })
            .catch((error) => console.log("error", error));
    }
    function DropdownInput(props) {
        const { name, value, handleChange, options } = props;

        return (
            <Form.Group>
                <Form.Label>{name}</Form.Label>
                <Form.Select name={name} value={value} onChange={handleChange}>
                    {options.map((key) => (
                        <option key={key}>{key}</option>
                    ))}
                </Form.Select>
            </Form.Group>
        );
    }
    function clearCloseModal(){
        setShowDegrees(false)
        setShowAddresses(false)
        setShowExperiences(false)
        setShowProjects(false)
        setRenderComponents(false)
        handleClose()
    }
    useEffect(()=>{
        if(showAdresses===false){
             setAddresses([{
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                country: null,
                zipcode: null,
                workAuthorization: null,
                requireSponsorship: null,
            }])
        }
        if(showDegrees===false){
            setDegrees([{
                university: null,
                degreeLevel: null,
                major: null,
                gpa: null,
                transcript: null,
                startDate: null,
                endDate: null
            },])
       }
       if(showProjects===false){
            setProjects([{
                title: null,
                startDate: null,
                endDate: null,
                description: null,
                skillsUsed: null,
              }])
        }
        if(showExperiences===false){
            setExperiences([{
                company: null,
                location: null,
                jobCategory: null,
                jobTitle: null,
                level: null,
                type: null,
                startDate: null,
                endDate: null,
                description: null,
            },])
        }   
    },[showAdresses,showDegrees,showExperiences, showProjects])

    useEffect(() => {
        if (proID && jobID) {
            fetch(
                `https://api.opus.careers/application?proID=${proID}&jobID=${jobID}`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: accessToken,
                    },
                }
            )
                .then((response) => response.json())
                .then((body) => {
                    let data = {
                        proID: proID,
                        WorkLocation: "plano, tx",
                        userID: "45c7e662-2e08-457d-a525-396bcd519a5b",
                        PositionTitle:
                            "junior node js/ react developer( plano , tx)",
                        ApplicationStatus: "incomplete",
                        jobID: jobID,
                        appID: appID,
                        LastUpdatedTime: 1678149460,
                        Company: "diverse lynx",
                        FormDetails: [
                            {
                                "Form-Type": {
                                    Action: "upload",
                                    Category: "button",
                                },
                                Matched: ["resume"],
                                TextLabel: "Resume/CV",
                                MatchedDetails: "",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: [
                                    "first_name",
                                    "last_name",
                                    "middle_name",
                                ],
                                TextLabel: "Full name *",
                                MatchedDetails: "Avinash Tumu",
                            },
                            {
                                "Form-Type": {
                                    Category: "checkbox",
                                    Options: [
                                        "Biz Ops; Sales Strategy & Operations",
                                        "Biz Ops; Strategic Finance",
                                        "Biz Ops; Supply Chain",
                                        "Product",
                                        "People Team",
                                        "Product Marketing",
                                        "Pricing",
                                    ],
                                    Plurality: "multiple",
                                },
                                Matched: ["user_prompt"],
                                TextLabel:
                                    "What department(s) are you most interested in?",
                                MatchedDetails: [
                                    "Pricing",
                                    "Product Marketing",
                                ],
                            },

                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["internal_email"],
                                TextLabel: "Email*",
                                MatchedDetails: "testEmail@opus.careers",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["phone_number"],
                                TextLabel: "Phone*",
                                MatchedDetails: "+1 999 999 9999",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["experiences[0].company"],
                                TextLabel: "Current Company*",
                                MatchedDetails: "Opus",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["experiences[0].jobTitle"],
                                TextLabel: "Job Title*",
                                MatchedDetails: "Full stack dev",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["linkedin"],
                                TextLabel: "LinkedIn URL*",
                                MatchedDetails: "www.google.com/linkedln",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["twitter"],
                                TextLabel: "Twitter URL",
                                MatchedDetails: "www.x.com",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["github"],
                                TextLabel: "Github URL",
                                MatchedDetails: "www.github.com",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["portfolio"],
                                TextLabel: "Portfolio URL",
                                MatchedDetails: "www.avinash.tumu",
                            },
                            {
                                "Form-Type": {
                                    Category: "textInput",
                                },
                                Matched: ["user_prompt"],
                                TextLabel: "Other website",
                                MatchedDetails: "none",
                            },
                            {
                                "Form-Type": {
                                    Category: "dropdown",
                                    Options: [
                                        "",
                                        "Decline to self-identify",
                                        "I am a veteran",
                                        "I am not a veteran",
                                    ],
                                    Plurality: "single",
                                },
                                Matched: ["veteran"],
                                TextLabel: "Veteran status",
                                MatchedDetails: "Decline to self-identify",
                            },
                            {
                                "Form-Type": {
                                    Category: "dropdown",
                                    Options: [
                                        "",
                                        "American Indian or Alaska Native (Not Hispanic or Latino)",
                                        "Asian (Not Hispanic or Latino)",
                                        "Black or African American (Not Hispanic or Latino)",
                                        "Decline to self-identify",
                                        "Hispanic or Latino",
                                        "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)",
                                        "Two or More Races (Not Hispanic or Latino)",
                                        "White (Not Hispanic or Latino)",
                                    ],
                                    Plurality: "single",
                                },
                                Matched: ["ethnicity"],
                                TextLabel: "Race",
                                MatchedDetails: "Decline to self-identify",
                            },
                            {
                                "Form-Type": {
                                    Category: "dropdown",
                                    Options: [
                                        "",
                                        "Decline to self-identify",
                                        "Female",
                                        "Male",
                                    ],
                                    Plurality: "single",
                                },
                                Matched: ["gender"],
                                TextLabel: "Gender",
                                MatchedDetails: "Male",
                            },
                            {
                                "Form-Type": {
                                    Category: "captcha",
                                },
                                Matched: ["captcha"],
                                TextLabel: "hCaptcha",
                                MatchedDetails: "",
                            },
                            {
                                "Form-Type": {
                                    Action: "submit",
                                    Category: "button",
                                },
                                Matched: ["EOF"],
                                TextLabel: "SUBMIT APPLICATION",
                                MatchedDetails: "",
                            },
                        ],
                    };
                    setApplicationData(data);
                    setRenderComponents(true)
                })
                .catch((error) => console.log(error));
        }

        // eslint-disable-next-line
    }, [appID]);
    useEffect(()=>{
        if(renderComponents){
            loadFormTemplates()
        }
    },[renderComponents])

    function loadFormTemplates(){
        const degreeArray = degrees;
        const experienceArray = experiences;
        const projectArray = projects;
        const addressArray = addresses;
        // console.log(degreeArray, experienceArray);
        if (Object.keys(applicationData).length !== 0) {
        applicationData.FormDetails.forEach((element)=>{
            if (element["Matched"].join(",").includes("addresses")) {
                const addressItem = {
                    ...addresses[0],
                    [element["Matched"][0].split(".")[1]]:
                        element["MatchedDetails"],
                };
                addressArray[0] = addressItem;
            } else if (element["Matched"].join(",").includes("projects")) {
                const projectItem = {
                    ...projects[0],
                    [element["Matched"][0].split(".")[1]]:
                        element["MatchedDetails"],
                };
                projectArray[0] = projectItem;
            } else if (
                element["Matched"].join(",").includes("experiences")
            ) {
                // console.log(element["Matched"][0].split("."));
                const experienceItem = {
                    ...experiences[0],
                    [element["Matched"][0].split(".")[1]]:
                        element["MatchedDetails"],
                };
                experienceArray[0] = experienceItem;
            } else if (element["Matched"].join(",").includes("degrees")) {
                const degreeItem = {
                    ...degrees[0],
                    [element["Matched"][0].split(".")[1]]:
                        element["MatchedDetails"],
                };
                degreeArray[0] = degreeItem;
            }
        })
        }
        if (hasOnlyNull(degreeArray) === false) {
            // console.log("degree: ", !hasOnlyNull(degreeArray));
            setDegrees(degreeArray)
            setShowDegrees(true)
        }
        if (hasOnlyNull(addressArray) === false) {
            // console.log("address: ", !hasOnlyNull(addressArray), addressArray);
            setAddresses(addressArray)
            setShowAddresses(true)
        }
        if (hasOnlyNull(projectArray) === false) {
            setProjects(projectArray)
            setShowProjects(true)
        }
        if (hasOnlyNull(experienceArray) === false) {
            // console.log(experienceArray);
            setExperiences(experienceArray)
            setShowExperiences(true)
        }

    }    
    useEffect(()=>{
        mapFOL()
    },[degrees,experiences,projects,addresses])
    function hasOnlyNull(jsonArray) {
        let isNullBool = true;
        for (let index = 0; index < jsonArray.length; index++) {
            const jsonObject = jsonArray[index];
            // console.log("jsonObject: ", jsonObject);

            for (const key in jsonObject) {
                // console.log(jsonObject[key] !== null);
                if (jsonObject[key] !== null) {
                    isNullBool = false;
                }
            }
        }

        // console.log(jsonArray, isNullBool);
        return isNullBool;
    }

    function mapFOL() {
        if (Object.keys(applicationData).length !== 0) {
            const updatedFormDetails = applicationData.FormDetails.map(
                (element) => {
                    if (element["Matched"].join(",").includes("addresses")) {
                        const key = element["Matched"].join(",").split(".")[1];
                        const value = addresses.map((item) => {
                            return item[key];
                        });
                        element["MatchedDetails"] = value;
                    } else if (
                        element["Matched"].join(",").includes("projects")
                    ) {
                        const key = element["Matched"].join(",").split(".")[1];
                        const value = projects.map((item) => {
                            return item[key];
                        });
                        element["MatchedDetails"] = value;
                    } else if (
                        element["Matched"].join(",").includes("experiences")
                    ) {

                        const key = element["Matched"].join(",").split(".")[1];
                        const value = experiences.map((item) => {
                            return item[key];
                        });
                        element["MatchedDetails"] = value;
                    } else if (
                        element["Matched"].join(",").includes("degrees")
                    ) {
                        const key = element["Matched"].join(",").split(".")[1];
                        const value = degrees.map((item) => {
                            return item[key];
                        });
                        element["MatchedDetails"] = value;
                    }
                    return element;
                }
            );
            setApplicationData({
                ...applicationData,
                FormDetails: updatedFormDetails,
            });
        }
    }

    return (
        <Modal size='lg' show={showModal} onHide={clearCloseModal}>
            <Modal.Header className="text-center" style={{ background: "#4169F0", display:"block"}}>
                <Modal.Title style={{ color: "white", margin: "auto"}}>
                    One Last Step!
                </Modal.Title>
                <div className="text-white fs-5">
                    {props.company}
                </div>
                <div className="text-white fs-6">
                    {props.title}
                </div>
            </Modal.Header>

            <Modal.Body className="px-2">
            {Object.keys(applicationData).length !== 0?(applicationData.FormDetails.map((element, i) => {
                if(element["Matched"].join(",").includes("addresses")||element["Matched"].join(",").includes("experiences")||element["Matched"].join(",").includes("projects")||element["Matched"].join(",").includes("degrees")) {
                    // console.log("template found")
                    return(<></>)
                }
                else{
                    switch (element["Form-Type"]["Category"]) {
                        case "button":
                            return(<></>)
                            break;
                        case "textInput":
                            return(
                                <Form.Group className="my-2 p-2">
                                    <Form.Label>
                                        {element["TextLabel"]}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={element["TextLabel"]}
                                        onChange={handleChange}
                                        value={applicationData.FormDetails[i]["MatchedDetails"]}
                                    />
                                </Form.Group>
                            );
                            break;
                        case "checkbox":
                            if (
                                element["Form-Type"]["Plurality"] === "single"
                            ) {
                                return(
                                    <DropdownInput
                                        name={element["TextLabel"]}
                                        handleChange={handleChange}
                                        options={
                                            element["Form-Type"]["Options"]
                                        }
                                        value={applicationData.FormDetails[i]["MatchedDetails"]}
                                        className="my-2 p-2"
                                    />
                                    // <div className="my-1 border p-1">
                                    //     <h6>{element["TextLabel"]} Dropdown</h6>
                                    //     <MultiSelectDropdown
                                    //         options={element["Form-Type"]["Options"]}
                                    //     />
                                    // </div>
                                );
                            } else if (
                                element["Form-Type"]["Plurality"] === "multiple"
                            ) {
                                return(
                                    <div className="my-2 p-2">
                                        <label>{element["TextLabel"]}</label>
                                        <MultiSelectDropdown
                                            group={element["TextLabel"]}
                                            onChange={handleChange}
                                            options={
                                                element["Form-Type"]["Options"]
                                            }
                                            selected={applicationData.FormDetails[i]["MatchedDetails"]}
                                            setSelections={setSelections}
                                        />
                                    </div>
                                );
                            }
                            break;
                        case "dropdown":
                            if (
                                element["Form-Type"]["Plurality"] === "single"
                            ) {
                                return (
                                    <div className="my-2 p-2">
                                    <DropdownInput
                                        name={element["TextLabel"]}
                                        handleChange={handleChange}
                                        options={
                                            element["Form-Type"]["Options"]
                                        }
                                        value={applicationData.FormDetails[i]["MatchedDetails"]}
                                        className="my-2 p-2"
                                    /></div>
                                    // <div className="my-1 border p-1">
                                    //     <h6>{element["TextLabel"]} Dropdown</h6>
                                    //     <MultiSelectDropdown
                                    //         options={element["Form-Type"]["Options"]}
                                    //     />
                                    // </div>
                                );
                            } else if (
                                element["Form-Type"]["Plurality"] === "multiple"
                            ) {
                                return (
                                    <div className="my-2 p-2">
                                        <label>{element["TextLabel"]}</label>
                                        <MultiSelectDropdown
                                            group={element["TextLabel"]}
                                            onChange={handleChange}
                                            options={
                                                element["Form-Type"]["Options"]
                                            }
                                            selected={applicationData.FormDetails[i]["MatchedDetails"]}
                                        />
                                    </div>
                                );
                            }
                            break;
                        case "captcha":
                            
                            <h6>captcha goes here</h6>;
                            return(<></>)
                            break;
                        default:
                            return(<></>)
                            break
                    }
                }
            })):(
                <div className="text-center">
                    <Spinner variant="dark" style={{ width: 50, height: 50 }} />
                </div>
            )}
            {showAdresses?<div className="p-2 my-2"><h4>Address</h4><Templates.AddressList
                    address={addresses}
                    modal={true}
                    handleChange={handleFOL}
                /></div>:<></>}
            {showExperiences? <div className="p-2 my-2"><h4>Work History</h4><Templates.ExperienceList
                    experiences={experiences}
                    modal={true}
                    handleChange={handleFOL}
                /></div>:<></>}
            {showDegrees?<div className="p-2 m-2"><h4>Education</h4><Templates.DegreeList
                degrees={degrees}
                modal={true}
                handleChange={handleFOL}
                handleFileChange={handleFileChange}
            /></div>:<></>}
            {showProjects?<div className="p-2 my-2"><h4>Projects</h4><Templates.ProjectList
                    projects={projects}
                    modal={true}
                    handleChange={handleFOL}
                /></div>:<></>}
            </Modal.Body>

            <Modal.Footer style={{ background: "#4169F0" }}>
                <Button
                    variant="outline-light"
                    style={{
                        width: "115px",
                        borderRadius: 999,
                        borderWidth: "2px",
                        fontWeight: "700",
                    }}
                    onClick={clearCloseModal}
                >
                    Cancel
                </Button>
                {/* <Button
                    variant="outline-light"
                    style={{
                        width: "115px",
                        borderRadius: 999,
                        fontWeight: "700",
                        borderWidth: "2px",
                    }}
                    onClick={clearCloseModal}
                >
                    Save
                </Button> */}
                <Button
                    variant="outline-primary"
                    style={{
                        width: "115px",
                        borderRadius: 999,
                        fontWeight: "700",
                        border: "none",
                        background: "white",
                        color: "#4169F0",
                    }}
                    onClick={handleSubmit}
                >
                    Complete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
