import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { forgotPassword, forgotPasswordSubmit } from "../../auth/AuthUtils";
import { useNavigate } from "react-router";
function ForgotPasswordSubmit({ forgotPasswordSubmit, updateForm }) {
  return (
    <div>
      <p>
        Check the email associated with the username for a code to reset your password.
      </p>
      <Form.Group
        className="my-3"
        controlId="forgot.confirmcode"
      >
        <Form.Label className="fw-bold">Confirmation Code</Form.Label>
        <Form.Control
          autoComplete="off"
          name="confirmationCode"
          placeholder=""
          onChange={updateForm}
          className="rounded-3 px-3 py-3"
        />
      </Form.Group>
      <Form.Group
        className="my-3"
        controlId="forgot.password"
      >
        <Form.Label className="fw-bold">New Password</Form.Label>
        <Form.Control
          autoComplete="off"
          type="password"
          name="password"
          onChange={updateForm}
          placeholder="9+ Characters"
          className="rounded px-3 py-3"
        />
      </Form.Group>
      <Button
        className="w-100 py-3 my-3 rounded-pill"
        variant="dark"
        onClick={forgotPasswordSubmit}
      >
        Reset Password
      </Button>
    </div>
  );
}
function ForgotPassword({ forgotPassword, updateForm }) {
  return (
    <div>
      <Form.Group
        className="my-3"
        controlId="forgot.username"
      >
        <Form.Label className="fw-bold">Username</Form.Label>
        <Form.Control
          autoComplete="on"
          name="username"
          onChange={updateForm}
          placeholder=""
          className="rounded-3 px-3 py-3"
        />
      </Form.Group>

      <Button
        className="w-100 py-3 my-3 rounded-pill"
        variant="dark"
        onClick={forgotPassword}
      >
        Send Reset Code
      </Button>
    </div>
  );
}
export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const initialFormState = {
    username: "",
    password: "",
    email: "",
    confirmationCode: "",
  };
  const [formType, updateFormType] = useState("forgotPassword");
  const [formState, updateFormState] = useState(initialFormState);
  function updateForm(event) {
    const newFormState = {
      ...formState,
      [event.target.name]: event.target.value,
    };
    updateFormState(newFormState);
  }
  useEffect(() => {
    if (formType === "successful") {
      navigate("/");
    }
  }, [formType, navigate]);
  function renderForm() {
    switch (formType) {
      case "forgotPassword":
        return (
          <ForgotPassword
          forgotPassword={() => forgotPassword(formState, updateFormType)}
            updateForm={updateForm}
          />
        );
      case "forgotPasswordSubmit":
        return (
          <ForgotPasswordSubmit
          forgotPasswordSubmit={() => forgotPasswordSubmit(formState, updateFormType)}
            updateForm={updateForm}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div className="m-auto w-25">
      <h2 className="my-3 fw-bold">Forgot Password?</h2>

      {renderForm()}
    </div>
  );
}
