import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import React, { useState, useEffect, useRef } from "react";

function SearchSelect(props) {
    const { options, value, group, handleChange, index } = props;

    const [searchText, setSearchText] = useState("");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Set the initial searchText value from the 'value' prop
        setSearchText(value);
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [value]);

    const handleOutsideClick = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setDropdownOpen(false);
        }
    };

    const handleSelect = (event, option) => {
        handleChange(
            { target: { value: option, name: props.name } },
            index,
            group
        );
        setSearchText(option);
        setDropdownOpen(false);
    };

    const filteredOptions = options.filter((option) => {
        try {
            if (!props.disabled) {
                //console.log(option);
                return option.toLowerCase().includes(searchText.toLowerCase());
            }
        } catch (error) {
            console.error(option);
        }
    });

    const dropdownOptions = filteredOptions.map((option, idx) => (
        <Dropdown.Item
            key={idx}
            title={option}
            onClick={(event) => handleSelect(event, option)}
            style={{
                display: "block",
                width: "100%",
                padding: "2px 10px",
                clear: "both",
                fontWeight: 400,
                color: "black",
                textAlign: "inherit",
                textDecoration: "none",
                whiteSpace: "normal",
                backgroundColor: "transparent",
                border: "0",
            }}
        >
            {option}
        </Dropdown.Item>
    ));

    return (
        <div ref={dropdownRef} style={{ width: "100%" }}>
            <div
                style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                }}
            >
                <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Search..."
                        onClick={() => setDropdownOpen(true)}
                        onChange={(event) => setSearchText(event.target.value)}
                        value={searchText}
                        style={{ borderRightWidth: "0px" }}
                        disabled={props.disabled}
                    />
                    <InputGroup.Text style={{ backgroundColor: "white" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ced4da"
                            className="bi bi-search"
                            viewBox="0 0 16 16"
                        >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </InputGroup.Text>
                </InputGroup>

                {isDropdownOpen && (
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 1,
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                            marginTop: "5px",
                            boxSizing: "content-box",
                            paddingTop: "8px",
                        }}
                    >
                        {filteredOptions.length > 0 ? (
                            dropdownOptions
                        ) : (
                            <Dropdown.ItemText
                                style={{
                                    display: "block",
                                    width: "100%",
                                    padding: "2px 10px",
                                    clear: "both",
                                    fontWeight: 400,
                                    color: "black",
                                    textAlign: "inherit",
                                    textDecoration: "none",
                                    whiteSpace: "nowrap",
                                    backgroundColor: "transparent",
                                    border: "0",
                                }}
                            >
                                No results found
                            </Dropdown.ItemText>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SearchSelect;
