import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { confirmSignUp, signUp } from "../../auth/AuthUtils";
import { useNavigate, useLocation } from "react-router";
function ConfirmSignUp({ confirmSignUp, updateForm }) {
  return (
    <div>
      <p className="text-center">
        Check your email for a verification code to complete registration.
      </p>
      <Form.Group
        className="my-3"
        controlId="signup.confirmcode"
      >
        <Form.Label className="fw-bold">Confirmation Code</Form.Label>
        <Form.Control
          autoComplete="off"
          name="confirmationCode"
          placeholder="Confirmation Code"
          onChange={updateForm}
          className="rounded-3 px-3 py-3"
        />
      </Form.Group>

      <Button
        className="w-100 py-3 my-3 rounded-pill"
        variant="dark"
        onClick={confirmSignUp}
      >
        Confirm Sign Up
      </Button>
    </div>
  );
}
function SignUp({ signUp, updateForm, formState }) {
  return (
    <div>
      <Form.Group
        className="my-3"
        controlId="signup.username"
      >
        <Form.Label className="fw-bold">Username</Form.Label>
        <Form.Control
          autoComplete="on"
          name="username"
          onChange={updateForm}
          placeholder=""
          className="rounded-3 px-3 py-3"
        />
      </Form.Group>
      <Form.Group
        className="my-3"
        controlId="signup.email"
      >
        <Form.Label className="fw-bold">Email</Form.Label>
        <Form.Control
          autoComplete="on"
          name="email"
          onChange={updateForm}
          placeholder=""
          value={formState.email}
          className="rounded-3 px-3 py-3"
        />
      </Form.Group>
      <Form.Group
        className="my-3"
        controlId="signup.password"
      >
        <Form.Label className="fw-bold">Password</Form.Label>
        <Form.Control
          autoComplete="off"
          type="password"
          name="password"
          onChange={updateForm}
          placeholder="9+ Characters"
          className="rounded px-3 py-3"
        />
      </Form.Group>

      <Button
        className="w-100 py-3 my-3 rounded-pill"
        variant="dark"
        onClick={signUp}
      >
        Create Account
      </Button>
      <p className="text-center">
        Already have an account?
        <a
          className="mx-2"
          href="/"
        >
          Sign In
        </a>
      </p>
    </div>
  );
}
function getEmailFromQueryParams(location) {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get('email') || ''; // Return an empty string if 'email' is not in the query parameters
}

export default function RegisterPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormState = {
    username: "",
    password: "",
    email: getEmailFromQueryParams(location),
    confirmationCode: "",
  };
  const [formType, updateFormType] = useState("signUp");
  const [formState, updateFormState] = useState(initialFormState);
  function updateForm(event) {
    const newFormState = {
      ...formState,
      [event.target.name]: event.target.value,
    };
    updateFormState(newFormState);
  }
  useEffect(() => {
    if (formType === "successful") {
      navigate("/letsbegin");
    }
  }, [formType, navigate]);
  function renderForm() {
    switch (formType) {
      case "signUp":
        return (
          <SignUp
            signUp={() => signUp(formState, updateFormType)}
            updateForm={updateForm}
            formState={formState}
          />
        );
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType)}
            updateForm={updateForm}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div className="m-auto w-25">
      <h2 className="my-3 fw-bold">Sign up to Opus</h2>

      {renderForm()}
    </div>
  );
}
