import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import Form from "react-bootstrap/Form";

function MultiTextInput(props) {
    const [selected, setSelected] = useState(props.selected ? props.selected : []);
    const [inputValue, setInputValue] = useState('');
  
    const { group, setSelections } = props;
  
    useEffect(() => {
      if (props.selected) {
        setSelected(props.selected);
      }
    }, [props.selected]);
  
    const addBadge = () => {
      if (inputValue.trim() === '') return;
  
      const newSelected = [...selected, inputValue];
      newSelected.sort((a, b) => a.localeCompare(b));
      setSelected(newSelected);
      setSelections(group, newSelected);
  
      setInputValue('');
    };
  
    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        addBadge();
      }
    };
  
    const handleDeselect = title => {
      const newSelected = selected.filter(item => item !== title);
      setSelected(newSelected);
      setSelections(group, newSelected);
    };
  
    const badges = selected.map((item, index) => (
      <div key={index}>
        <Badge
          pill
          bg="primary"
          title={item}
          onClick={() => handleDeselect(item)}
          style={{ cursor: 'pointer', marginRight: '6px' }}
        >
          {item}
        </Badge>
      </div>
    ));
  
    return (
      <div>
        <Form.Group>
          <Form.Control
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder='Type and Enter to Add'
          />
        </Form.Group>
        <h5 style={{ display: 'flex', flexWrap: 'wrap', gap: '6px', marginTop: '6px' }}>
          {selected.length > 0 ? (
            badges
          ) : (
            <Badge pill bg="primary">
              None
            </Badge>
          )}
        </h5>

      </div>
    );
    }

export default MultiTextInput;
