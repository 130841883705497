import Nav from "react-bootstrap/Nav";
import * as Unicons from "@iconscout/react-unicons";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";
import { Image } from "react-bootstrap";
import { signOut } from "../auth/AuthUtils";
import logo from "../assets/logo.svg";

function Navbar() {
  const location = useLocation();
  const handleSignOut = (e) => {
    e.preventDefault();
    signOut((data) => {
      console.log("Signing Out");
    });
  };
  switch (location.pathname) {
    case "/register":
    case "/":
    case "/password_reset":
      return <></>;
    default:
      return (
        <Nav
          defaultActiveKey="/home"
          variant="pills"
          className="flex-column sticky-top"
          style={{
            height: "100vh",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
            zIndex: "20",
            padding: "16px 0",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Image
              src={logo}
              rounded
              style={{
                maxWidth: "100%",
                marginBottom: "8px",
              }}
            />
          </div>
          <div>
            <Link
              to="/jobrec"
              className="nav__link"
            >
              <Nav.Link
                href="/jobrec"
                className="nav__link"
              >
                <Unicons.UilLayerGroup
                  size="32"
                  color="#000000"
                />
              </Nav.Link>
            </Link>
            <Link to="/apptracker">
              <Nav.Link
                href="/apptracker"
                className="nav__link"
              >
                <Unicons.UilClipboardNotes
                  size="32"
                  color="#000000"
                />
              </Nav.Link>
            </Link>
            <Link to="/userinfo">
              <Nav.Link
                eventKey="temp1"
                href="/userinfo"
                className="nav__link"
              >
                <Unicons.UilSmile
                  size="32"
                  color="#000000"
                />
              </Nav.Link>
            </Link>
          </div>

          <Nav.Link
            eventKey="temp2"
            className="nav__link"
            href="/"
            onClick={handleSignOut}
          >
            <Unicons.UilSignout
              size="32"
              color="#000000"
            />
          </Nav.Link>
        </Nav>
      );
  }
}

export default Navbar;
