import AppCard from "./AppCard";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { React } from "react";
import * as Unicons from "@iconscout/react-unicons";
import "../../styles/AppTracking.css";
import { useEffect, useState } from "react";
import ApplicationModal from "../../components/ApplicationModal";
import { Spinner } from "react-bootstrap";

/*
 * To-do:
 * ☐ Add more AppCard time categories
 */

// TEMPORARY, FOR TESTING
// const exampleData1 = {
//     title: "Software Engineering: Intern Opportunities for University Students, United States",
//     logo: "https://logo.clearbit.com/microsoft.com",
//     company: "Microsoft Corporation",
//     tag: "Completed",
//     timestamp: new Date(2022, 10, 22),
//     appID: -1,
// };

// const exampleData2 = {
//     title: "Software Development Engineer Internship - 2023 (US)",
//     logo: "https://logo.clearbit.com/amazon.com",
//     company: "Amazon Services LLC",
//     tag: "Completed",
//     timestamp: new Date(2022, 0, 4),
//     appID: -1,
// };

// const exampleData3 = {
//     title: "Software Engineering Internship",
//     logo: "https://logo.clearbit.com/apple.com",
//     company: "Apple",
//     tag: "Completed",
//     timestamp: new Date(Date.now()),
//     appID: -1,
// };

// const exampleData4 = {
//     title: "2022 Summer Intern: Software Engineer (Undergrad)",
//     logo: "https://logo.clearbit.com/walmart.com",
//     company: "Walmart Corporate",
//     tag: "Completed",
//     timestamp: new Date(2022, 8, 17),
//     appID: -1,
// };

// const exampleData5 = {
//     title: "2022 EPM Full Stack Developer Internship",
//     logo: "https://logo.clearbit.com/ibm.com",
//     company: "IBM",
//     tag: "Completed",
//     timestamp: new Date(2022, 0, 1),
//     appID: -1,
// };

// const exampleData6 = {
//     title: "Software Engineer - Bachelor's (Intern) - United States",
//     logo: "https://logo.clearbit.com/cisco.com",
//     company: "Cisco",
//     tag: "Completed",
//     timestamp: new Date(2021, 2, 14),
//     appID: -1,
// };

// const exampleData7 = {
//     title: "Product Development Program Associate - 2023",
//     logo: "https://logo.clearbit.com/capitalone.com",
//     company: "Capital One",
//     tag: "Completed",
//     timestamp: new Date(2023, 0, 30),
//     appID: -1,
// };

// TEMPORARY, FOR TESTING

function AppTrackingPage(props) {
    const [loading, setLoading] = useState("true");
    const [actionNeededApps, setActionNeededApps] = useState([]);
    const [submittedApps, setSubmittedApps] = useState([]);
    const [interviewingApps, setInterviewingApps] = useState([]);
    const [concludedApps, setConcludedApps] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState({});
    function handleCloseModal() {
        setSelectedApplication({});
    }
    const handleAppClick = (applicationDetails) => {
        console.log("Applciation clicked", applicationDetails);
        setSelectedApplication(applicationDetails);
    };
    // Build contents for tabs by organizing cards into subdivisions by date
    function organizeAppCards(dataList) {
        // Sort data from most recent to least recent
        dataList.sort((a, b) => {
            return b.timestamp - a.timestamp;
        });

        // If 'dataList' is empty,
        if (dataList.length === 0) {
            return null;
        }

        // Group cards into buckets by date
        const content = []; // React Element List
        let bucket = []; // React Element List
        let index = 0;

        // Today
        const today = new Date(Date.now());
        if (
            dataList[index] &&
            dataList[index].timestamp.getDate() === today.getDate() &&
            dataList[index].timestamp.getMonth() === today.getMonth() &&
            dataList[index].timestamp.getFullYear() === today.getFullYear()
        ) {
            content.push(<h4>Today</h4>);
            while (
                index < dataList.length &&
                dataList[index].timestamp.getDate() === today.getDate() &&
                dataList[index].timestamp.getMonth() === today.getMonth() &&
                dataList[index].timestamp.getFullYear() === today.getFullYear()
            ) {
                bucket.push(
                    <AppCard
                        data={dataList[index]}
                        handleAppClick={handleAppClick}
                    />
                );
                index++;
            }
            content.push(
                <div
                    style={{
                        marginBottom: 24,
                        display: "grid",
                        gridTemplateColumns: "calc(50% - 6px) auto",
                        gridTemplateRows: 96,
                        gap: 12,
                    }}
                >
                    {bucket}
                </div>
            );
            bucket = [];
        }

        // Yesterday
        const yesterday = new Date(today.setDate(today.getDate() - 1));
        if (
            dataList[index] &&
            dataList[index].timestamp.getDate() === yesterday.getDate() &&
            dataList[index].timestamp.getMonth() === yesterday.getMonth() &&
            dataList[index].timestamp.getFullYear() === yesterday.getFullYear()
        ) {
            content.push(<h4>Yesterday</h4>);
            while (
                index < dataList.length &&
                dataList[index].timestamp.getDate() === yesterday.getDate() &&
                dataList[index].timestamp.getMonth() === yesterday.getMonth() &&
                dataList[index].timestamp.getFullYear() ===
                    yesterday.getFullYear()
            ) {
                bucket.push(
                    <AppCard
                        data={dataList[index]}
                        handleAppClick={handleAppClick}
                    />
                );
                index++;
            }
            content.push(
                <div
                    style={{
                        marginBottom: 24,
                        display: "grid",
                        gridTemplateColumns: "calc(50% - 6px) auto",
                        gridTemplateRows: 96,
                        gap: 12,
                    }}
                >
                    {bucket}
                </div>
            );
            bucket = [];
        }

        // This Week
        //content.push(<h4>This Week</h4>);

        // This Month
        //content.push(<h4>This Month</h4>);

        // This Year
        //content.push(<h4>This Year</h4>);

        // Previous Years
        //content.push(<h4>Previous Years</h4>);

        // TEMPORARY, FOR TESTING
        // Previous
        if (dataList[index]) {
            content.push(<h4>Previous</h4>);
            while (dataList[index]) {
                bucket.push(
                    <AppCard
                        data={dataList[index]}
                        handleAppClick={handleAppClick}
                    />
                );
                index++;
            }
            content.push(
                <div
                    style={{
                        marginBottom: 24,
                        display: "grid",
                        gridTemplateColumns: "calc(50% - 6px) auto",
                        gridTemplateRows: 96,
                        gap: 12,
                    }}
                >
                    {bucket}
                </div>
            );
            bucket = [];
        }
        // TEMPORARY, FOR TESTING

        return content;
    }

    const { accessToken, uuid } = props;
    const [count, setCount] = useState({});

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const loadApplicationList = (status) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        //TODO: Current Date-Time categorization does not work

        fetch(
            `https://api.opus.careers/application/list?id=${uuid}&status=${status}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((results) => {
                if (status === "incomplete") {
                    setActionNeededApps(
                        results.map((result) => {
                            // let time = new Date(result.LastUpdatedTime);
                            return {
                                title: toTitleCase(result.PositionTitle),
                                logo: null,
                                company: result.Company.toUpperCase(),
                                tag: toTitleCase(result.status) + " since ",
                                timestamp: new Date(
                                    result.LastUpdatedTime * 1000
                                ),
                                appID: result.appID,
                                proID: result.proID,
                                jobID: result.jobID,
                            };
                        })
                    );
                } else if (status === "submitted" || status === "confirmed") {
                    setSubmittedApps(
                        results.map((result) => {
                            // let time = new Date(result.LastUpdatedTime * 1000);
                            return {
                                title: toTitleCase(result.PositionTitle),
                                logo: "https://logo.clearbit.com/capitalone.com",
                                company: result.Company.toUpperCase(),
                                tag: toTitleCase(result.status) + " on ",
                                timestamp: new Date(result.LastUpdatedTime),
                                appID: result.appID,
                                proID: result.proID,
                                jobID: result.jobID,
                            };
                        })
                    );
                } else if (status === "interview") {
                    setInterviewingApps(
                        results.map((result) => {
                            // let time = new Date(result.LastUpdatedTime * 1000);
                            return {
                                title: toTitleCase(result.PositionTitle),
                                logo: "https://logo.clearbit.com/capitalone.com",
                                company: result.Company.toUpperCase(),
                                tag: toTitleCase(result.status) + " requested ",
                                timestamp: new Date(result.LastUpdatedTime),
                                appID: result.appID,
                                proID: result.proID,
                                jobID: result.jobID,
                            };
                        })
                    );
                } else if (status === "concluded") {
                    setConcludedApps(
                        results.map((result) => {
                            // let time = new Date(result.LastUpdatedTime * 1000);
                            return {
                                title: toTitleCase(result.PositionTitle),
                                logo: "https://logo.clearbit.com/capitalone.com",
                                company: result.Company.toUpperCase(),
                                tag: toTitleCase(result.status) + " on ",
                                timestamp: new Date(result.LastUpdatedTime),
                                appID: result.appID,
                                proID: result.proID,
                                jobID: result.jobID,
                            };
                        })
                    );
                }
                setLoading("false");
            })
            .catch((error) => console.log("error", error));
    };

    const loadCount = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            `https://api.opus.careers/application/count?id=${uuid}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setCount(result);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        if (loading === "true") {
            loadCount();
            loadApplicationList("incomplete");
        }
        // eslint-disable-next-line
    }, []);

    const tabList = [
        {
            name: "Action Needed",
            icon: <Unicons.UilPen size="24" />,
            cards: actionNeededApps,
            applicationCount: count["incomplete"],
            id: "incomplete",
        },
        {
            name: "Submitted",
            icon: <Unicons.UilEnvelope size="24" />,
            cards: submittedApps,
            applicationCount: count["submitted"] + count["confirmed"],
            id: "submitted",
        },
        {
            name: "Interviewing",
            icon: <Unicons.UilSearchAlt size="24" />,
            cards: interviewingApps,
            applicationCount: count["interview"],
            id: "interview",
        },
        {
            name: "Concluded",
            icon: <Unicons.UilAward size="24" />,
            cards: concludedApps,
            applicationCount: count["concluded"],
            id: "concluded",
        },
    ];

    return (
        <div
            className="background"
            style={{
                display: "flex",
                flex: 1,
            }}
        >
            <Card
                className="border-0"
                style={{
                    maxWidth: 1000,
                    height: "calc(100vh - 64px)",
                    display: "flex",
                    flex: 1,
                    marginInline: "auto",
                    alignSelf: "end",
                }}
            >
                <Tab.Container
                    defaultActiveKey="incomplete"
                    // className="mb-3"
                    transition={true}
                    onSelect={(k) => {
                        setLoading("true");
                        loadCount();
                        loadApplicationList(k);
                    }}
                    style={{
                        color: "white",
                        background: "#19186B",
                    }}
                >
                    <Col>
                        <Row className="m-0">
                            <Nav className="flex-row p-0">
                                {tabList.map((tab) => (
                                    <Nav.Item className="navItem">
                                        <Nav.Link
                                            eventKey={tab.id}
                                            className="navLink"
                                        >
                                            <span
                                                style={{
                                                    minHeight: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    // alignItems: "center",
                                                    justifyContent: "center",

                                                    margin: 1,
                                                }}
                                            >
                                                {tab.icon}
                                                <h5>
                                                    {tab.name}
                                                    {""}
                                                    {tab.applicationCount > 0
                                                        ? `(${tab.applicationCount})`
                                                        : ""}
                                                </h5>
                                            </span>
                                        </Nav.Link>
                                        {/* <div
                                                style={{
                                                    margin: 24,
                                                }}
                                            ></div> */}
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Row>
                        <Row sm={9}>
                            <Tab.Content>
                                {loading === "true" ? (
                                    <div className="w-100 d-flex justify-content-center">
                                        <Spinner
                                            variant="dark"
                                            style={{
                                                width: 50,
                                                height: 50,
                                                marginTop: "30%",
                                            }}
                                        />
                                    </div>
                                ) : (
                                    tabList.map((tab) => (
                                        <Tab.Pane
                                            eventKey={tab.id}
                                            style={{ "overflow-y": "auto" }}
                                        >
                                            <div className="mt-3 p-3 ">
                                                {organizeAppCards(tab.cards)}
                                            </div>
                                        </Tab.Pane>
                                    ))
                                )}
                            </Tab.Content>
                        </Row>
                    </Col>
                </Tab.Container>
                {/* <Tabs
                        defaultActiveKey="incomplete"
                        className="mb-3"
                        transition={true}
                        fill
                        onSelect={(k) => {
                            loadCount();
                            loadApplicationList(k);
                        }}
                        justify
                        style={{
                            color: "white",
                            background: "#19186B",
                        }}
                    >
                        {tabList.map((tab) => (
                            <Tab
                                eventKey={tab.id}
                                title={
                                    <span
                                        style={{
                                            minHeight: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: 6,
                                        }}
                                    >
                                        {tab.icon}
                                        <h5>
                                            {tab.name}{" "}
                                            {tab.applicationCount !== 0
                                                ? `(${tab.applicationCount})`
                                                : ""}
                                        </h5>
                                    </span>
                                }
                            >
                                <div
                                    style={{
                                        margin: 24,
                                    }}
                                >
                                    {organizeAppCards(tab.cards)}
                                </div>
                            </Tab>
                        ))}
                    </Tabs> */}
            </Card>
            <ApplicationModal
                showModal={Object.keys(selectedApplication).length !== 0}
                handleClose={handleCloseModal}
                appID={selectedApplication.appID}
                proID={selectedApplication.proID}
                jobID={selectedApplication.jobID}
                company={selectedApplication.company}
                title={selectedApplication.title}
                accessToken={accessToken}
            ></ApplicationModal>
        </div>
    );
}

export default AppTrackingPage;
