import FormObjectList from "./FormObjectList";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SearchSelect from "./SearchSelect";
import { Children, cloneElement, useEffect } from "react";

const countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Caribbean Netherlands",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo - Brazzaville",
  "Congo - Kinshasa",
  "Cook Islands",
  "Costa Rica",
  "C√¥te d‚ÄôIvoire",
  "Croatia",
  "Cuba",
  "Cura√ßao",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard & McDonald Islands",
  "Honduras",
  "Hong Kong SAR China",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao SAR China",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "North Macedonia",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territories",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "R√©union",
  "Romania",
  "Russia",
  "Rwanda",
  "Samoa",
  "San Marino",
  "S√£o Tom√© & Pr√≠ncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia & South Sandwich Islands",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "St. Barth√©lemy",
  "St. Helena",
  "St. Kitts & Nevis",
  "St. Lucia",
  "St. Martin",
  "St. Pierre & Miquelon",
  "St. Vincent & Grenadines",
  "Sudan",
  "Suriname",
  "Svalbard & Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Islands",
  "Tuvalu",
  "U.S. Outlying Islands",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis & Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const majors = [
  "Animal Training",
  "Dog/Pet/Animal Grooming",
  "Equestrian/Equine Studies",
  "Taxidermy/Taxidermist",
  "Agricultural and Food Products Processing",
  "Agribusiness/Agricultural Business Operations",
  "Agricultural Business and Management",
  "Agricultural Business Technology",
  "Agricultural Economics",
  "Agricultural/Farm Supplies Retailing and Wholesaling",
  "Farm/Farm and Ranch Management",
  "Agricultural Mechanics and Equipment/Machine Technology",
  "Agricultural Mechanization",
  "Agricultural Power Machinery Operation",
  "Agricultural Production Operations",
  "Agroecology and Sustainable Agriculture",
  "Animal/Livestock Husbandry and Production",
  "Aquaculture",
  "Crop Production",
  "Dairy Husbandry and Production",
  "Horse Husbandry/Equine Science and Management",
  "Viticulture and Enology",
  "Agricultural and Extension Education Services",
  "Agricultural Communication/Journalism",
  "Agricultural Animal Breeding",
  "Animal Health",
  "Animal Nutrition",
  "Animal Sciences",
  "Dairy Science",
  "Livestock Management",
  "Poultry Science",
  "Applied Horticulture/Horticulture Operations",
  "Floriculture/Floristry Operations and Management",
  "Greenhouse Operations and Management",
  "Landscaping and Groundskeeping",
  "Ornamental Horticulture",
  "Plant Nursery Operations and Management",
  "Turf and Turfgrass Management",
  "Food Science",
  "Food Technology and Processing",
  "International Agriculture",
  "Agricultural and Horticultural Plant Breeding",
  "Agronomy and Crop Science",
  "Horticultural Science",
  "Plant Protection and Integrated Pest Management",
  "Plant Sciences",
  "Range Science and Management",
  "Soil Chemistry and Physics",
  "Soil Microbiology",
  "Soil Science and Agronomy",
  "Architectural History and Criticism",
  "Architectural and Building Sciences/Technology",
  "Architectural Technology/Technician",
  "Architecture",
  "City/Urban, Community and Regional Planning",
  "Environmental Design/Architecture",
  "Interior Architecture",
  "Landscape Architecture",
  "Real Estate Development",
  "African Studies",
  "American/United States Studies/Civilization",
  "Asian Studies/Civilization",
  "Balkans Studies",
  "Baltic Studies",
  "Canadian Studies",
  "Caribbean Studies",
  "Chinese Studies",
  "Commonwealth Studies",
  "East Asian Studies",
  "European Studies/Civilization",
  "French Studies",
  "German Studies",
  "Irish Studies",
  "Italian Studies",
  "Japanese Studies",
  "Korean Studies",
  "Latin American and Caribbean Studies",
  "Latin American Studies",
  "Near and Middle Eastern Studies",
  "Pacific Area/Pacific Rim Studies",
  "Polish Studies",
  "Regional Studies (US, Canadian, Foreign)",
  "Russian Studies",
  "Russian, Central European, East European and Eurasian Studies",
  "Scandinavian Studies",
  "Slavic Studies",
  "South Asian Studies",
  "Southeast Asian Studies",
  "Spanish and Iberian Studies",
  "Tibetan Studies",
  "Ukraine Studies",
  "Ural-Altaic and Central Asian Studies",
  "Western European Studies",
  "African-American/Black Studies",
  "American Indian/Native American Studies",
  "Asian-American Studies",
  "Deaf Studies",
  "Disability Studies",
  "Folklore Studies",
  "Gay/Lesbian Studies",
  "Hispanic-American, Puerto Rican, and Mexican-American/Chicano Studies",
  "Women's Studies",
  "Aviation / Flight Training (UND Aerospace) ",
  "Biochemistry",
  "Biochemistry and Molecular Biology",
  "Biophysics",
  "Molecular Biochemistry",
  "Molecular Biology",
  "Molecular Biophysics",
  "Photobiology",
  "Radiation Biology/Radiobiology",
  "Structural Biology",
  "Biology/Biological Sciences",
  "Biomedical Sciences",
  "Bioinformatics",
  "Biometry/Biometrics",
  "Biostatistics",
  "Computational Biology",
  "Biotechnology",
  "Botany/Plant Biology",
  "Plant Molecular Biology",
  "Plant Pathology/Phytopathology",
  "Plant Physiology",
  "Anatomy",
  "Cell Biology and Anatomy",
  "Cell/Cellular and Molecular Biology",
  "Cell/Cellular Biology and Histology",
  "Developmental Biology and Embryology",
  "Aquatic Biology/Limnology",
  "Conservation Biology",
  "Ecology",
  "Ecology and Evolutionary Biology",
  "Environmental Biology",
  "Epidemiology",
  "Evolutionary Biology",
  "Marine Biology and Biological Oceanography",
  "Population Biology",
  "Systematic Biology/Biological Systematics",
  "Animal Genetics",
  "Genetics",
  "Genome Sciences/Genomics",
  "Human/Medical Genetics",
  "Microbial and Eukaryotic Genetics",
  "Molecular Genetics",
  "Plant Genetics",
  "Immunology",
  "Medical Microbiology and Bacteriology",
  "Microbiology",
  "Microbiology and Immunology",
  "Mycology",
  "Parasitology",
  "Virology",
  "Molecular Medicine",
  "Neuroanatomy",
  "Neurobiology and Anatomy",
  "Neurobiology and Behavior",
  "Neuroscience",
  "Environmental Toxicology",
  "Molecular Pharmacology",
  "Molecular Toxicology",
  "Neuropharmacology",
  "Pharmacology",
  "Pharmacology and Toxicology",
  "Toxicology",
  "Aerospace Physiology and Medicine",
  "Cardiovascular Science",
  "Cell Physiology",
  "Endocrinology",
  "Exercise Physiology",
  "Molecular Physiology",
  "Oncology and Cancer Biology",
  "Pathology/Experimental Pathology",
  "Physiology",
  "Reproductive Biology",
  "Vision Science/Physiological Optics",
  "Animal Behavior and Ethology",
  "Animal Physiology",
  "Entomology",
  "Wildlife Biology",
  "Zoology/Animal Biology",
  "Accounting",
  "Accounting and Business/Management",
  "Accounting and Finance",
  "Accounting Technology/Technician and Bookkeeping",
  "Auditing",
  "Business Administration and Management",
  "Customer Service Management",
  "E-Commerce/Electronic Commerce",
  "Logistics, Materials, and Supply Chain Management",
  "Non-Profit/Public/Organizational Management",
  "Office Management and Supervision",
  "Operations Management and Supervision",
  "Organizational Leadership",
  "Project Management",
  "Purchasing, Procurement/Acquisitions and Contracts Management",
  "Research and Development Management",
  "Retail Management",
  "Transportation/Mobility Management",
  "Administrative Assistant and Secretarial Science",
  "Business/Office Automation/Technology/Data Entry",
  "Customer Service Support/Call Center/Teleservice Operation",
  "Executive Assistant/Executive Secretary",
  "General Office Occupations and Clerical Services",
  "Parts, Warehousing, and Inventory Management Operations",
  "Receptionist",
  "Traffic, Customs, and Transportation Clerk/Technician",
  "Business/Commerce",
  "Business/Corporate Communications",
  "Business/Managerial Economics",
  "Construction Management",
  "Entrepreneurship/Entrepreneurial Studies",
  "Franchising and Franchise Operations",
  "Small Business Administration/Management",
  "Banking and Financial Support Services",
  "Credit Management",
  "Finance",
  "Financial Planning and Services",
  "International Finance",
  "Investments and Securities",
  "Public Finance",
  "Merchandising and Buying Operations",
  "Retailing and Retail Operations",
  "Sales, Distribution, and Marketing Operations",
  "Selling Skills and Sales Operations",
  "Casino Management",
  "Hospitality Administration/Management",
  "Hotel, Motel, and Restaurant Management",
  "Hotel/Motel Administration/Management",
  "Meeting and Event Planning",
  "Resort Management",
  "Restaurant/Food Services Management",
  "Tourism and Travel Services Management",
  "Human Resources Development",
  "Human Resources Management/Personnel Administration",
  "Labor and Industrial Relations",
  "Labor Studies",
  "Organizational Behavior Studies",
  "Insurance",
  "International Business/Trade/Commerce",
  "Information Resources Management",
  "Knowledge Management",
  "Management Information Systems",
  "Actuarial Science",
  "Business Statistics",
  "Management Science",
  "International Marketing",
  "Marketing Research",
  "Marketing/Marketing Management",
  "Real Estate",
  "Apparel and Accessories Marketing Operations",
  "Auctioneering",
  "Business and Personal/Financial Services Marketing Operations",
  "Fashion Merchandising",
  "Fashion Modeling",
  "Hospitality and Recreation Marketing Operations",
  "Special Products Marketing Operations",
  "Tourism and Travel Services Marketing Operations",
  "Tourism Promotion Operations",
  "Vehicle and Vehicle Parts and Accessories Marketing Operations",
  "Taxation",
  "Telecommunications Management",
  "Mass Communication/Media Studies",
  "Speech Communication and Rhetoric",
  "Broadcast Journalism",
  "Journalism",
  "Photojournalism",
  "Advertising",
  "Health Communication",
  "International and Intercultural Communication",
  "Organizational Communication",
  "Political Communication",
  "Public Relations/Image Management",
  "Sports Communication",
  "Technical and Scientific Communication",
  "Publishing",
  "Digital Communication and Media/Multimedia",
  "Radio and Television",
  "Photographic and Film/Video Technology/Technician and Assistant",
  "Radio and Television Broadcasting Technology/Technician",
  "Recording Arts Technology/Technician",
  "Communications Technology/Technician",
  "Animation, Interactive Technology, Video Graphics and Special Effects",
  "Computer Typography and Composition Equipment Operator",
  "Graphic and Printing Equipment Operator Production",
  "Graphic Communications",
  "Platemaker/Imager",
  "Prepress/Desktop Publishing and Digital Imaging Design",
  "Printing Management",
  "Printing Press Operator",
  "Artificial Intelligence",
  "Computer and Information Sciences",
  "Informatics",
  "Information Technology",
  "Computer Programming, Specific Applications",
  "Computer Programming, Vendor/Product Certification",
  "Computer Programming/Programmer",
  "Computer Science",
  "Computer Graphics",
  "Data Modeling/Warehousing and Database Administration",
  "Modeling, Virtual Environments and Simulation",
  "Web Page, Digital/Multimedia and Information Resources Design",
  "Computer Systems Analysis/Analyst",
  "Computer Systems Networking and Telecommunications",
  "Computer and Information Systems Security/Information Assurance",
  "Computer Support Specialist",
  "Information Technology Project Management",
  "Network and System Administration/Administrator",
  "System, Networking, and LAN/WAN Management/Manager",
  "Web/Multimedia Management and Webmaster",
  "Data Entry/Microcomputer Applications",
  "Word Processing",
  "Data Processing and Data Processing Technology/Technician",
  "Information Science/Studies",
  "Building Construction Technology",
  "Building/Construction Site Management/Manager",
  "Building/Home/Construction Inspection/Inspector",
  "Building/Property Maintenance",
  "Carpet, Floor, and Tile Worker",
  "Concrete Finishing/Concrete Finisher",
  "Drywall Installation/Drywaller",
  "Glazier",
  "Insulator",
  "Metal Building Assembly/Assembler",
  "Painting/Painter and Wall Coverer",
  "Roofer",
  "Carpentry/Carpenter",
  "Electrical and Power Transmission Installation/Installer",
  "Electrician",
  "Lineworker",
  "Mason/Masonry",
  "Blasting/Blaster",
  "Pipefitting/Pipefitter and Sprinkler Fitter",
  "Plumbing Technology/Plumber",
  "Well Drilling/Driller",
  "Bilingual and Multilingual Education",
  "Indian/Native American Education",
  "Multicultural Education",
  "Curriculum and Instruction",
  "Administration of Special Education",
  "Adult and Continuing Education Administration",
  "Community College Education",
  "Educational Leadership and Administration",
  "Educational, Instructional, and Curriculum Supervision",
  "Elementary and Middle School Administration/Principalship",
  "Higher Education/Higher Education Administration",
  "Secondary School Administration/Principalship",
  "Superintendency and Educational System Administration",
  "Urban Education and Leadership",
  "Educational Assessment, Testing, and Measurement",
  "Educational Evaluation and Research",
  "Educational Statistics and Research Methods",
  "Learning Sciences",
  "Educational/Instructional Technology",
  "Education",
  "International and Comparative Education",
  "Social and Philosophical Foundations of Education",
  "Education/Teaching of Individuals in Early Childhood Special Education Programs",
  "Education/Teaching of Individuals in Elementary Special Education Programs",
  "Education/Teaching of Individuals in Junior High/Middle School Special Education Programs",
  "Education/Teaching of Individuals in Secondary Special Education Programs",
  "Education/Teaching of Individuals Who are Developmentally Delayed",
  "Education/Teaching of Individuals with Autism",
  "Education/Teaching of Individuals with Emotional Disturbances",
  "Education/Teaching of Individuals with Hearing Impairments Including Deafness",
  "Education/Teaching of Individuals with Mental Retardation",
  "Education/Teaching of Individuals with Multiple Disabilities",
  "Education/Teaching of Individuals with Specific Learning Disabilities",
  "Education/Teaching of Individuals with Speech or Language Impairments",
  "Education/Teaching of Individuals with Traumatic Brain Injuries",
  "Education/Teaching of Individuals with Vision Impairments Including Blindness",
  "Education/Teaching of the Gifted and Talented",
  "Special Education and Teaching",
  "College Student Counseling and Personnel Services",
  "Counselor Education/School Counseling and Guidance Services",
  "Adult and Continuing Education and Teaching",
  "Early Childhood Education and Teaching",
  "Elementary Education and Teaching",
  "Junior High/Intermediate/Middle School Education and Teaching",
  "Kindergarten/Preschool Education and Teaching",
  "Montessori Teacher Education",
  "Secondary Education and Teaching",
  "Teacher Education, Multiple Levels",
  "Waldorf/Steiner Teacher Education",
  "Agricultural Teacher Education",
  "Art Teacher Education",
  "Biology Teacher Education",
  "Business Teacher Education",
  "Chemistry Teacher Education",
  "Computer Teacher Education",
  "Drama and Dance Teacher Education",
  "Driver and Safety Teacher Education",
  "Earth Science Teacher Education",
  "English/Language Arts Teacher Education",
  "Environmental Education",
  "Family and Consumer Sciences/Home Economics Teacher Education",
  "Foreign Language Teacher  Education",
  "French Language Teacher Education",
  "Geography Teacher Education",
  "German Language Teacher Education",
  "Health Occupations Teacher Education",
  "Health Teacher Education",
  "History Teacher Education",
  "Latin Teacher Education",
  "Mathematics Teacher Education",
  "Music Teacher Education",
  "Physical Education Teaching and Coaching",
  "Physics Teacher Education",
  "Psychology Teacher Education",
  "Reading Teacher Education",
  "Sales and Marketing Operations/Marketing and Distribution   Teacher Education",
  "School Librarian/School Library Media Specialist",
  "Science Teacher Education/General Science Teacher Education",
  "Social Science Teacher Education",
  "Social Studies Teacher Education",
  "Spanish Language Teacher Education",
  "Speech Teacher Education",
  "Technical Teacher Education",
  "Technology Teacher Education/Industrial Arts Teacher Education",
  "Trade and Industrial Teacher Education",
  "Adult Literacy Tutor/Instructor",
  "Teacher Assistant/Aide",
  "Teaching English as a Second or Foreign Language/ESL Language Instructor",
  "Teaching French as a Second or Foreign Language",
  "Architectural Engineering Technology/Technician",
  "Civil Engineering Technology/Technician",
  "Computer Engineering Technology/Technician",
  "Computer Hardware Technology/Technician",
  "Computer Software Technology/Technician",
  "Computer Technology/Computer Systems Technology",
  "Construction Engineering Technology/Technician",
  "Architectural Drafting and Architectural CAD/CADD",
  "CAD/CADD Drafting and/or Design Technology/Technician",
  "Civil Drafting and Civil Engineering CAD/CADD",
  "Drafting and Design Technology/Technician",
  "Electrical/Electronics Drafting and Electrical/Electronics CAD/CADD",
  "Mechanical Drafting and Mechanical Drafting CAD/CADD",
  "Electrical, Electronic and Communications Engineering Technology/Technician",
  "Integrated Circuit Design",
  "Laser and Optical Technology/Technician",
  "Telecommunications Technology/Technician",
  "Automation Engineer Technology/Technician",
  "Biomedical Technology/Technician",
  "Electromechanical Technology/Electromechanical Engineering Technology",
  "Instrumentation Technology/Technician",
  "Robotics Technology/Technician",
  "Engineering Design",
  "Engineering/Industrial Management",
  "Packaging Science",
  "Hydraulics and Fluid Power Technology/Technician",
  "Surveying Technology/Surveying",
  "Energy Management and Systems Technology/Technician",
  "Environmental Engineering Technology/Environmental Technology",
  "Hazardous Materials Management and Waste Technology/Technician",
  "Heating, Ventilation, Air Conditioning and Refrigeration Engineering Technology/Technician",
  "Solar Energy Technology/Technician",
  "Water Quality and Wastewater Treatment Management and Recycling Technology/Technician",
  "Chemical Engineering Technology/Technician",
  "Industrial Technology/Technician",
  "Manufacturing Engineering Technology/Technician",
  "Metallurgical Technology/Technician",
  "Plastics and Polymer Engineering Technology/Technician",
  "Semiconductor Manufacturing Technology",
  "Welding Engineering Technology/Technician",
  "Aeronautical/Aerospace Engineering Technology/Technician",
  "Automotive Engineering Technology/Technician",
  "Mechanical Engineering/Mechanical Technology/Technician",
  "Mining Technology/Technician",
  "Petroleum Technology/Technician",
  "Nanotechnology",
  "Nuclear Engineering Technology/Technician",
  "Hazardous Materials Information Systems Technology/Technician",
  "Industrial Safety Technology/Technician",
  "Occupational Safety and Health Technology/Technician",
  "Quality Control Technology/Technician",
  "Aerospace, Aeronautical and Astronautical/Space Engineering",
  "Agricultural Engineering",
  "Architectural Engineering",
  "Biochemical Engineering",
  "Biological/Biosystems Engineering",
  "Bioengineering and Biomedical Engineering",
  "Ceramic Sciences and Engineering",
  "Chemical and Biomolecular Engineering",
  "Chemical Engineering",
  "Civil Engineering",
  "Geotechnical and Geoenvironmental Engineering",
  "Structural Engineering",
  "Transportation and Highway Engineering",
  "Water Resources Engineering",
  "Computer Engineering",
  "Computer Hardware Engineering",
  "Computer Software Engineering",
  "Construction Engineering",
  "Electrical and Electronics Engineering",
  "Laser and Optical Engineering",
  "Telecommunications Engineering",
  "Electromechanical Engineering",
  "Engineering Chemistry",
  "Engineering Mechanics",
  "Engineering Physics/Applied Physics",
  "Engineering Science",
  "Engineering",
  "Pre-Engineering",
  "Environmental/Environmental Health Engineering",
  "Forest Engineering",
  "Geological/Geophysical Engineering",
  "Industrial Engineering",
  "Manufacturing Engineering",
  "Materials Engineering",
  "Mechanical Engineering",
  "Mechatronics, Robotics, and Automation Engineering",
  "Metallurgical Engineering",
  "Mining and Mineral Engineering",
  "Naval Architecture and Marine Engineering",
  "Nuclear Engineering",
  "Ocean Engineering",
  "Operations Research",
  "Paper Science and Engineering",
  "Petroleum Engineering",
  "Polymer/Plastics Engineering",
  "Surveying Engineering",
  "Systems Engineering",
  "Textile Sciences and Engineering",
  "English Language and Literature",
  "American Literature (Canadian)",
  "American Literature (United States)",
  "Children's and Adolescent Literature",
  "English Literature (British and Commonwealth)",
  "General Literature",
  "Creative Writing",
  "Professional, Technical, Business, and Scientific Writing",
  "Rhetoric and Composition",
  "Writing",
  "Apparel and Textile Manufacture",
  "Apparel and Textile Marketing Management",
  "Apparel and Textiles",
  "Fashion and Fabric Consultant",
  "Textile Science",
  "Consumer Economics",
  "Consumer Services and Advocacy",
  "Family Resource Management Studies",
  "Business Family and Consumer Sciences/Human Sciences",
  "Consumer Merchandising/Retailing Management",
  "Family and Consumer Sciences/Human Sciences Communication",
  "Family and Consumer Sciences/Human Sciences",
  "Foods, Nutrition, and Wellness Studies",
  "Foodservice Systems Administration/Management",
  "Human Nutrition",
  "Facilities Planning and Management",
  "Home Furnishings and Equipment Installers",
  "Housing and Human Environments",
  "Adult Development and Aging",
  "Child Care and Support Services Management",
  "Child Care Provider/Assistant",
  "Child Development",
  "Developmental Services Worker",
  "Family and Community Services",
  "Family Systems",
  "Human Development and Family Studies",
  "African Languages, Literatures, and Linguistics",
  "American Indian/Native American Languages, Literatures, and Linguistics",
  "American Sign Language (ASL)",
  "Sign Language Interpretation and Translation",
  "Celtic Languages, Literatures, and Linguistics",
  "Ancient/Classical Greek Language and Literature",
  "Latin Language and Literature",
  "Chinese Language and Literature",
  "Japanese Language and Literature",
  "Korean Language and Literature",
  "Tibetan Language and Literature",
  "Danish Language and Literature",
  "Dutch/Flemish Language and Literature",
  "German Language and Literature",
  "Norwegian Language and Literature",
  "Scandinavian Languages, Literatures, and Linguistics",
  "Swedish Language and Literature",
  "Iranian Languages, Literatures, and Linguistics",
  "Applied Linguistics",
  "Comparative Literature",
  "Foreign Languages and Literatures",
  "Language Interpretation and Translation",
  "Linguistics",
  "Ancient Near Eastern and Biblical Languages, Literatures, and Linguistics",
  "Arabic Language and Literature",
  "Hebrew Language and Literature",
  "Modern Greek Language and Literature",
  "Catalan Language and Literature",
  "French Language and Literature",
  "Hispanic and Latin American Languages, Literatures, and Linguistics",
  "Italian Language and Literature",
  "Portuguese Language and Literature",
  "Romanian Language and Literature",
  "Spanish Language and Literature",
  "Albanian Language and Literature",
  "Baltic Languages, Literatures, and Linguistics",
  "Bosnian, Serbian, and Croatian Languages and Literatures",
  "Bulgarian Language and Literature",
  "Czech Language and Literature",
  "Polish Language and Literature",
  "Russian Language and Literature",
  "Slovak Language and Literature",
  "Ukrainian Language and Literature",
  "Bengali Language and Literature",
  "Hindi Language and Literature",
  "Punjabi Language and Literature",
  "Sanskrit and Classical Indian Languages, Literatures, and Linguistics",
  "Tamil Language and Literature",
  "Urdu Language and Literature",
  "Australian/Oceanic/Pacific Languages, Literatures, and Linguistics",
  "Burmese Language and Literature",
  "Filipino/Tagalog Language and Literature",
  "Indonesian/Malay Languages and Literatures",
  "Khmer/Cambodian Language and Literature",
  "Lao Language and Literature",
  "Thai Language and Literature",
  "Vietnamese Language and Literature",
  "Hungarian/Magyar Language and Literature",
  "Mongolian Language and Literature",
  "Turkish Language and Literature",
  "Uralic Languages, Literatures, and Linguistics",
  "Health and Wellness",
  "Advanced General Dentistry",
  "Dental Clinical Sciences",
  "Dental Materials",
  "Dental Public Health and Education",
  "Endodontics/Endodontology",
  "Oral Biology and Oral and Maxillofacial Pathology",
  "Oral/Maxillofacial Surgery",
  "Orthodontics/Orthodontology",
  "Pediatric Dentistry/Pedodontics",
  "Periodontics/Periodontology",
  "Prosthodontics/Prosthodontology",
  "Anesthesiologist Assistant",
  "Chiropractic Assistant/Technician",
  "Clinical/Medical Laboratory Assistant",
  "Emergency Care Attendant (EMT Ambulance)",
  "Lactation Consultant",
  "Medical/Clinical Assistant",
  "Occupational Therapist Assistant",
  "Pathology/Pathologist Assistant",
  "Pharmacy Technician/Assistant",
  "Physical Therapy Technician/Assistant",
  "Radiologist Assistant",
  "Respiratory Therapy Technician/Assistant",
  "Speech-Language Pathology Assistant",
  "Veterinary/Animal Health Technology/Technician and Veterinary Assistant",
  "Athletic Training/Trainer",
  "Cardiopulmonary Technology/Technologist",
  "Cardiovascular Technology/Technologist",
  "Diagnostic Medical Sonography/Sonographer and Ultrasound Technician",
  "Electrocardiograph Technology/Technician",
  "Electroneurodiagnostic/Electroencephalographic Technology/Technologist",
  "Emergency Medical Technology/Technician (EMT Paramedic)",
  "Gene/Genetic Therapy",
  "Hearing Instrument Specialist",
  "Magnetic Resonance Imaging (MRI) Technology/Technician",
  "Mammography Technician/Technology",
  "Medical Radiologic Technology/Science - Radiation Therapist",
  "Nuclear Medical Technology/Technologist",
  "Perfusion Technology/Perfusionist",
  "Physician Assistant",
  "Polysomnography",
  "Radiation Protection/Health Physics Technician",
  "Radiologic Technology/Science - Radiographer",
  "Respiratory Care Therapy/Therapist",
  "Surgical Technology/Technologist",
  "Direct Entry Midwifery",
  "Acupuncture and Oriental Medicine",
  "Ayurvedic Medicine/Ayurveda",
  "Holistic Health",
  "Homeopathic Medicine/Homeopathy",
  "Naturopathic Medicine/Naturopathy",
  "Traditional Chinese Medicine and Chinese Herbology",
  "Bioethics/Medical Ethics",
  "Chiropractic",
  "Blood Bank Technology Specialist",
  "Clinical Laboratory Science/Medical Technology/Technologist",
  "Clinical/Medical Laboratory Technician",
  "Cytogenetics/Genetics/Clinical Genetics Technology/Technologist",
  "Cytotechnology/Cytotechnologist",
  "Hematology Technology/Technician",
  "Histologic Technician",
  "Histologic Technology/Histotechnologist",
  "Ophthalmic Laboratory Technology/Technician",
  "Phlebotomy Technician/Phlebotomist",
  "Renal/Dialysis Technologist/Technician",
  "Sterile Processing Technology/Technician",
  "Audiology/Audiologist",
  "Audiology/Audiologist and Speech-Language Pathology/Pathologist",
  "Communication Sciences and Disorders",
  "Speech-Language Pathology/Pathologist",
  "Dental Assisting/Assistant",
  "Dental Hygiene/Hygienist",
  "Dental Laboratory Technology/Technician",
  "Dentistry",
  "Clinical Nutrition/Nutritionist",
  "Dietetic Technician",
  "Dietetics/Dietitian",
  "Dietitian Assistant",
  "Aromatherapy",
  "Herbalism/Herbalist",
  "Polarity Therapy",
  "Reiki",
  "Health Aide",
  "Home Health Aide/Home Attendant",
  "Medication Aide",
  "Rehabilitation Aide",
  "Clinical Research Coordinator",
  "Health Information/Medical Records Administration/Administrator",
  "Health Information/Medical Records Technology/Technician",
  "Health Unit Coordinator/Ward Clerk",
  "Health Unit Manager/Ward Supervisor",
  "Health/Health Care Administration/Management",
  "Health/Medical Claims Examiner",
  "Hospital and Health Care Facilities Administration/Management",
  "Long Term Care Administration/Management",
  "Medical Administrative/Executive Assistant and Medical Secretary",
  "Medical Insurance Coding Specialist/Coder",
  "Medical Insurance Specialist/Medical Biller",
  "Medical Office Assistant/Specialist",
  "Medical Office Computer Specialist/Assistant",
  "Medical Office Management/Administration",
  "Medical Reception/Receptionist",
  "Medical Staff Services Technology/Technician",
  "Medical Transcription/Transcriptionist",
  "Medical/Health Management and Clinical Assistant/Specialist",
  "Pre-Chiropractic Studies",
  "Pre-Dentistry Studies",
  "Pre-Medicine/Pre-Medical Studies",
  "Pre-Nursing Studies",
  "Pre-Occupational Therapy Studies",
  "Pre-Optometry Studies",
  "Pre-Pharmacy Studies",
  "Pre-Physical Therapy Studies",
  "Pre-Veterinary Studies",
  "Medical Scientist",
  "Medical Illustration/Medical Illustrator",
  "Medical Informatics",
  "Medicine",
  "Clinical Pastoral Counseling/Patient Counseling",
  "Clinical/Medical Social Work",
  "Community Health Services/Liaison/Counseling",
  "Genetic Counseling/Counselor",
  "Marriage and Family Therapy/Counseling",
  "Mental Health Counseling/Counselor",
  "Psychiatric/Mental Health Services Technician",
  "Substance Abuse/Addiction Counseling",
  "Movement Therapy and Movement Education",
  "Yoga Teacher Training/Yoga Therapy",
  "Ophthalmic Technician/Technologist",
  "Opticianry/Ophthalmic Dispensing Optician",
  "Optometric Technician/Assistant",
  "Orthoptics/Orthoptist",
  "Optometry",
  "Osteopathic Medicine/Osteopathy",
  "Clinical and Industrial Drug Development",
  "Clinical, Hospital, and Managed Care Pharmacy",
  "Industrial and Physical Pharmacy and Cosmetic Sciences",
  "Medicinal and Pharmaceutical Chemistry",
  "Natural Products Chemistry and Pharmacognosy",
  "Pharmaceutical Marketing and Management",
  "Pharmaceutical Sciences",
  "Pharmaceutics and Drug Design",
  "Pharmacoeconomics/Pharmaceutical Economics",
  "Pharmacy",
  "Pharmacy Administration and Pharmacy Policy and Regulatory Affairs",
  "Podiatric Medicine/Podiatry",
  "Licensed Practical/Vocational Nurse Training",
  "Nursing Assistant/Aide and Patient Care Assistant/Aide",
  "Behavioral Aspects of Health",
  "Community Health and Preventive Medicine",
  "Environmental Health",
  "Health Services Administration",
  "Health/Medical  Physics",
  "International Public Health/International Health",
  "Maternal and Child Health",
  "Occupational Health and Industrial Hygiene",
  "Public Health",
  "Public Health Education and Promotion",
  "Adult Health Nurse/Nursing",
  "Clinical Nurse Leader",
  "Clinical Nurse Specialist",
  "Critical Care Nursing",
  "Emergency Room/Trauma Nursing",
  "Family Practice Nurse/Nursing",
  "Geriatric Nurse/Nursing",
  "Maternal/Child Health and Neonatal Nurse/Nursing",
  "Nurse Anesthetist",
  "Nurse Midwife/Nursing Midwifery",
  "Nursing Administration",
  "Nursing Education",
  "Nursing Practice",
  "Nursing Science",
  "Occupational and Environmental Health Nursing",
  "Palliative Care Nursing",
  "Pediatric Nurse/Nursing",
  "Perioperative/Operating Room and Surgical Nurse/Nursing",
  "Psychiatric/Mental Health Nurse/Nursing",
  "Public Health/Community Nurse/Nursing",
  "Registered Nursing/Registered Nurse",
  "Women 's Health Nurse/Nursing",
  "Animal-Assisted Therapy",
  "Art Therapy/Therapist",
  "Assistive/Augmentative Technology and Rehabilitation Engineering",
  "Dance Therapy/Therapist",
  "Music Therapy/Therapist",
  "Occupational Therapy/Therapist",
  "Orthotist/Prosthetist",
  "Physical Therapy/Therapist",
  "Rehabilitation Science",
  "Therapeutic Recreation/Recreational Therapy",
  "Vocational Rehabilitation Counseling/Counselor",
  "Asian Bodywork Therapy",
  "Massage Therapy/Therapeutic Massage",
  "Somatic Bodywork",
  "Comparative and Laboratory Animal Medicine",
  "Large Animal/Food Animal and Equine Surgery and Medicine",
  "Small/Companion Animal Surgery and Medicine",
  "Veterinary Anatomy",
  "Veterinary Infectious Diseases",
  "Veterinary Microbiology and Immunobiology",
  "Veterinary Pathology and Pathobiology",
  "Veterinary Physiology",
  "Veterinary Preventive Medicine, Epidemiology, and Public Health",
  "Veterinary Sciences/Veterinary Clinical Sciences",
  "Veterinary Toxicology and Pharmacology",
  "Veterinary Medicine",
  "American  History (United States)",
  "Asian History",
  "Canadian History",
  "European History",
  "History",
  "History and Philosophy of Science and Technology",
  "Military History",
  "Public/Applied History",
  "Corrections",
  "Corrections Administration",
  "Criminal Justice/Law Enforcement Administration",
  "Criminal Justice/Police Science",
  "Criminal Justice/Safety Studies",
  "Criminalistics and Criminal Science",
  "Critical Incident Response/Special Police Operations",
  "Cultural/Archaelogical Resources Protection",
  "Cyber/Computer Forensics and Counterterrorism",
  "Financial Forensics and Fraud Investigation",
  "Forensic Science and Technology",
  "Juvenile Corrections",
  "Law Enforcement Intelligence Analysis",
  "Law Enforcement Investigation and Interviewing",
  "Law Enforcement Record-Keeping and Evidence Management",
  "Maritime Law Enforcement",
  "Protective Services Operations",
  "Securities Services Administration/Management",
  "Security and Loss Prevention Services",
  "Suspension and Debarment Investigation",
  "Fire Prevention and Safety Technology/Technician",
  "Fire Science/Fire-fighting",
  "Fire Services Administration",
  "Fire Systems Technology",
  "Fire/Arson Investigation and Prevention",
  "Wildland/Forest Firefighting and Investigation",
  "Crisis/Emergency/Disaster Management",
  "Critical Infrastructure Protection",
  "Homeland Security",
  "Terrorism and Counterterrorism Operations",
  "Community Organization and Advocacy",
  "Public Administration",
  "Education Policy Analysis",
  "Health Policy Analysis",
  "International Policy Analysis",
  "Public Policy Analysis",
  "Social Work",
  "Youth Services/Administration",
  "Pre-Law Studies",
  "Law",
  "Advanced Legal Research/Studies",
  "American/US Law/Legal Studies/Jurisprudence",
  "Banking, Corporate, Finance, and Securities Law",
  "Canadian Law/Legal Studies/Jurisprudence",
  "Comparative Law",
  "Energy, Environment, and Natural Resources Law",
  "Health Law",
  "Intellectual Property Law",
  "International Business, Trade, and Tax Law",
  "International Law and Legal Studies",
  "Programs for Foreign Lawyers",
  "Tax Law/Taxation",
  "Court Reporting/Court Reporter",
  "Legal Administrative Assistant/Secretary",
  "Legal Assistant/Paralegal",
  "General Studies",
  "Humanities/Humanistic Studies",
  "Liberal Arts and Sciences/Liberal Studies",
  "Library and Archives Assisting",
  "Archives/Archival Administration",
  "Children and Youth Library Services",
  "Library and Information Science",
  "Applied Mathematics",
  "Computational and Applied Mathematics",
  "Computational Mathematics",
  "Financial Mathematics",
  "Mathematical Biology",
  "Algebra and Number Theory",
  "Analysis and Functional Analysis",
  "Geometry/Geometric Analysis",
  "Mathematics",
  "Topology and Foundations",
  "Mathematical Statistics and Probability",
  "Mathematics and Statistics",
  "Statistics",
  "Appliance Installation and Repair Technology/Technician",
  "Business Machine Repair",
  "Communications Systems Installation and Repair Technology",
  "Computer Installation and Repair Technology/Technician",
  "Electrical/Electronics Equipment Installation and Repair",
  "Industrial Electronics Technology/Technician",
  "Security System Installation, Repair, and Inspection Technology/Technician",
  "Heating, Air Conditioning, Ventilation and Refrigeration Maintenance Technology/Technician",
  "Heavy Equipment Maintenance Technology/Technician",
  "Industrial Mechanics and Maintenance Technology",
  "Gunsmithing/Gunsmith",
  "Locksmithing and Safe Repair",
  "Musical Instrument Fabrication and Repair",
  "Parts and Warehousing Operations and Maintenance Technology/Technician",
  "Watchmaking and Jewelrymaking",
  "Aircraft Powerplant Technology/Technician",
  "Airframe Mechanics and Aircraft Maintenance Technology/Technician",
  "Alternative Fuel Vehicle Technology/Technician",
  "Autobody/Collision and Repair Technology/Technician",
  "Automobile/Automotive Mechanics Technology/Technician",
  "Avionics Maintenance Technology/Technician",
  "Bicycle Mechanics and Repair Technology/Technician",
  "Diesel Mechanics Technology/Technician",
  "Engine Machinist",
  "High Performance and Custom Engine Technician/Mechanic",
  "Marine Maintenance/Fitter and Ship Repair Technology/Technician",
  "Medium/Heavy Vehicle and Truck Technology/Technician",
  "Motorcycle Maintenance and Repair Technology/Technician",
  "Recreation Vehicle (RV) Service Technician",
  "Small Engine Mechanics and Repair Technology/Technician",
  "Vehicle Emissions Inspection and Maintenance Technology/Technician",
  "Command & Control (C3, C4I) Systems and Operations",
  "Cyber/Electronic Operations and Warfare",
  "Information Operations/Joint Information Operations",
  "Information/Psychological Warfare and Military Media Relations",
  "Intelligence",
  "Signal/Geospatial Intelligence",
  "Strategic Intelligence",
  "Combat Systems Engineering",
  "Directed Energy Systems",
  "Engineering Acoustics",
  "Low-Observables and Stealth Technology",
  "Operational Oceanography",
  "Space Systems Operations",
  "Undersea Warfare",
  "Aerospace Ground Equipment Technology",
  "Air and Space Operations Technology",
  "Aircraft Armament Systems Technology",
  "Explosive Ordinance/Bomb Disposal",
  "Joint Command/Task Force (C3, C4I) Systems",
  "Military Information Systems Technology",
  "Missile and Space Systems Technology",
  "Munitions Systems/Ordinance Technology",
  "Radar Communications and Systems Technology",
  "Accounting and Computer Science",
  "Behavioral Sciences",
  "Biological and Physical Sciences",
  "Biopsychology",
  "Ancient Studies/Civilization",
  "Classical, Ancient Mediterranean and Near Eastern Studies and Archaeology",
  "Cognitive Science",
  "Computational Science",
  "Cultural Studies/Critical Theory and Analysis",
  "Dispute Resolution",
  "Gerontology",
  "Cultural Resource Management and Policy Analysis",
  "Historic Preservation and Conservation",
  "Holocaust and Related Studies",
  "Human Biology",
  "Human Computer Interaction",
  "Intercultural/Multicultural and Diversity Studies",
  "International/Global Studies",
  "Marine Sciences",
  "Maritime Studies",
  "Mathematics and Computer Science",
  "Medieval and Renaissance Studies",
  "Museology/Museum Studies",
  "Natural Sciences",
  "Nutrition Sciences",
  "Peace Studies and Conflict Resolution",
  "Science, Technology and Society",
  "Sustainability Studies",
  "Systems Science and Theory",
  "Fishing and Fisheries Sciences and Management",
  "Forest Management/Forest Resources Management",
  "Forest Resources Production and Management",
  "Forest Sciences and Biology",
  "Forest Technology/Technician",
  "Forestry",
  "Urban Forestry",
  "Wood Science and Wood Products/Pulp and Paper Technology",
  "Environmental Science",
  "Environmental Studies",
  "Natural Resources/Conservation",
  "Land Use Planning and Management/Development",
  "Natural Resource Economics",
  "Natural Resource Recreation and Tourism",
  "Natural Resources Law Enforcement and Protective Services",
  "Natural Resources Management and Policy",
  "Water, Wetlands, and Marine Resources Management",
  "Wildlife, Fish and Wildlands Science and Management",
  "Health and Physical Education/Fitness",
  "Kinesiology and Exercise Science",
  "Physical Fitness Technician",
  "Sport and Fitness Administration/Management",
  "Sports Studies",
  "Outdoor Education",
  "Golf Course Operation and Grounds Management",
  "Parks, Recreation and Leisure Facilities Management",
  "Parks, Recreation and Leisure Studies",
  "Baking and Pastry Arts/Baker/Pastry Chef",
  "Bartending/Bartender",
  "Culinary Arts/Chef Training",
  "Culinary Science/Culinology",
  "Food Preparation/Professional Cooking/Kitchen Assistant",
  "Food Service, Waiter/Waitress, and Dining Room Management/Manager",
  "Institutional Food Workers",
  "Meat Cutting/Meat Cutter",
  "Restaurant, Culinary, and Catering Management/Manager",
  "Wine Steward/Sommelier",
  "Aesthetician/Esthetician and Skin Care Specialist",
  "Barbering/Barber",
  "Cosmetology, Barber/Styling, and Nail Instructor",
  "Cosmetology/Cosmetologist",
  "Electrolysis/Electrology and Electrolysis Technician",
  "Facial Treatment Specialist/Facialist",
  "Hair Styling/Stylist and Hair Design",
  "Make-Up Artist/Specialist",
  "Master Aesthetician/Esthetician",
  "Nail Technician/Specialist and Manicurist",
  "Permanent Cosmetics/Makeup and Tattooing",
  "Salon/Beauty Salon Management/Manager",
  "Funeral Direction/Service",
  "Funeral Service and Mortuary Science",
  "Mortuary Science and Embalming/Embalmer",
  "Philosophy and Religious Studies",
  "Applied and Professional Ethics",
  "Ethics",
  "Logic",
  "Philosophy",
  "Buddhist Studies",
  "Christian Studies",
  "Hindu Studies",
  "Islamic Studies",
  "Jewish/Judaic Studies",
  "Religion/Religious Studies",
  "Astronomy",
  "Astrophysics",
  "Planetary Astronomy and Science",
  "Atmospheric Chemistry and Climatology",
  "Atmospheric Physics and Dynamics",
  "Atmospheric Sciences and Meteorology",
  "Meteorology",
  "Analytical Chemistry",
  "Chemical Physics",
  "Chemistry",
  "Environmental Chemistry",
  "Forensic Chemistry",
  "Inorganic Chemistry",
  "Organic Chemistry",
  "Physical Chemistry",
  "Polymer Chemistry",
  "Theoretical Chemistry",
  "Geochemistry",
  "Geochemistry and Petrology",
  "Geology/Earth Science",
  "Geophysics and Seismology",
  "Hydrology and Water Resources Science",
  "Oceanography, Chemical and Physical",
  "Paleontology",
  "Materials Chemistry",
  "Materials Science",
  "Physical Sciences",
  "Acoustics",
  "Atomic/Molecular Physics",
  "Condensed Matter and Materials Physics",
  "Elementary Particle Physics",
  "Nuclear Physics",
  "Optics/Optical Sciences",
  "Physics",
  "Plasma and High-Temperature Physics",
  "Theoretical and Mathematical Physics",
  "Boilermaking/Boilermaker",
  "Shoe, Boot and Leather Repair",
  "Upholstery/Upholsterer",
  "Computer Numerically Controlled (CNC) Machinist Technology/CNC Machinist",
  "Ironworking/Ironworker",
  "Machine Shop Technology/Assistant",
  "Machine Tool Technology/Machinist",
  "Metal Fabricator",
  "Sheet Metal Technology/Sheetworking",
  "Tool and Die Technology/Technician",
  "Welding Technology/Welder",
  "Cabinetmaking and Millwork",
  "Furniture Design and Manufacturing",
  "Woodworking",
  "Psychology",
  "Cognitive Psychology and Psycholinguistics",
  "Comparative Psychology",
  "Developmental and Child Psychology",
  "Experimental Psychology",
  "Personality Psychology",
  "Physiological Psychology/Psychobiology",
  "Psychometrics and Quantitative Psychology",
  "Psychopharmacology",
  "Social Psychology",
  "Chemical Technology/Technician",
  "Anthropology",
  "Cultural Anthropology",
  "Medical Anthropology",
  "Physical and Biological Anthropology",
  "Archeology",
  "Criminology",
  "Demography and Population Studies",
  "Applied Economics",
  "Development Economics and International Development",
  "Econometrics and Quantitative Economics",
  "Economics",
  "International Economics",
  "Geographic Information Science and Cartography",
  "Geography",
  "International Relations and Affairs",
  "National Security Policy Studies",
  "American Government and Politics (United States)",
  "Canadian Government and Politics",
  "Political Economy",
  "Political Science and Government",
  "Rural Sociology",
  "Research Methodology and Quantitative Methods",
  "Social Sciences",
  "Sociology and Anthropology",
  "Sociology",
  "Urban Studies/Affairs",
  "Bible/Biblical Studies",
  "Missions/Missionary Studies and Missiology",
  "Lay Ministry",
  "Pastoral Studies/Counseling",
  "Urban Ministry",
  "Women s Ministry",
  "Youth Ministry",
  "Religious Education",
  "Religious/Sacred Music",
  "Divinity/Ministry",
  "Pre-Theology/Pre-Ministerial Studies",
  "Rabbinical Studies",
  "Talmudic Studies",
  "Theology/Theological Studies",
  "Aeronautics/Aviation/Aerospace Science and Technology",
  "Air Traffic Controller",
  "Airline Flight Attendant",
  "Airline/Commercial/Professional Pilot and Flight Crew",
  "Aviation/Airway Management and Operations",
  "Flight Instructor",
  "Construction/Heavy Equipment/Earthmoving Equipment Operation",
  "Flagging and Traffic Control",
  "Mobil Crane Operation/Operator",
  "Railroad and Railway Transportation",
  "Truck and Bus Driver/Commercial Vehicle Operator and Instructor",
  "Commercial Fishing",
  "Diver, Professional and Instructor",
  "Marine Science/Merchant Marine Officer",
  "Arts, Entertainment,and Media Management",
  "Fine and Studio Arts Management",
  "Music Management",
  "Theatre/Theatre Arts Management",
  "Crafts/Craft Design, Folk Art and Artisanry",
  "Ballet",
  "Dance",
  "Commercial and Advertising Art",
  "Commercial Photography",
  "Design and Visual Communications",
  "Fashion/Apparel Design",
  "Game and Interactive Media Design",
  "Graphic Design",
  "Illustration",
  "Industrial and Product Design",
  "Interior Design",
  "Acting",
  "Costume Design",
  "Directing and Theatrical Production",
  "Drama and Dramatics/Theatre Arts",
  "Musical Theatre",
  "Playwriting and Screenwriting",
  "Technical Theatre/Theatre Design and Technology",
  "Theatre Literature, History and Criticism",
  "Cinematography and Film/Video Production",
  "Documentary Production",
  "Film/Cinema/Video Studies",
  "Photography",
  "Art History, Criticism and Conservation",
  "Art/Art Studies",
  "Ceramic Arts and Ceramics",
  "Drawing",
  "Fiber, Textile and Weaving Arts",
  "Fine/Studio Arts",
  "Intermedia/Multimedia",
  "Metal and Jewelry Arts",
  "Painting",
  "Printmaking",
  "Sculpture",
  "Brass Instruments",
  "Conducting",
  "Jazz/Jazz Studies",
  "Keyboard Instruments",
  "Music",
  "Music History, Literature, and Theory",
  "Music Pedagogy",
  "Music Performance",
  "Music Technology",
  "Music Theory and Composition",
  "Musicology and Ethnomusicology",
  "Percussion Instruments",
  "Stringed Instruments",
  "Voice and Opera",
  "Woodwind Instruments",
  "Digital Arts",
  "Visual and Performing Arts",
];

const universities = [
  "Abilene Christian University",
  "Academy of Art College",
  "Adams State College",
  "Adelphi University",
  "Adler School of Professional Psychology",
  "Adrian College",
  "Agnes Scott College",
  "Air Force Institute of Technology",
  "Alabama Agricultural and Mechanical University",
  "Alabama State University",
  "Alaska Bible College",
  "Alaska Pacific University",
  "Albany College of Pharmacy",
  "Albany Law School",
  "Albany Medical Center",
  "Albany State University",
  "Albertus Magnus College",
  "Albion College",
  "Albright College",
  "Alcorn State University",
  "Alderson Broaddus College",
  "Alfred Adler Graduate School",
  "Alfred University",
  "Alice Lloyd College",
  "Allegheny College",
  "Allen University",
  "Alma College",
  "Alvernia College",
  "Alverno College",
  "Ambassador University",
  "Amber University",
  "American Academy of Nutrition",
  "American Business & Technology University",
  "American Conservatory of Music",
  "American Conservatory Theater",
  "American-European School of Management ",
  "American Film Institute Center for Advanced Film and Television Studies",
  "American Indian College",
  "American InterContinental University - Atlanta",
  "American InterContinental University - Ft. Lauderdale",
  "American InterContinental University - Georgia",
  "American InterContinental University Online",
  "American International College",
  "American Jewish University",
  "American Military University",
  "American Public University",
  "American University",
  "American World University",
  "Amherst College",
  "Anderson College",
  "Anderson University",
  "Andon College - Modesto",
  "Andon College - Stockton",
  "Andrew Jackson University",
  "Andrews University",
  "Angelo State University",
  "Anna Maria College",
  "Antioch New England Graduate School",
  "Antioch University",
  "Antioch University Los Angeles",
  "Antioch University Santa Barbara",
  "Antioch University Seattle",
  "Appalachian Bible College",
  "Appalachian State University",
  "Aquinas College",
  "Arcadia University",
  "Argosy University",
  "Argosy University - Hawaii",
  "Arizona Christian University",
  "Arizona State University",
  "Arizona State University, Downtown Phoenix Campus",
  "Arizona State University, Polytechnic Campus",
  "Arizona State University, Tempe Campus",
  "Arizona State University, West Campus",
  "Arkansas State University",
  "Arkansas State University, Beebe",
  "Arkansas State University, Mountain Home",
  "Arkansas State University, Newport",
  "Arkansas Tech University",
  "Arlington Baptist College",
  "Armstrong Atlantic State University",
  "Armstrong University",
  "Art Academy of Cincinnati",
  "Art Center College of Design",
  "Arthur D. Little Management Education Institute",
  "Art Institute of Charlotte",
  "Art Institute of Southern California",
  "Asbury College",
  "Ashland University",
  "Assumption College",
  "Athenaeum of Ohio",
  "Athens State College",
  "Atlanta Christian College",
  "Atlanta College of Art",
  "Atlantic International University",
  "Atlantic Union College",
  "Atlantic University",
  "Auburn University",
  "Auburn University at Montgomery",
  "Audrey Cohen College",
  "Augsburg College",
  "Augustana College",
  "Augustana College",
  "Augusta State University",
  "Aurora University",
  "Austin College",
  "Austin Community College",
  "Austin Peay State University",
  "Ave Maria University",
  "Averett College",
  "Avila College",
  "Azusa Pacific University",
  "Babson College",
  "Baker College of Auburn Hills",
  "Baker College of Cadillac",
  "Baker College of Flint",
  "Baker College of Mount Clemens",
  "Baker College of Muskegon",
  "Baker College of Owosso",
  "Baker College of Port Huron",
  "Baker University",
  "Baldwin-Wallace College",
  "Ball State University",
  "Baltimore Hebrew University",
  "Baltimore International College",
  "Bank Street College of Education",
  "Baptist Bible College of Missouri",
  "Baptist Bible College of Pennsylvania",
  "Barat College",
  "Barber-Scotia College",
  "Barclay College",
  "Bard College",
  "Bard Graduate Center for Studies in the Decorative Arts",
  "Barnard College - Columbia University",
  "Barry University",
  "Bartlesville Wesleyan College",
  "Barton College",
  "Bastyr University",
  "Bates College",
  "Bauder College",
  "Baylor College of Dentistry",
  "Baylor College of Medicine",
  "Baylor University",
  "Belhaven College",
  "Bellarmine College",
  "Bellevue University",
  "Bellin College of Nursing",
  "Belmont Abbey College",
  "Belmont University",
  "Beloit College",
  "Bemidji State Univeristy",
  "Benedict College",
  "Benedictine College",
  "Benedictine University",
  "Benedictine University, Springfield College in Illinois",
  "Bennett College",
  "Bennington College",
  "Bentley College",
  "Berea College",
  "Berean University of the Assemblies of God",
  "Berklee College of Music",
  "Berne University",
  "Berry College",
  "Bethany College California",
  "Bethany College Kansas",
  "Bethany College West Virginia",
  "Bethel College McKenzie",
  "Bethel College Mishawaka",
  "Bethel College Newton",
  "Beth-El College of Nursing and Health Sciences",
  "Bethel College St. Paul",
  "Bethune-Cookman College",
  "Biola University",
  "Birmingham-Southern College",
  "Blackburn College",
  "Black Hawk College",
  "Black Hills State University",
  "Blessing-Rieman College of Nursing",
  "Bloomfield College",
  "Bloomsburg University of Pennsylvania",
  "Bluefield College",
  "Bluefield State College",
  "Blue Mountain College",
  "Bluffton College",
  "Bob Jones University",
  "Boise Bible College",
  "Boise State University",
  "Boricua College",
  "Boston Architectural Center",
  "Boston College",
  "Boston University",
  "Bowdoin College",
  "Bowie State University",
  "Bowling Green State University",
  "Bowling Green State University, Firelands",
  "Bradley University",
  "Brandeis University",
  "Brandman University",
  "Brenau University",
  "Brescia University",
  "Brevard College",
  "Brewton-Parker College",
  "Breyer State University (Virtual University)",
  "Briar Cliff College",
  "Bridgewater College",
  "Bridgewater State College",
  "Brigham Young University",
  "Brigham Young University",
  "Brigham Young University Hawaii",
  "Brooklyn Law School",
  "Brooks Institute of Photography",
  "Brown University",
  "Brunswick Community College",
  "Bryan College",
  "Bryant and Stratton College",
  "Bryant University",
  "Bryn Athyn College of the New Church",
  "Bryn Mawr College",
  "Bucknell University",
  "Buena Vista University",
  "Burlington College",
  "Butler University",
  "Cabrini College",
  "Caldwell College",
  "California Baptist College",
  "California Coast University",
  "California College for Health Sciences",
  "California College of Arts and Crafts",
  "California College of Podiatric Medicine",
  "California College San Diego",
  "California Institute of Integral Studies",
  "California Institute of Technology",
  "California Institute of the Arts",
  "California Lutheran University",
  "California Maritime Academy",
  "California Polytechnic State University - San Luis Obispo",
  "California School of Professional Psychology - Berkley/Alameda",
  "California School of Professional Psychology - Fresno",
  "California School of Professional Psychology - Los Angels",
  "California School of Professional Psychology - San Diego",
  "California State Polytechnic University - Pomona",
  "California State University, Bakersfield",
  "California State University, Channel Islands",
  "California State University, Chico",
  "California State University, Dominguez Hills",
  "California State University, Fresno",
  "California State University, Fullerton",
  "California State University, Hayward",
  "California State University, Long Beach",
  "California State University, Los Angeles",
  "California State University, Monterey Bay",
  "California State University, Northridge",
  "California State University, Sacramento",
  "California State University, San Bernadino",
  "California State University, San Marcos",
  "California State University, Stanislaus",
  "California University of Management and Sciences",
  "California University of Pennsylvania",
  "California Western School of Law",
  "Calumet College of St. Joseph",
  "Calvary Bible College",
  "Calvin College",
  "Cambridge College",
  "Cameron University",
  "Campbellsville College",
  "Campbell University",
  "Canisius College",
  "Capella University",
  "Capital University",
  "Capital University Law School",
  "Capitol College",
  "Cardinal Stritch University",
  "Carleton College",
  "Carlos Albizu University",
  "Carlow College",
  "Carnegie Mellon University",
  "Carroll College Helena",
  "Carroll College Waukesha",
  "Carson-Newman College",
  "Carthage College",
  "Case Western Reserve University",
  "Castleton State College",
  "Catawba College",
  "Catholic Theological Union",
  "Cedar Crest College",
  "Cedarville College",
  "Centenary College",
  "Centenary College of Louisiana",
  "Center for Humanistic Studies",
  "Central Baptist College",
  "Central Bible College",
  "Central Christian College of the Bible",
  "Central College",
  "Central Connecticut State University",
  "Central Methodist College",
  "Central Michigan University",
  "Central State University",
  "Central Washington University",
  "Centre College",
  "Chadron State College",
  "Chaminade University of Honolulu",
  "Champlain College",
  "Chapman University",
  "Charles R. Drew University of Medicine and Science",
  "Charleston Southern University",
  "Charter Oak State College",
  "Chatham College",
  "Chestnut Hill College",
  "Cheyney University of Pennsylvania",
  "Chicago State University",
  "Chowan College",
  "Christendom College",
  "Christian Brothers University",
  "Christian Heritage College",
  "Christopher Newport University",
  "Circleville Bible College",
  "City University",
  "City University of New York, Bernard M. Baruch College",
  "City University of New York, Brooklyn College",
  "City University of New York, City College",
  "City University of New York, College of Staten Island",
  "City University of New York (CUNY) System",
  "City University of New York, Graduate School and University Center",
  "City University of New York, Hunter College",
  "City University of New York, John Jay College of Criminal Justice",
  "City University of New York, Lehman College",
  "City University of New York, Medgar Evers College",
  "City University of New York Medical School / Sophie Davis School of Biomedical Education",
  "City University of New York, New York City Technical College",
  "City University of New York, Queens College",
  "City University of New York, School of Law at Queens College",
  "City University of New York, York College",
  "Claflin College",
  "Claremont Graduate University",
  "Claremont Lincoln University",
  "Claremont McKenna College",
  "Clarion University",
  "Clark Atlanta University",
  "Clarke College",
  "Clarkson College",
  "Clarkson University",
  "Clark University",
  "Clayton College & State University",
  "Clearwater Christian College",
  "Cleary College",
  "Clemson University",
  "Cleveland Chiropractic College, Kansas City",
  "Cleveland Chiropractic College, Los Angeles",
  "Cleveland Institute of Art",
  "Cleveland Institute of Music",
  "Cleveland State University",
  "Coastal Carolina University",
  "Coe College",
  "Cogswell Polytechnical College",
  "Coker College",
  "Colby College",
  "Colby-Sawyer College",
  "Coleman College",
  "Colgate University",
  "CollegeAmerica, Denver",
  "CollegeAmerica, Phoenix",
  "College for Creative Studies",
  "College for Financial Planning",
  "College for Lifelong Learning",
  "College Misericordia",
  "College of Aeronautics",
  "College of Charleston",
  "College of DuPage",
  "College of Idaho",
  "College of Mount St. Joseph",
  "College of Mount St. Vincent",
  "College of New Rochelle",
  "College of Notre Dame",
  "College of Notre Dame of Maryland",
  "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)",
  "College of St. Benedict and St. John's University",
  "College of St. Catherine",
  "College of St. Elizabeth",
  "College of St. Joseph",
  "College of St. Mary",
  "College of St. Rose",
  "College of St. Scholastica",
  "College of the Atlantic",
  "College of the Holy Cross",
  "College of the Ozarks",
  "College of Visual Arts",
  "College of William and Mary",
  "Colorado Christian University",
  "Colorado College",
  "Colorado School of Mines",
  "Colorado State University",
  "Colorado State University-Pueblo",
  "Colorado Technical University",
  "Columbia College Chicago",
  "Columbia College Hollywood",
  "Columbia College of Missouri",
  "Columbia College South Carolina",
  "Columbia Commonwealth University",
  "Columbia International University",
  "Columbia Southern University",
  "Columbia Union College",
  "Columbia University",
  "Columbus College of Art and Design",
  "Columbus State University",
  "Columbus University",
  "Community College of Denver",
  "Concord College",
  "Concordia College, Ann Arbor",
  "Concordia College, Bronxville",
  "Concordia College, Moorhead",
  "Concordia College, Selma",
  "Concordia College, Seward",
  "Concordia College, St. Paul",
  "Concordia University, Austin",
  "Concordia University, Irvine",
  "Concordia University, Mequon",
  "Concordia University, Portland",
  "Concordia University, River Forest",
  "Connecticut College",
  "Converse College",
  "Conway School of Landscape Design",
  "Coppin State College",
  "Cornell College",
  "Cornell University",
  "Cornish College of the Arts",
  "Cosmopolitan University",
  "Covenant College",
  "Cranbrook Academy of Art",
  "Creighton University",
  "Crichton College",
  "Crown College",
  "Culver-Stockton College",
  "Cumberland College",
  "Cumberland University",
  "Curry College",
  "Daemen College",
  "Dakota State University",
  "Dakota Wesleyan University",
  "Dallas Baptist University",
  "Dallas Christian College",
  "Dana College",
  "Daniel Webster College",
  "Danville Area Community College",
  "Dartmouth College",
  "Darton College",
  "Davenport College of Business, Grand Rapids",
  "Davenport College of Business, Kalamazoo",
  "Davenport College of Business, Lansing",
  "Davidson College",
  "Davis and Elkins College",
  "Deaconess College of Nursing",
  "Delaware State University",
  "Delaware Valley College",
  "Delta International University",
  "Delta State University",
  "Denison University",
  "Denver Paralegal Institute",
  "Denver Technical College",
  "DePaul University",
  "DePauw University",
  "DeSales University",
  "Design Institute of San Diego",
  "Detroit College of Business",
  "Detroit College of Business - Flint",
  "Detroit College of Business - Warren",
  "Detroit College of Law",
  "DeVry Institute of Technology, Chicago",
  "DeVry Institute of Technology, Columbus",
  "DeVry Institute of Technology, Decatur",
  "DeVry Institute of Technology, DuPage",
  "DeVry Institute of Technology, Irving",
  "DeVry Institute of Technology, Kansas City",
  "DeVry Institute of Technology, Phoenix",
  "DeVry Institute of Technology, Pomona",
  "Dickinson College",
  "Dickinson State University",
  "Dillard University",
  "Divine Word College",
  "Dixie College",
  "Doane College",
  "Dominican College",
  "Dominican College of San Rafael",
  "Dominican School of Philosophy and Theology",
  "Dominican University",
  "Dominion College",
  "Dordt College",
  "Dowling College",
  "Drake University",
  "Drew University",
  "Drexel University",
  "Drury College",
  "Duke University",
  "Duluth Business University",
  "Duquesne University",
  "D'Youville College",
  "Earlham College",
  "EarthNet Institute",
  "East Carolina University",
  "East Central University",
  "Eastern College",
  "Eastern Connecticut State University",
  "Eastern Conservatory of Music",
  "Eastern Illinois University",
  "Eastern Kentucky University",
  "Eastern Mennonite University",
  "Eastern Michigan University",
  "Eastern Nazarene College",
  "Eastern New Mexico University",
  "Eastern Oregon University",
  "Eastern Virginia Medical School",
  "Eastern Washington University",
  "East Stroudsburg State University",
  "East Tennessee State University",
  "East Texas Baptist University",
  "East-West University",
  "Eckerd College",
  "Edgewood College",
  "Edinboro University of Pennsylvania",
  "Edison Community College",
  "Edward Waters College",
  "Elizabeth City State University",
  "Elizabethtown College",
  "Elmhurst College",
  "Elmira College",
  "Elms College",
  "Elon College",
  "Embry-Riddle Aeronautical University",
  "Embry Riddle Aeronautical University, Prescott",
  "Emerson College",
  "Emmanuel College",
  "Emmanuel College Georgia",
  "Emmaus Bible College",
  "Emory & Henry College",
  "Emory University",
  "Emporia State University",
  "Erskine College",
  "Eugene Bible College",
  "Eureka College",
  "Evangel University",
  "Evergreen State College",
  "Excel College",
  "Excelsior College",
  "Fairfield University",
  "Fairleigh Dickinson University, Florham-Madison Campus",
  "Fairleigh Dickinson University, Teaneck-Hackensack Campus",
  "Fairmont State College",
  "Fashion Institute of New York",
  "Faulkner University",
  "Fayetteville State University",
  "Felician College",
  "Ferris State University",
  "Ferrum College",
  "Fielding Institute",
  "Finch University of Health Sciences - The Chicago Medical School",
  "Finlandia University",
  "Fisher College",
  "Fisk University",
  "Fitchburg State College",
  "Flagler College",
  "Florida Agricultural and Mechanical University",
  "Florida Atlantic University",
  "Florida Christian College",
  "Florida Community College at Jacksonville",
  "Florida Gulf Coast University",
  "Florida Institute of Technology",
  "Florida International University",
  "Florida Memorial College",
  "Florida Metropolitan University, Fort Lauderdale College",
  "Florida Metropolitan University, Tampa College",
  "Florida Metropolitan University, Tampa College Brandon",
  "Florida Metropolitan University, Tampa College Lakeland",
  "Florida Southern College",
  "Florida State University",
  "Florida University of Medicine",
  "Fontbonne College",
  "Foothill-De Anza Community College District",
  "Fordham University",
  "Forest Institute of Professional Psychology",
  "Fort Hays State University",
  "Fort Lewis College",
  "Fort Valley State College",
  "Framingham State College",
  "Franciscan School of Theology",
  "Franciscan University of Steubenville",
  "Francis Marion University",
  "Franklin and Marshall College",
  "Franklin College",
  "Franklin Pierce College",
  "Franklin Pierce Law Center",
  "Franklin University",
  "Franklin W. Olin College of Engineering",
  "Frank Lloyd Wright School of Architecture",
  "Fred Hutchinson Cancer Research Center",
  "Freed-Hardeman University",
  "Free University (Virginia Theological University)",
  "Free Will Baptist Bible College",
  "Fresno City College",
  "Fresno Pacific University",
  "Friends University",
  "Frostburg State University",
  "Full Sail University",
  "Furman University",
  "Gallaudet University",
  "Gannon University",
  "Gardner Webb University",
  "Gemological Institute of America",
  "Geneva College",
  "George Fox University",
  "George Mason University",
  "Georgetown College",
  "Georgetown University",
  "George Washington University",
  "George Wythe College",
  "Georgia Baptist College of Nursing",
  "Georgia College & State University",
  "Georgia Health Sciences University",
  "Georgia Institute of Technology",
  "Georgian Court College",
  "Georgia School of Professional Psychology",
  "Georgia Southern University",
  "Georgia Southwestern University",
  "Georgia State University",
  "Gettysburg College",
  "Glendale University College of Law",
  "Glenville State College",
  "Goddard College",
  "God's Bible School and College",
  "Golden Gate University",
  "Goldey-Beacom College",
  "Gonzaga University",
  "Gordon College",
  "Gordon Conwell Theological Seminary",
  "Goshen College",
  "Goucher College",
  "Governors State University",
  "Grace Bible College",
  "Grace College",
  "Graceland College",
  "Grace University",
  "Graduate Theological Union",
  "Grambling State University",
  "Grand Canyon University",
  "Grand Valley State University",
  "Grand View College",
  "Grantham University",
  "Gratz College",
  "Great Lakes Christian College",
  "Green Mountain College",
  "Greensboro College",
  "Greenville College",
  "Grinnell College",
  "Grove City College",
  "Guilford College",
  "Gustavus Adolphus College",
  "Gwynedd-Mercy College",
  "Hagerstown Community College",
  "Hamilton College",
  "Hamilton Technical College",
  "Hamline University",
  "Hampden-Sydney College",
  "Hampshire College",
  "Hampton College",
  "Hampton University",
  "Hannibal-Lagrange College",
  "Hanover College",
  "Harding University",
  "Harding University Graduate School of Religion",
  "Hardin-Simmons University",
  "Harrington Institute of Interior Design",
  "Harris-Stowe State University",
  "Hartford College for Women",
  "Hartford Graduate Center (Rensselaer at Hartford)",
  "Hartwick College",
  "Harvard University",
  "Harvey Mudd College",
  "Hastings College",
  "Haverford College",
  "Hawaii Pacific University",
  "Heartland Baptist Bible College",
  "Hebrew College",
  "Heidelberg College",
  "Henderson State Univerisity",
  "Hendrix College",
  "Heritage College",
  "Hesser College",
  "High Point University",
  "Hilbert College",
  "Hillsdale College",
  "Hiram College",
  "Hobart and William Smith Colleges",
  "Hobe Sound Bible College",
  "Hodges University",
  "Hofstra University",
  "Hollins University",
  "Holy Apostles College",
  "Holy Cross College",
  "Holy Family College",
  "Holy Names College",
  "Hood College",
  "Hope College",
  "Hope International University",
  "Houghton College",
  "Houston Baptist University",
  "Howard Payne University",
  "Howard University",
  "Humboldt State University",
  "Humphreys College",
  "Huntington College",
  "Huron University",
  "Husson College",
  "Huston-Tillotson College",
  "ICI University",
  "ICT College",
  "Idaho State University",
  "Iglobal University",
  "Illinois Benedictine University",
  "Illinois College",
  "Illinois College of Optometry",
  "Illinois Institute of Technology",
  "Illinois School of Professional Psychology - Chicago Campus",
  "Illinois School of Professional Psychology - Meadows Campus",
  "Illinois State University",
  "Illinois Valley Community College",
  "Illinois Wesleyan University",
  "Immaculata University",
  "IMPAC University",
  "Indiana Institute of Technologyy",
  "Indiana State University",
  "Indiana University at Bloomington",
  "Indiana University at Kokomo",
  "Indiana University at South Bend",
  "Indiana University - East",
  "Indiana University - Northwest",
  "Indiana University of Pennsylvania",
  "Indiana University-Purdue University at Columbus",
  "Indiana University-Purdue University at Fort Wayne",
  "Indiana University-Purdue University at Indianapolis",
  "Indiana University - Southeast",
  "Indiana University (System)",
  "Indiana Wesleyan University",
  "Institute of Clinical Social Work",
  "Institute of Paper Science and Technology",
  "Institute of Textile Technology",
  "Institute of Transpersonal Psychology",
  "IntelliTec College - Grand Junction",
  "International Academy of  Merchandising and Design Chicago",
  "International Academy of  Merchandising and Design Tampa",
  "International Bible College",
  "International College",
  "Iona College",
  "Iowa State University of Science and Technology",
  "Iowa Wesleyan College",
  "Ithaca College",
  "ITT Technical Institute Fort Wayne",
  "ITT Technical Institute Indianapolis",
  "ITT Technical Institute Maitland",
  "ITT Technical Institute Portland",
  "ITT Technical Institute West Covina",
  "Jackson State University",
  "Jacksonville State University",
  "Jacksonville University",
  "James Madison University",
  "Jamestown College",
  "Jarvis Christian College",
  "John Brown University",
  "John Carroll University",
  "John F. Kennedy University",
  "John Marshall Law School",
  "John Paul the Great Catholic University",
  "Johns Hopkins University",
  "Johnson Bible College",
  "Johnson County Community College",
  "Johnson C. Smith University",
  "Johnson State College",
  "Johnson & Wales University",
  "Johnson & Wales University, Charleston",
  "John Wesley College",
  "Jones College",
  "Jones International University",
  "Judson College Elgin",
  "Judson College Marion",
  "Juniata College",
  "Kalamazoo College",
  "Kankakee Community College",
  "Kansas City Art Institute",
  "Kansas State University",
  "Kansas Wesleyan University",
  "Kaplan University",
  "Katharine Gibbs School",
  "Kean University of New Jersey",
  "Keck Graduate Institute of Applied Life Sciences",
  "Keene State College",
  "Keller Graduate School of Management",
  "Kendall College",
  "Kendall College of Art and Design",
  "Kennesaw State University",
  "Kent State University",
  "Kent State University - Ashtabula",
  "Kent State University - East Liverpool",
  "Kent State University - Salem",
  "Kent State University - Stark",
  "Kent State University - Trumbull",
  "Kent State University - Tuscarawas",
  "Kentucky Christian College",
  "Kentucky State University",
  "Kentucky Wesleyan College",
  "Kenyon College",
  "Kettering University (GMI)",
  "Keuka College",
  "King College",
  "King's College",
  "Kirksville College of Osteopathic Medicine",
  "Kirkwood Community College",
  "Knox College",
  "Knoxville College",
  "Kutztown University of Pennsylvania",
  "Laboratory Institute of Merchandising",
  "Lafayette College",
  "Lagrange College",
  "Lake Erie College",
  "Lake Forest College",
  "Lake Forest Graduate School of Management",
  "Lakeland College",
  "Lake Superior State University",
  "Lakeview College of Nursing",
  "Lamar University",
  "Lamar University - Port Arthur",
  "Lambuth University",
  "Lancaster Bible College",
  "Lander University",
  "Lane College",
  "Langston University",
  "La Roche College",
  "La Salle University",
  "Lasell College",
  "La Sierra University",
  "Laurus Technical Institute",
  "Lawrence Technological University",
  "Lawrence University",
  "Lebanon Valley College",
  "Lees-McRae College",
  "Lee University",
  "Lehigh Univervsity",
  "Le Moyne College",
  "Le Moyne-Owen College",
  "Lenoir-Rhyne College",
  "Lesley University",
  "Letourneau University",
  "Lewis and Clark College",
  "Lewis & Clark Community College",
  "Lewis-Clark State College",
  "Lewis University",
  "Liberty University",
  "Life Chiropractic College West",
  "Life University",
  "Limestone College",
  "Lincoln Memorial University",
  "Lincoln University Missouri",
  "Lincoln University Pennsylvania",
  "Lincoln University San Francisco",
  "Lindenwood University",
  "Lindsey Wilson College",
  "Linfield College",
  "Lipscomb University",
  "Livingstone College",
  "Lock Haven University of Pennsylvania",
  "Logan College of Chiropractic",
  "Loma Linda University",
  "Long Island University",
  "Long Island University, C.W. Post Campus",
  "Long Island University, Southampton College",
  "Longwood College",
  "Loras College",
  "Los Angeles College of Chiropractic",
  "Louisiana Baptist University",
  "Louisiana College",
  "Louisiana State University and Agricultural and Mechanical College",
  "Louisiana State University at Alexandria",
  "Louisiana State University at Eunice",
  "Louisiana State University Health Sciences Center New Orleans",
  "Louisiana State University in Shreveport",
  "Louisiana Tech University",
  "Lourdes College",
  "Loyola College in Maryland",
  "Loyola Marymount University",
  "Loyola University New Orleans",
  "Loyola University of Chicago",
  "Lubbock Christian University",
  "Lutheran Bible Institute of Seattle",
  "Luther College",
  "Lycoming College",
  "Lynchburg College",
  "Lyndon State College",
  "Lynn University",
  "Lyon College",
  "Macalester College",
  "MacMurray College",
  "Macon State College",
  "Madison University (Distance Education)",
  "Madonna University",
  "Maharishi University of Management",
  "Maine College of Art",
  "Maine Maritime Academy",
  "Malone College",
  "Manchester College",
  "Manhattan Christian College",
  "Manhattan College",
  "Manhattan School of Music",
  "Manhattanville College",
  "Mankato State University",
  "Mansfield University of Pennsylvania",
  "Maranatha Baptist Bible College",
  "Marian College",
  "Marian College of Fond Du Lac",
  "Marietta College",
  "Marist College",
  "Marlboro College",
  "Marquette University",
  "Marshall University",
  "Mars Hill College",
  "Martin Luther College",
  "Martin Methodist College",
  "Martin University",
  "Mary Baldwin College",
  "Marycrest International University",
  "Marygrove College",
  "Marylhurst University",
  "Marymount College New York",
  "Marymount Manhattan College",
  "Marymount University",
  "Maryville College",
  "Maryville University of St. Louis",
  "Mary Washington College",
  "Marywood University",
  "Massachusetts College of Art",
  "Massachusetts College of Liberal Arts",
  "Massachusetts College of Pharmacy and Allied Health Sciences",
  "Massachusetts Institute of Technology",
  "Massachusetts Maritime Academy",
  "Massachusetts School of Professional Psychology",
  "Mayo Graduate School",
  "Mayo Medical School",
  "Mayville State University",
  "McKendree College",
  "McMurry University",
  "McNeese State University",
  "McPherson College",
  "Medaille College",
  "Medcenter One College of Nursing",
  "Medical College of Georgia",
  "Medical College of Ohio",
  "Medical College of Pennsylvania and Hahnemann University",
  "Medical College of Wisconsin",
  "Medical University of South Carolina",
  "Meharry Medical College",
  "Memphis College of Art",
  "Menlo College",
  "Mennonite College of Nursing",
  "Mercer University",
  "Mercer University, Cecil B. Day Campus",
  "Mercy College",
  "Mercyhurst College",
  "Meredith College",
  "Merrimack College",
  "Mesa State College",
  "Messiah College",
  "Methodist College",
  "Metropolitan State College of Denver",
  "Metropolitan State University",
  "MGH Institute of Health Professions",
  "Miami Dade College",
  "Miami University of Ohio",
  "Miami University of Ohio - Hamilton",
  "Miami University of Ohio - Middletown",
  "Michigan School of Professional Psychology",
  "Michigan State University",
  "Michigan Technological University",
  "Mid-America Nazarene University",
  "Mid-American Bible College",
  "Mid-Continent Baptist Bible College",
  "Middlebury College",
  "Middle Tennessee State University",
  "Midland Lutheran College",
  "Midway College",
  "Midwestern State University",
  "Midwestern University",
  "Miles College",
  "Millennia Atlantic University",
  "Millersville University of Pennsylvania",
  "Milligan College",
  "Millikin University",
  "Millsaps College",
  "Mills College",
  "Mills Grae University",
  "Milwaukee Institute of Art and Design",
  "Milwaukee School of Engineering",
  "Minneapolis College of Art and Design",
  "Minnesota Bible College",
  "Minnesota School of Professional Psychology",
  "Minot State University",
  "Mississippi College",
  "Mississippi State University",
  "Mississippi University for Women",
  "Mississippi Valley State University",
  "Missouri Baptist College",
  "Missouri Southern State College",
  "Missouri Tech",
  "Missouri University of Science and Technology",
  "Missouri Valley College",
  "Missouri Western State College",
  "Molloy College",
  "Monmouth University",
  "Montana State University",
  "Montana State University - Billings",
  "Montana State University - Northern",
  "Montana Tech",
  "Montclair State University",
  "Monterey Institute of International Studies",
  "Montreat College",
  "Montserrat College of Art",
  "Moody Bible Institute",
  "Moore College of Art and Design",
  "Moorhead State University",
  "Moraine Valley Community College",
  "Moravian College",
  "Morehead State University",
  "Morehouse College",
  "Morehouse School of Medicine",
  "Morgan State University",
  "Morningside College",
  "Morris Brown College",
  "Morris College",
  "Morrison College",
  "Mountain State University",
  "Mount Aloysius College",
  "Mount Carmel College of Nursing",
  "Mount Holyoke College",
  "Mount Ida College",
  "Mount Marty College",
  "Mount Mary College",
  "Mount Mercy College",
  "Mount Olive College",
  "Mount Senario College",
  "Mount Sinai School of Medicine",
  "Mount St. Clare College",
  "Mount St. Mary College",
  "Mount St. Mary's College California",
  "Mount St. Mary's College Maryland",
  "Mount Union College",
  "Mount Vernon College",
  "Mount Vernon Nazarene College",
  "Muhlenberg College",
  "Multnomah Bible College",
  "Murray State University",
  "Muskingum College",
  "NAES College",
  "National American University",
  "National American University, Albuquerque",
  "National American University, Colorado Springs",
  "National American University, Denver",
  "National American University, Kansas City",
  "National American University, Roseville",
  "National American University, Sioux Falls",
  "National College of Chiropractic",
  "National College of Naturopathic Medicine",
  "National Defense University",
  "National Hispanic University",
  "National-Louis University",
  "National Technological University",
  "National Theatre Conservatory",
  "National University",
  "Naval Postgraduate School",
  "Nazarene Bible College",
  "Nazareth College",
  "Nebraska Christian College",
  "Nebraska Methodist College of Nursing and Allied Health",
  "Nebraska Wesleyan University",
  "Neumann College",
  "Newberry College",
  "New College of California",
  "New College of Florida",
  "New England College",
  "New England College of Optometry",
  "New England Conservatory of Music",
  "New England Institute of Technology",
  "New England School of Art and Design",
  "New England School of Communications",
  "New England School of Law",
  "New Hampshire College",
  "New Jersey City University",
  "New Jersey Institute of Technology",
  "Newman University",
  "New Mexico Highlands University",
  "New Mexico Institute of Mining and Technology",
  "New Mexico State University",
  "Newschool of Architecture and Design",
  "New York Academy of Art, Graduate School of Figurative Art",
  "New York Chiropractic College",
  "New York College of Podiatric Medicine",
  "New York Film Academy",
  "New York Institute of Technology",
  "New York Law School",
  "New York Medical College",
  "New York School of Interior Design",
  "New York University",
  "Niagara University",
  "Nicholls State University",
  "Nichols College",
  "Norfolk State University",
  "North Carolina Agricultural and Technical State University",
  "North Carolina Central University",
  "North Carolina School of the Arts",
  "North Carolina State University",
  "North Carolina Wesleyan College",
  "North Central Bible College",
  "North Central College",
  "Northcentral University",
  "North Dakota State University",
  "Northeastern Illinois University",
  "Northeastern Ohio University College of Medicine",
  "Northeastern State University",
  "Northeastern University",
  "Northern Arizona University",
  "Northern Illinois University",
  "Northern Kentucky University",
  "Northern Michigan University",
  "Northern State University",
  "Northern Virginia Community College",
  "Northface University",
  "North Georgia College",
  "North Greenville College",
  "Northland College",
  "North Park University",
  "Northwest Christian College",
  "Northwest College of Art",
  "Northwestern College Iowa",
  "Northwestern College of Chiropractic",
  "Northwestern College St. Paul",
  "Northwestern Oklahoma State University",
  "Northwestern State University of Louisiana",
  "Northwestern University",
  "Northwest Missouri State University",
  "Northwest Nazarene University",
  "Northwest University",
  "Northwood University",
  "Northwood University, Florida Campus",
  "Northwood University, Texas Campus",
  "Norwich University",
  "Notre Dame College",
  "Notre Dame College of Ohio",
  "Notre Dame de Namur University",
  "Nova Southeastern University",
  "Nyack College",
  "Oakland City University",
  "Oakland University",
  "Oakton Community College",
  "Oakwood College",
  "Oberlin College",
  "Occidental College",
  "Oglala Lakota College",
  "Oglethorpe University",
  "Ohio College of Podiatric Medicine",
  "Ohio Dominican College",
  "Ohio Northern University",
  "Ohio State University",
  "Ohio State University - Lima",
  "Ohio State University - Mansfield",
  "Ohio State University - Marion",
  "Ohio State University - Newark",
  "Ohio University",
  "Ohio University - Chillicothe",
  "Ohio University - Eastern",
  "Ohio University - Lancaster",
  "Ohio University - Southern",
  "Ohio University - Zanesville",
  "Ohio Valley College",
  "Ohio Wesleyan University",
  "Oklahoma Baptist University",
  "Oklahoma Christian University",
  "Oklahoma City University",
  "Oklahoma Panhandle State University",
  "Oklahoma State University",
  "Oklahoma State University Center for Health Sciences",
  "Oklahoma State University - Institute of Technology",
  "Oklahoma State University - Oklahoma City",
  "Oklahoma State University - Tulsa",
  "Old Dominion University",
  "Olive-Harvey College",
  "Olivet College",
  "Olivet Nazarene University",
  "O'More College of Design",
  "Oral Roberts University",
  "Oregon College of Arts and Crafts",
  "Oregon Graduate Institute of Science and Technology",
  "Oregon Health Sciences University",
  "Oregon Institute of Technology",
  "Oregon State University",
  "Otis College of Art & Design",
  "Ottawa University",
  "Otterbein College",
  "Ouachita Baptist University",
  "Our Lady of Holy Cross College",
  "Our Lady of the Lake University",
  "Ozark Christian College",
  "Pace University",
  "Pace University  Pleasantville/Briarcliff",
  "Pacifica Graduate Institute",
  "Pacific College of Oriental Medicine",
  "Pacific Graduate School of Psychology",
  "Pacific Lutheran University",
  "Pacific Northwest College of Art",
  "Pacific Oaks College",
  "Pacific Union College",
  "Pacific University",
  "Paier College of Art",
  "Paine College",
  "Palm Beach Atlantic University",
  "Palm Beach State College",
  "Palmer College of Chiropractic",
  "Palmer College of Chiropractic West",
  "Park College",
  "Parsons School of Design",
  "Paul Quinn College",
  "Peace College",
  "Pebble Hills University",
  "Pennsylvania Academy of the Fine Arts",
  "Pennsylvania College of Optometry",
  "Pennsylvania Institute of Technology",
  "Pennsylvania State University",
  "Pennsylvania State University - Abington",
  "Pennsylvania State University - Altoona",
  "Pennsylvania State University at Erie - Behrend College",
  "Pennsylvania State University at Harrisburg - The Capital College",
  "Pennsylvania State University - Berks-Lehigh Valley College",
  "Pennsylvania State University Delaware County",
  "Pennsylvania State University Great Valley",
  "Pennsylvania State University - Lehigh Valley",
  "Pennsylvania State University - Milton S.Hershey Medical Center",
  "Pennsylvania State University - Schuylkill",
  "Pepperdine University",
  "Peru State College",
  "Pfeiffer University",
  "Philadelphia College of Bible",
  "Philadelphia College of Osteopathic Medicine",
  "Philadelphia University",
  "Philander Smith College",
  "Phillips Graduate Institute",
  "Phillips University",
  "Piedmont Baptist College",
  "Piedmont College",
  "Pikeville College",
  "Pillsbury Baptist Bible College",
  "Pittsburg State University",
  "Pitzer College",
  "Plymouth State College",
  "Point Loma Nazarene College",
  "Point Park College",
  "Polytechnic University",
  "Polytechnic University, Long Island Campus",
  "Polytechnic University, Westchester Graduate Center",
  "Pomona College",
  "Portland Community College",
  "Portland State University",
  "Post University of Waterbury",
  "Prairie View Agricultural and Mechanical University",
  "Pratt Institute",
  "Presbyterian College",
  "Prescott College",
  "Preston University",
  "Princeton University",
  "Principia College",
  "Providence College",
  "Puget Sound Christian College",
  "Purdue University",
  "Purdue University Calumet",
  "Purdue University North Central",
  "Quantum-Veritas International University",
  "Queens College",
  "Quincy University",
  "Quinnipiac College",
  "Radford University",
  "Ramapo College of New Jersey",
  "Rand Graduate School of Policy Studies",
  "Randolph-Macon College",
  "Randolph-Macon Woman's College",
  "Rasmussen College",
  "Rasmussen College, Florida Campuses",
  "Rasmussen College, Illinois Campuses",
  "Rasmussen College, Minnesota Campuses",
  "Rasmussen College, North Dakota Campuses",
  "Rasmussen College, Wisconsin Campuses",
  "Reed College",
  "Reformed Bible College",
  "Regent International University",
  "Regent University",
  "Regis College",
  "Regis University",
  "Reinhardt College",
  "Rensselaer Polytechnic Institute",
  "Research College of Nursing - Rockhurst University",
  "Rhode Island College",
  "Rhode Island School of Design",
  "Rhodes College",
  "Rice University",
  "Richard Stockton College of New Jersey",
  "Rider University",
  "Ringling College of Art and Design",
  "Ripon College",
  "Rivier College",
  "Roanoke Bible College",
  "Roanoke College",
  "Robert Morris College",
  "Robert Morris College of Chicago",
  "Roberts Wesleyan College",
  "Rochester College",
  "Rochester Institute of Technology",
  "Rockford College",
  "Rockhurst College",
  "Rock Valley College",
  "Rocky Mountain College",
  "Rocky Mountain College of Art and Design",
  "Rogers State University",
  "Roger Williams University",
  "Rollins College",
  "Roosevelt University",
  "Rose-Hulman Institute of Technology",
  "Rosemont College",
  "Ross University, School of Medicine",
  "Rowan University",
  "Rush University",
  "Russel Sage College",
  "Rust College",
  "Rutgers, The State University of New Jersey",
  "Rutgers, The State University of New Jersey - Camden",
  "Rutgers, The State University of New Jersey - Newark",
  "Sacred Heart University",
  "Sage Graduate School",
  "Saginaw Valley State University",
  "Salem College",
  "Salem International University",
  "Salem State College",
  "Salem Teikyo University",
  "Salisbury State University",
  "Salve Regina University",
  "Samford University",
  "Sam Houston State University",
  "Samuel Merritt College",
  "San Diego State University",
  "San Diego University for Integrative Studies",
  "Sanford-Brown Institute",
  "San Francisco Art Institute",
  "San Francisco Conservatory of Music",
  "San Francisco State University",
  "San Joaquin College of Law",
  "San Jose Christian College",
  "San Jose State University",
  "Santa Clara University",
  "Sarah Lawrence College",
  "Savannah College of Art and Design",
  "Savannah State University",
  "Saybrook Institute",
  "Schiller International University",
  "Scholl College of Podiatric Medicine",
  "School for International Training",
  "School of the Museum of Fine Arts",
  "School of the Visual Arts",
  "Schreiner College",
  "Scripps College",
  "Seattle Pacific University",
  "Seattle University",
  "Seton Hall University",
  "Seton Hill College",
  "Shawnee Community College",
  "Shawnee State University",
  "Shaw University",
  "Sheldon Jackson College",
  "Shenandoah University",
  "Shepherd College",
  "Sherman College of Straight Chiropractic",
  "Shimer College",
  "Shippensburg University of Pennsylvania",
  "Shoreline Community College",
  "Shorter College",
  "Siena College",
  "Siena Heights University",
  "Sierra Nevada College",
  "Silver Lake College",
  "Simmons College",
  "Simon's Rock College",
  "Simpson College",
  "Simpson College Iowa",
  "Sinte Gleska University",
  "Skadron College",
  "Skidmore College",
  "Slippery Rock University",
  "Smith College",
  "Sojourner-Douglass College",
  "Soka University of America",
  "Sonoma State University",
  "South Carolina State University",
  "South Dakota School of Mines and Technology",
  "South Dakota State University",
  "Southeastern Baptist College",
  "Southeastern Bible College",
  "Southeastern College of the Assemblies of God",
  "Southeastern Louisiana University",
  "Southeastern Oklahoma State University",
  "Southeastern University",
  "Southeast Missouri State University",
  "Southern Adventist University",
  "Southern Arkansas University",
  "Southern California College",
  "Southern California College of Optometry",
  "Southern California Institute of Architecture",
  "Southern College of Optometry",
  "Southern Connecticut State University",
  "Southern Illinois University at Carbondale",
  "Southern Illinois University at Edwardsville",
  "Southern Methodist University",
  "Southern Nazarene University",
  "Southern New Hampshire University",
  "Southern Oregon University",
  "Southern Polytechnic State Univerisity",
  "Southern University - Baton Rouge",
  "Southern University - New Orleans",
  "Southern University - Shreveport",
  "Southern Utah University",
  "Southern Vermont College",
  "Southern Wesleyan University",
  "South Florida Bible College & Theological Seminary",
  "South Texas College of Law",
  "Southwest Baptist University",
  "Southwestern Adventist University",
  "Southwestern Assemblies of God University",
  "Southwestern Baptist Theological Seminary",
  "Southwestern Christian College",
  "Southwestern Christian University",
  "Southwestern College Kansas",
  "Southwestern College Santa Fe",
  "Southwestern Oklahoma State University",
  "Southwestern University",
  "Southwestern University School of Law",
  "Southwest Missouri State University",
  "Southwest Missouri State University - West Plains",
  "Southwest State University",
  "Southwest Texas State University",
  "Southwest University",
  "Spalding University",
  "Spelman College",
  "Spertus Institute of Jewish Studies",
  "Spring Arbor College",
  "Springfield College",
  "Spring Hill College",
  "St. Ambrose University",
  "Standford Online University",
  "Standford University",
  "St. Andrews Presbyterian College",
  "Stanford University",
  "St. Anselm College",
  "St. Anthony College of Nursing",
  "State University of New York at Albany",
  "State University of New York at Binghamton",
  "State University of New York at Buffalo",
  "State University of New York at New Paltz",
  "State University of New York at Oswego",
  "State University of New York at Stony Brook",
  "State University of New York College at Brockport",
  "State University of New York College at Cortland",
  "State University of New York College at Fredonia",
  "State University of New York College at Geneseo",
  "State University of New York College at Old Westbury",
  "State University of New York College at Oneonta",
  "State University of New York College at Plattsburgh",
  "State University of New York College at Potsdam",
  "State University of New York College at Purchase",
  "State University of New York College of Agriculture and Technology at Cobleskill",
  "State University of New York College of Environmental Science and Forestry",
  "State University of New York College of Optometry",
  "State University of New York College of Technology at Alfred",
  "State University of New York College of Technology at Farmingdale",
  "State University of New York Downstate Medical Center",
  "State University of New York Empire State College",
  "State University of New York Health Sience Centre Syracuse",
  "State University of New York Institute of Technology at Utica/Rome",
  "State University of New York Maritime College",
  "State University of New York School of Engineering and Applied Sciences",
  "State University of New York (SUNY)",
  "State University of New York Upstate Medical University ",
  "State University of West Georgia",
  "St. Augustine's College North Carolina",
  "St. Bernard's Institute",
  "St. Bonaventure University",
  "St. Cloud State University",
  "St. Edwards University",
  "Stefan University",
  "Stephen F. Austin State University",
  "Stephens College",
  "Sterling College",
  "Stetson University",
  "Stevens Institute of Technology",
  "St. Francis College, Brooklyn Heights",
  "St. Francis College, Fort Wayne",
  "St. Francis College, Loretto",
  "St. Francis Medical Center College of Nursing",
  "St. George's University",
  "Stillman College",
  "St. John Fisher College",
  "St. John's College Maryland",
  "St. John's College New Mexico",
  "St. John's Seminary",
  "St. John's University",
  "St. Joseph College",
  "St. Joseph College of Nursing",
  "St. Joseph's College",
  "St. Joseph's College New York",
  "St. Joseph's College New York, Suffolk Campus",
  "St. Joseph's College of Maine",
  "St. Joseph's University",
  "St. Lawrence University",
  "St. Leo College",
  "St. Louis Christian College",
  "St. Louis College of Pharmacy",
  "St. Louis University",
  "St. Luke's College",
  "St. Martin's College",
  "St. Mary College",
  "St. Mary-of-the-Woods College",
  "St. Mary's College Indiana",
  "St. Mary's College of California",
  "St. Mary's College of Maryland",
  "St. Mary's University of Minnesota",
  "St. Mary's University of San Antonio",
  "St. Meinrad College",
  "St. Michael's College",
  "St. Norbert College",
  "St. Olaf College",
  "Stonehill College",
  "St. Paul's College",
  "St. Petersburg College",
  "St. Peter's College",
  "Strayer University",
  "St. Thomas Aquinas College",
  "St. Thomas University",
  "St. Vincent College",
  "St. Xavier University",
  "Suffolk University",
  "Sullivan College",
  "Sul Ross State University",
  "Susquehanna University",
  "Swarthmore College",
  "Sweet Briar College",
  "Syracuse University",
  "Tabor College",
  "Talladega College",
  "Tarleton State University",
  "Taylor University",
  "Taylor University, Fort Wayne Campus",
  "Teachers College, Columbia University",
  "Temple University",
  "Temple University School of Podiatric Medicine",
  "Tennessee State University",
  "Tennessee Technological University",
  "Tennessee Temple University",
  "Tennessee Wesleyan College",
  "Texas A&M International University",
  "Texas A&M University",
  "Texas A&M University - Commerce",
  "Texas A&M University - Corpus Christi",
  "Texas A&M University - Galveston",
  "Texas A&M University - Kingsville",
  "Texas A&M University - Texarkana",
  "Texas Chiropractic College",
  "Texas Christian University",
  "Texas College",
  "Texas College of Osteopathic Medicine",
  "Texas Lutheran University",
  "Texas Southern University",
  "Texas Tech University",
  "Texas Tech University Health Science Center",
  "Texas Wesleyan University",
  "Texas Woman's University",
  "The American College",
  "The Art Institute of Boston",
  "The Art Institutes International Portland",
  "The Art Institutes International San Francisco",
  "The Boston Conservatory",
  "The Catholic University of America",
  "The Chicago School of Professional Psychology",
  "The College of Insurance",
  "The College of New Jersey",
  "The College of Santa Fe",
  "The College of St. Scholastica",
  "The College of Westchester",
  "The College of Wooster",
  "The Cooper Union for the Advancement of Science and Art",
  "The Corcoran College of Art",
  "The Curtis Institute of Music",
  "The Defiance College",
  "The Dickinson School of Law",
  "The Illinois Institute of Art-Chicago",
  "The Johns Hopkins University",
  "The Juilliard School",
  "The Leadership Institute of Seattle",
  "The Maryland Institute, College of Art",
  "The Master's College",
  "The McGregor School of Antioch University",
  "The Naropa Institute",
  "The New School",
  "The Rockefeller University",
  "The School of the Art Institute of Chicago",
  "The Scripps Research Institute",
  "The Southern Christian University",
  "The Tulane University of New Orleans",
  "The Union Institute",
  "Thiel College",
  "Thomas A. Edison State College",
  "Thomas Aquinas College",
  "Thomas College Maine",
  "Thomas Jefferson University",
  "Thomas More College",
  "Thomas More College of Liberal Arts",
  "Thomas University",
  "Thunderbird School of Global Management",
  "Tiffin University",
  "Toccoa Falls College",
  "Tomball College",
  "Tougaloo College",
  "Touro College",
  "Touro University",
  "Towson University",
  "Transylvania University",
  "Trevecca Nazarene University",
  "Tri-College University",
  "Trident University",
  "Trinity Bible College",
  "Trinity Christian College",
  "Trinity College Connecticut",
  "Trinity College of Florida",
  "Trinity College of Vermont",
  "Trinity International University",
  "Trinity International University (Excel), Miami",
  "Trinity University",
  "Trinity University",
  "Tri-State University",
  "Triton College",
  "Troy University",
  "Troy University, Dothan",
  "Troy University, Montgomery",
  "Troy University, Phenix City",
  "Troy University, Troy",
  "Truman College",
  "Truman State University",
  "Tufts University",
  "Tui Online University",
  "Tusculum College",
  "Tuskegee University",
  "Uniformed Services Universty of the Health Sciences",
  "Union College",
  "Union College Kentucky",
  "Union College Nebraska",
  "Union Theological Seminary (UTS)",
  "Union University",
  "United States Air Force Academy",
  "United States Coast Guard Academy",
  "United States International University",
  "United States Merchant Marine Academy",
  "United States Military Academy",
  "United States Naval Academy",
  "United States Sports Academy",
  "Unity College",
  "University of Advancing Technology (UAT)",
  "University of Akron",
  "University of Alabama - Birmingham",
  "University of Alabama - Huntsville",
  "University of Alabama - Tuscaloosa",
  "University of Alanta",
  "University of Alaska - Anchorage",
  "University of Alaska - Fairbanks",
  "University of Alaska - Southeast",
  "University of Alaska (System)",
  "University of Arizona",
  "University of Arkansas at Fayetteville",
  "University of Arkansas at Little Rock",
  "University of Arkansas at Monticello",
  "University of Arkansas at Pine Bluff",
  "University of Arkansas for Medical Sciences",
  "University of Arkansas (System)",
  "University of Baltimore",
  "University of Bridgeport",
  "University of California, Berkeley",
  "University of California, Davis",
  "University of California, Hastings College of Law",
  "University of California, Irvine",
  "University of California, Los Angeles",
  "University of California, Merced",
  "University of California, Oakland",
  "University of California, Riverside",
  "University of California, San Diego",
  "University of California, San Francisco",
  "University of California, Santa Barbara",
  "University of California, Santa Cruz",
  "University of California System",
  "University of Central Arkansas",
  "University of Central Florida",
  "University of Central Missouri",
  "University of Central Oklahoma",
  "University of Central Texas",
  "University of Charleston",
  "University of Charleston South Carolina",
  "University of Chicago",
  "University of Cincinnati",
  "University of Colorado at Boulder",
  "University of Colorado at Colorado Springs",
  "University of Colorado at Denver",
  "University of Colorado Health Sciences Center",
  "University of Connecticut",
  "University of Connecticut at Avery Point",
  "University of Connecticut at Hartford",
  "University of Connecticut at Stamford",
  "University of Connecticut at Waterbury",
  "University of Connecticut Health Center",
  "University of Dallas",
  "University of Dayton",
  "University of Delaware",
  "University of Denver",
  "University of Detroit Mercy",
  "University of Dubuque",
  "University of Evansville",
  "University of Findlay",
  "University of Florida",
  "University of Georgia",
  "University of Great Falls",
  "University of Hartford",
  "University of Hawaii - Hilo",
  "University of Hawaii - Manoa",
  "University Of Hawaii - System",
  "University of Hawaii - West Oahu",
  "University of Health Sciences",
  "University of Houston",
  "University of Houston, Clear Lake",
  "University of Houston, Downtown",
  "University of Houston, Victoria",
  "University of Idaho",
  "University of Illinois",
  "University of Illinois at Chicago",
  "University of Illinois at Springfield",
  "University of Illinois at Urbana-Champaign",
  "University of Indianapolis",
  "University of Iowa",
  "University of Kansas",
  "University of Kentucky",
  "University of La Verne",
  "University of Louisiana at Lafayette",
  "University of Louisiana at Monroe",
  "University of Louisville",
  "University of Maine, Augusta",
  "University of Maine, Farmington",
  "University of Maine, Fort Kent",
  "University of Maine, Machias",
  "University of Maine, Orono",
  "University of Maine, Presque Isle",
  "University of Maine (System)",
  "University of Management & Technology",
  "University of Mary",
  "University of Mary Hardin-Baylor",
  "University of Maryland at Baltimore",
  "University of Maryland at College Park",
  "University of Maryland Baltimore County",
  "University of Maryland Eastern Shore",
  "University of Maryland Medicine",
  "University of Maryland (System)",
  "University of Maryland University College",
  "University of Massachusetts at Amherst",
  "University of Massachusetts at Boston",
  "University of Massachusetts at Dartmouth",
  "University of Massachusetts at Lowell",
  "University of Massachusetts Medical Center at Worcester",
  "University of Massachusetts (System)",
  "University of Medicine and Dentistry of New Jersey",
  "University of Memphis",
  "University of Miami",
  "University of Michigan - Ann Arbor",
  "University of Michigan - Dearborn",
  "University of Michigan - Flint",
  "University of Minnesota - Crookston",
  "University of Minnesota - Duluth",
  "University of Minnesota - Morris",
  "University of Minnesota - Twin Cities Campus",
  "University of Mississippi",
  "University of Mississippi Medical Center",
  "University of Missouri - Columbia",
  "University of Missouri - Kansas City",
  "University of Missouri - Saint Louis",
  "University of Mobile",
  "University of Montana",
  "University of Montana Western",
  "University of Montevallo",
  "University of Nebraska - Kearney",
  "University of Nebraska - Lincoln",
  "University of Nebraska Medical Center",
  "University of Nebraska - Omaha",
  "University of Nebraska (System)",
  "University of Nevada - Las Vegas",
  "University of Nevada - Reno",
  "University of New England",
  "University of New England, Westbrook College Campus",
  "University of New Hampshire",
  "University of New Hampshire - Manchester",
  "University of New Haven",
  "University of New Mexico",
  "University of New Orleans",
  "University of North Alabama",
  "University of North America",
  "University of North Carolina at Asheville",
  "University of North Carolina at Chapel Hill",
  "University of North Carolina at Charlotte",
  "University of North Carolina at Greensboro",
  "University of North Carolina at Pembroke",
  "University of North Carolina at Wilmington",
  "University of North Dakota",
  "University of Northern Colorado",
  "University of Northern Iowa",
  "University of Northern Virginia",
  "University of Northern Washington",
  "University of North Florida",
  "University of North Texas",
  "University of North Texas Health Science Center at Fort Worth",
  "University of NorthWest",
  "University of Notre Dame",
  "University of Oklahoma",
  "University of Oklahoma Health Sciences Center",
  "University of Oregon",
  "University of Osteopathic Medicine and Health Science",
  "University of Pennsylvania",
  "University of Phoenix",
  "University of Pittsburgh",
  "University of Pittsburgh at Bradford",
  "University of Pittsburgh at Greensburg",
  "University of Pittsburgh at Johnstown",
  "University of Portland",
  "University of Puget Sound",
  "University of Redlands",
  "University of Rhode Island",
  "University of Richmond",
  "University of Rio Grande",
  "University of Rochester",
  "University of San Diego",
  "University of San Francisco",
  "University of Science and Arts of Oklahoma",
  "University of Scranton",
  "University of Sioux Falls",
  "University of South Alabama",
  "University of South Carolina",
  "University of South Carolina - Aiken",
  "University of South Carolina - Beaufort",
  "University of South Carolina - Lancaster",
  "University of South Carolina - Salkehatchie",
  "University of South Carolina - Spartanburg",
  "University of South Carolina - Sumter",
  "University of South Carolina - Union",
  "University of South Dakota",
  "University of Southern California",
  "University of Southern Indiana",
  "University of Southern Maine",
  "University of Southern Mississippi",
  "University of South Florida",
  "University of St. Francis",
  "University of St. Thomas, Houston",
  "University of St. Thomas, St. Paul",
  "University of Tampa",
  "University of Tennessee - Chattanooga",
  "University of Tennessee - Knoxville",
  "University of Tennessee - Martin",
  "University of Tennessee - Memphis",
  "University of Tennessee Space Institute",
  "University of Texas",
  "University of Texas at Arlington",
  "University of Texas at Austin",
  "University of Texas at Brownsville",
  "University of Texas at Dallas",
  "University of Texas at El Paso",
  "University of Texas at San Antonio",
  "University of Texas at Tyler",
  "University of Texas Health Center at Houston",
  "University of Texas Health Center at Tyler",
  "University of Texas Health Science Center at San Antonio",
  "University of Texas M.D. Anderson Cancer Center",
  "University of Texas Medical Branch Galveston",
  "University of Texas of the Permian Basin",
  "University of Texas Pan American",
  "University of Texas Southwestern Medical Center at Dallas",
  "University of the Arts",
  "University of the District of Columbia",
  "University of the Incarnate World",
  "University of the Ozarks",
  "University of the Pacific",
  "University of the Sciences in Philadelphia",
  "University of the South",
  "University of the Southwest",
  "University of Toledo",
  "University of Tulsa",
  "University of Utah",
  "University of Vermont",
  "University of Virginia",
  "University of Virginia, College at Wise",
  "University of Washington",
  "University of Washington, Tacoma",
  "University of West Alabama",
  "University of West Florida",
  "University of West Los Angeles",
  "University of Wisconsin - Eau Claire",
  "University of Wisconsin - Green Bay",
  "University of Wisconsin - La Crosse",
  "University of Wisconsin - Madison",
  "University of Wisconsin - Milwaukee",
  "University of Wisconsin - Oshkosh",
  "University of Wisconsin - Parkside",
  "University of Wisconsin - Platteville",
  "University of Wisconsin - River Falls",
  "University of Wisconsin - Stevens Point",
  "University of Wisconsin - Stout",
  "University of Wisconsin - Superior",
  "University of Wisconsin - Whitewater",
  "University of Wyoming",
  "Upper Iowa University",
  "Urbana University",
  "Ursinus College",
  "Ursuline College",
  "Utah State University",
  "Utah Valley State College",
  "Utica College",
  "Valdosta State University",
  "Valley City State University",
  "Valley Forge Christian College",
  "Valparaiso University",
  "Vanderbilt University",
  "VanderCook College of Music",
  "Vanguard University of Southern California",
  "Vassar College",
  "Vennard College",
  "Vermont Law School",
  "Vermont Technical College",
  "Villa Julie College",
  "Villanova University",
  "Virginia College",
  "Virginia Commonwealth University",
  "Virginia Intermont College",
  "Virginia International University",
  "Virginia Military Institute",
  "Virginia Polytechnic Institute and State University (Virginia Tech)",
  "Virginia State University",
  "Virginia Union University",
  "Virginia Wesleyan College",
  "Viterbo College",
  "Voorhees College",
  "Wabash College",
  "Wagner College",
  "Wake Forest University",
  "Walden University",
  "Walla Walla College",
  "Walsh College of Accountancy and Business Administration",
  "Walsh University",
  "Warner Pacific College",
  "Warner Southern College",
  "Warren Wilson College",
  "Wartburg College",
  "Washburn University",
  "Washington and Lee University",
  "Washington Bible College",
  "Washington College",
  "Washington State University",
  "Washington State University, Spokane",
  "Washington State University, Tri-Cities",
  "Washington State University, Vancouver",
  "Washington University in St. Louis",
  "Wayland Baptist University",
  "Waynesburg College",
  "Wayne State College",
  "Wayne State University",
  "Webber College",
  "Webb Institute",
  "Weber State University",
  "Webster University",
  "Webster University North Florida",
  "Weill Medical College of Cornell University",
  "Wellesley College",
  "Wells College",
  "Wentworth Institute of Technology",
  "Wesleyan College",
  "Wesleyan University",
  "Wesley College",
  "Wesley College Mississippi",
  "Westbrook University ",
  "West Chester University of Pennsylvania",
  "West Coast University",
  "Western Baptist College",
  "Western Bible College",
  "Western Carolina University",
  "Western Connecticut State University",
  "Western Governors University",
  "Western Illinois University",
  "Western International University",
  "Western Kentucky University",
  "Western Maryland College",
  "Western Michigan University",
  "Western New England College",
  "Western New Mexico University",
  "Western Oregon University",
  "Western State College",
  "Western States Chiropractic College",
  "Western State University College of Law",
  "Western State University College of Law - Orange County",
  "Western Washington University",
  "Westfield State College",
  "West Liberty State College",
  "Westminster College Fulton",
  "Westminster College New Wilmington",
  "Westminster College of Salt Lake City",
  "Westmont College",
  "West Suburban College of Nursing",
  "West Texas A&M University",
  "West Virginia School of Osteopathic Medicine",
  "West Virginia State College",
  "West Virginia University",
  "West Virginia University Institute of Technology",
  "West Virginia Wesleyan College",
  "Westwood College",
  "Wheaton College Massachusetts",
  "Wheeling Jesuit University",
  "Wheelock College",
  "Whitman College",
  "Whittier College",
  "Whitworth College",
  "Wichita State University",
  "Widener University",
  "Wilberforce University",
  "Wilbur Wright College",
  "Wiley College",
  "Wilkes University",
  "Willamette University",
  "William Carey College",
  "William Jewell College",
  "William Mitchell College of Law",
  "William Paterson University",
  "William Penn College",
  "Williams Baptist College",
  "Williams College",
  "William Tyndale College",
  "William Woods University",
  "Wilmington College",
  "Wilmington College",
  "Wilson College",
  "Wingate University",
  "Winona State University",
  "Winston-Salem State University",
  "Winthrop University",
  "Wisconsin Lutheran College",
  "Wisconsin School of Professional Psychology",
  "Wittenberg University",
  "Wofford College",
  "Woodbury University",
  "Worcester Polytechnic Institute",
  "Worcester State College",
  "Wright Institute",
  "Wright State University",
  "Xavier University",
  "Xavier University of Louisiana",
  "Yale University",
  "Yeshiva University",
  "York College Nebraska",
  "York College of Pennsylvania",
  "Yorker International University",
  "York University",
  "Youngstown State University",
];
/*
 * To-do:
 * ☐ Tooltip only shows when text field has content (placeholder is absent)
 */

/*
 * Props
 * - name: Name of field
 * - placeholder: Field placeholder, also used for tooltip
 * - value: Value of field
 * - onChange: Associated field event handler
 */

function TemplateTextField(props) {
  const { placeholder, handleChange, name, value, index, group, disabled } =
    props;
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{placeholder}</Tooltip>}
    >
      <Form.Control
        placeholder={placeholder}
        onChange={(e) => handleChange(e, index, group)}
        name={name}
        value={value}
        disabled={disabled}
      />
    </OverlayTrigger>
  );
}

function TemplateDateField(props) {
  const { placeholder, handleChange, name, value, index, group, disabled } =
    props;
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{placeholder}</Tooltip>}
    >
      <Form.Control
        type="date"
        format="MM-DD-YYYY"
        placeholder={placeholder}
        onChange={(e) => handleChange(e, index, group)}
        name={name}
        value={value}
        disabled={disabled}
      />
    </OverlayTrigger>
  );
}

/*
 * Props
 * - name: Name of field
 * - placeholder: Field placeholder, also used for tooltip
 * - value: Value of field
 * - onChange: Associated field event handler
 */

function TemplateFileField(props) {
  const { placeholder, handleChange, name, value, index, disabled } = props;

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{placeholder}</Tooltip>}
    >
      <Form.Control
        type="file"
        placeholder={placeholder}
        onChange={(e) => handleChange(e, index)}
        name={name}
        value={value}
        disabled={disabled}
      />
    </OverlayTrigger>
  );
}

/*
 * Props
 * - name: Name of field
 * - tooltip: String description of field
 * - value: Value of field
 * - onChange: Change event handler
 * - options: String array of selection entries
 */

function TemplateSelectField(props) {
  const { name, value, handleChange, tooltip, index, group, disabled } = props;
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <Form.Select
        name={name}
        value={value}
        onChange={(e) => handleChange(e, index, group)}
        disabled={disabled}
      >
        <option key=""></option>
        {props.options.map((key) => (
          <option key={key}>{key}</option>
        ))}
      </Form.Select>
    </OverlayTrigger>
  );
}

/*
 * Props
 * - name: Name of field
 * - tooltip: String descrition of field
 * - value: Value of field
 * - onChange: Change event handler
 */

function TemplateLargeTextField(props) {
  const { tooltip, handleChange, name, value, index, group, disabled } = props;

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <Form.Control
        as="textarea"
        style={{ height: "80px" }}
        name={name}
        value={value}
        onChange={(e) => handleChange(e, index, group)}
        disabled={disabled}
      />
    </OverlayTrigger>
  );
}

const SectionWrapper = (props) => {
  return (
    <div style={{ display: "flex", gap: 12 }}>
      {Children.map(props.children, (child) => {
        return cloneElement(child, {
          index: props.index,
          group: props.group,
          value: props.value ? props.value[props.index][child.props.name] : "",
          disabled:
            props.value[props.index][child.props.name] === null ||
            props.value[props.index][child.props.name] == undefined
              ? true
              : false,
        });
      })}
    </div>
  );
};

export function AddressList(props) {
  const addressTemplate = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    workAuthorization: "",
    requireSponsorship: "",
  };

  const { address, handleChange } = props;
    if(props.modal && address[0]){
      for (const key in addressTemplate) {
        if (address[0].hasOwnProperty(key) && (address[0][key] === null || address[0][key] === undefined)) {
          addressTemplate[key] = null;
        }
      }
    }
  return (
    <FormObjectList
      name="Address"
      object={address}
      objectTemplate={addressTemplate}
      group="addresses"
    >
      <SectionWrapper>
        <TemplateTextField
          placeholder="Address Line 1"
          name="addressLine1"
          handleChange={handleChange}
        />
        <TemplateTextField
          placeholder="Address Line 2"
          name="addressLine2"
          handleChange={handleChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateTextField
          style={{ flex: "2 1 0" }}
          placeholder="City"
          name="city"
          handleChange={handleChange}
        />
        <TemplateTextField
          style={{ flex: "2 1 0" }}
          placeholder="State"
          name="state"
          handleChange={handleChange}
        />
        <SearchSelect
          options={countries}
          name="country"
          handleChange={handleChange}
          style={{ flex: "2 1 0" }}
        />
        {/* <TemplateTextField
                    style={{ flex: "2 1 0" }}
                    placeholder="Country"
                    name="country"
                    handleChange={handleChange}
                /> */}
        <TemplateTextField
          style={{ flex: "2 1 0" }}
          placeholder="ZIP Code"
          name="zipcode"
          handleChange={handleChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateSelectField
          options={["Authorized to Work", "Not Authorized to Work"]}
          tooltip="Work Authorization: US"
          name="workAuthorization"
          handleChange={handleChange}
        />
        <TemplateSelectField
          options={["No Sponsorship Required", "Sponsorship Required"]}
          tooltip="Work Sponsorship: US"
          name="requireSponsorship"
          handleChange={handleChange}
        />
      </SectionWrapper>
    </FormObjectList>
  );
}

export function DegreeList(props) {
  const degreeTemplate = {
    university: "",
    degreeLevel: "",
    major: "",
    gpa: "",
    transcript: "",
    startDate: "",
    endDate: "",
  };

  const { degrees, handleChange, handleFileChange } = props;
  if(props.modal && degrees[0]){
    for (const key in degreeTemplate) {
      if (degrees[0].hasOwnProperty(key) && (degrees[0][key] === null || degrees[0][key] === undefined)) {
        degreeTemplate[key] = null;
      }
    }
  }
  console.log(degreeTemplate)
  return (
    <FormObjectList
      name="Degree"
      object={degrees}
      objectTemplate={degreeTemplate}
      group="degrees"
    >
      <SectionWrapper>
        <SearchSelect
          options={universities}
          // placeholder="School or University"
          // style={{ flex: "5 1 0" }}
          name="university"
          handleChange={handleChange}
        />
        <TemplateSelectField
          tooltip="Degree Level"
          options={[
            "Associate's Degree",
            "Bachelor's Degree",
            "Master's Degree",
            "Doctoral Degree",
          ]}
          name="degreeLevel"
          handleChange={handleChange}
          style={{ flex: "3 1 0" }}
        />
      </SectionWrapper>
      <SectionWrapper>
        {/* <TemplateTextField
                    placeholder="Major"
                    name="major"
                    handleChange={handleChange}
                    style={{ flex: "5 1 0" }}
                /> */}
        {/* Example below for Avinash when putting in form options for university and major */}
        <SearchSelect
          options={majors}
          name="major"
          handleChange={handleChange}
          style={{ flex: "5 1 0" }}
        />
        <TemplateTextField
          placeholder="GPA"
          style={{ flex: "2 1 0" }}
          name="gpa"
          handleChange={handleChange}
        />
        <TemplateFileField
          placeholder="Transcript"
          style={{ flex: "3 1 0" }}
          name="transcript"
          handleChange={handleFileChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateDateField
          placeholder="Start Date"
          name="startDate"
          handleChange={handleChange}
        />
        <TemplateDateField
          placeholder="End Date"
          name="endDate"
          handleChange={handleChange}
        />
      </SectionWrapper>
    </FormObjectList>
  );
}

export function ExperienceList(props) {
  const experienceTemplate = {
    company: "",
    location: "",
    jobCategory: "",
    jobTitle: "",
    level: "",
    type: "",
    startDate: "",
    endDate: "",
    description: "",
  };

  const { experiences, handleChange } = props;
  if(props.modal && experiences[0]){
    for (const key in experienceTemplate) {
      if (experiences[0].hasOwnProperty(key) && (experiences[0][key] === null || experiences[0][key] === undefined)) {
        experienceTemplate[key] = null;
      }
    }
  }
  //console.log(experienceTemplate)
  return (
    <FormObjectList
      name="Experience"
      object={experiences}
      objectTemplate={experienceTemplate}
      group="experiences"
    >
      <SectionWrapper>
        <TemplateTextField
          placeholder="Company"
          name="company"
          handleChange={handleChange}
        />
        <TemplateTextField
          placeholder="Location"
          name="location"
          handleChange={handleChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateTextField
          placeholder="Job Title"
          name="jobTitle"
          handleChange={handleChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateSelectField
          tooltip="Job Level"
          options={["Entry", "Associate", "Senior", "Director", "Executive"]}
          name="level"
          handleChange={handleChange}
        />
        <TemplateSelectField
          tooltip="Job Type"
          options={[
            "Full-time",
            "Part-time",
            "Contract",
            "Internship",
            "Volunteer",
          ]}
          name="type"
          handleChange={handleChange}
        />
        <TemplateDateField
          placeholder="Start Date"
          name="startDate"
          handleChange={handleChange}
        />
        <TemplateDateField
          placeholder="End Date"
          name="endDate"
          handleChange={handleChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateLargeTextField
          tooltip="Description"
          name="description"
          handleChange={handleChange}
        />
      </SectionWrapper>
    </FormObjectList>
  );
}

export function ProjectList(props) {
  const projectTemplate = {
    title: "",
    startDate: "",
    endDate: "",
    description: "",
    skillsUsed: [],
  };

  const { projects, handleChange } = props;
  if(props.modal && projects[0]){
    for (const key in projectTemplate) {
        if (projects[0].hasOwnProperty(key) && (projects[0][key] === null || projects[0][key] === undefined)) {
          projectTemplate[key] = null;
        }
      }
    }
  return (
    <FormObjectList
      name="Project"
      object={projects}
      objectTemplate={projectTemplate}
      group="projects"
    >
      <SectionWrapper>
        <TemplateTextField
          placeholder="Title"
          name="title"
          style={{ flex: "2 1 0" }}
          handleChange={handleChange}
        />
        <TemplateDateField
          placeholder="Start Date"
          name="startDate"
          style={{ flex: "1 1 0" }}
          handleChange={handleChange}
        />
        <TemplateDateField
          placeholder="End Date"
          name="endDate"
          style={{ flex: "1 1 0" }}
          handleChange={handleChange}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TemplateLargeTextField
          tooltip="Description"
          name="description"
          handleChange={handleChange}
        />
      </SectionWrapper>
      <TemplateTextField
        placeholder="Skills Used"
        name="skillsUsed"
        handleChange={handleChange}
      />
    </FormObjectList>
  );
}
