import Card from "react-bootstrap/Card";
import DefaultLogo from "../../components/DefaultLogo";
import Image from "react-bootstrap/Image";

/*
 * To-do:
 * ☐ Fix minute display dropping leading zeroes
 * ☐ More versitile date string conversion
 */

/*
 * Props
 * - data: Structure containing all information to be displayed in a JobCard
 *   - title: String of job title
 *   - company: String name of company
 *   - logo: Image link of company logo
 *   - tag: String category of application
 *   - timestamp: Date when tag was last updated
 *   - appID: ID of application for modal
 */

function AppCard(props) {
    function dateToString(date) {
        const today = new Date(Date.now());
        let monthName = new Intl.DateTimeFormat("en-US", { month: "long" })
            .format;
        //let shortMonthName = new Intl.DateTimeFormat("en-US", {month: "short"}).format;

        // If 'date' is today, display the hour
        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            return (
                "Today at " + (date.getHours() % 12) + ":" + date.getMinutes()
            );
        }
        // Display month and date
        return monthName(date) + " " + date.getDate();
    }

    return (
        <Card
            className="border-0"
            style={{
                height: 96,
                width: "100%",
                background: "#EFEEFB",
            }}
            onClick={() => {
                props.handleAppClick(props.data);
            }}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 8,
                    gap: 8,
                }}
            >
                {/* Logo */}
                <div
                    style={{
                        height: 80,
                        width: 80,
                    }}
                >
                    {props.data.logo ? (
                        <Image
                            src={props.data.logo}
                            rounded
                            style={{ height: 80 }}
                        />
                    ) : (
                        <DefaultLogo title={props.data.company} />
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flex: "1 1 0",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <h6 style={{ fontWeight: "bold", marginBottom: 0 }}>
                            {props.data.title}
                        </h6>
                        <div style={{ fontSize: 14 }}>{props.data.company}</div>
                    </div>
                    <div style={{ fontSize: 14 }}>
                        {props.data.tag} {dateToString(props.data.timestamp)}
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default AppCard;
