import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./redux/store";

import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    userPoolId: "us-east-1_1LVkJDieK", // UserPool ID
    region: "us-east-1",
    userPoolWebClientId: "1gc4itr78v14aj06haou4nangs", // WebClient ID
    oauth: {
      scope: [
        "phone",
        "email",
        "openid",
        "opususer-login/user.read",
        "opususer-login/update_user",
        "opususer-login/create_user",
      ],
      redirectSignIn: "https://app.opus.careers",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
