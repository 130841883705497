import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import parse from "html-react-parser";

const initialState = {
  jobs: [],
  status: "idle",
  error: null,
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = state.jobs.concat(action.payload);
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async ({ uuid, accessToken }) => {
    const request = `https://api.opus.careers/job?id=${uuid}`;
    const headers = new Headers();
    headers.append("Authorization", accessToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    const response = await fetch(request, requestOptions);
    const data = await response.json();

    const jobs = data.map((job) => {
      return {
        id: nanoid(),
        title: job.PositionTitle,
        logo: null,
        description: parse(job.Description),
        jobID: job.jobID,
        details: {
          company: job.Company,
          industry: job.WorkType.split("Industries")[1],
          location: job.WorkLocation,
          level:
            job.WorkType.split("Seniority level ")[1].split(
              "Employment type"
            )[0],
          type: job.WorkType.split("Employment type ")[1].split(
            "Job function"
          )[0],
        },
        posted: job.DatePosted,
      };
    });

    return jobs;
  }
);

// export const {} = jobsSlice.actions;

export const selectAllJobs = (state) => state.jobs.jobs;
export const selectJobByIdx = (state, jobIdx) => state.jobs.jobs[jobIdx];

export default jobsSlice.reducer;
