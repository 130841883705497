import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs, selectAllJobs } from "../../redux/reducers/jobsSlice";

import { Spinner } from "react-bootstrap";
import JobCard from "./JobCard";

import "../../styles/Rec.css";
import { v4 as uuidv4 } from "uuid";

export default function RecPage(props) {
    const { uuid, accessToken } = props;

    const dispatch = useDispatch();
    const jobs = useSelector(selectAllJobs);

    const jobStatus = useSelector((state) => state.jobs.status);
    const error = useSelector((state) => state.jobs.error);

    const [topCard, setTopCard] = useState(0); // Index of top card
    const [slide, setSlide] = useState(""); // Controls animations to slide card off screen

    useEffect(() => {
        if (jobStatus === "idle" || jobStatus === "error") {
            dispatch(fetchJobs({ uuid, accessToken }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobStatus, dispatch]);

    // Job Actions - 'apply', 'save', 'decline'
    function handleJobAction(type) {
        // Set-up animation for selected Job Card
        if (type === "apply") {
            setSlide("Up");
        } else if (type === "save") {
            setSlide("Right");
        } else {
            setSlide("Left");
        }

        const content = jobs.at(topCard);
        setTopCard(topCard + 1);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);
        myHeaders.append("Content-Type", "text/plain");
        console.log(uuidv4());
        let raw, gateway;
        if (type === "apply") {
            console.log("apply is called");
            raw = JSON.stringify({
                proID: props.uuid,
                appID: uuidv4(),
                userID: props.uuid,
                jobID: content.jobID,
                Company: content.details.company.toLowerCase(),
                PositionTitle: content.title.toLowerCase(),
                WorkLocation: content.details.location.toLowerCase(),
                LastUpdatedTime: Math.floor(Date.now() / 1000),
                ApplicationStatus: "incomplete",
            });
            gateway = "https://api.opus.careers/application";
        } else {
            // 'type' is "save" or "decline"
            raw = JSON.stringify({
                jobID: content.jobID,
            });
            gateway = `https://api.opus.careers/job/${type}?id=${uuid}`;
        }

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        // Make API Call
        fetch(gateway, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
            })
            .catch((error) => console.log("error", error));
    }

    function getCardClassName(index) {
        let className = "jobCard ";

        if (index === topCard - 1) {
            className += "prevCard slideOut" + slide;
        } else if (index === topCard) {
            className += "topCard ";
        }

        return className;
    }

    let content;

    if (jobStatus === "loading") {
        content = <Spinner variant="light" style={{ width: 50, height: 50 }} />;
    } else if (jobStatus === "succeeded") {
        content = jobs.map((content, index) => (
            <div
                key={index}
                className={getCardClassName(index)}
                style={{ borderRadius: 6 }}
            >
                <JobCard
                    content={content}
                    apply={() => handleJobAction("apply")}
                    decline={() => handleJobAction("decline")}
                    save={() => handleJobAction("save")}
                    disabled={index === topCard ? false : true}
                />
            </div>
        ));
    } else if (jobStatus === "failed") {
        content = (
            <div>
                <h3>Something went wrong!</h3>
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div
            className="background"
            style={{
                display: "flex",
                flex: 1,
            }}
        >
            {/* Card stack */}
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {content}
            </div>
        </div>
    );
}
