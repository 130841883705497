import "../../styles/LoginPage.css";
import { useState } from "react";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import { signIn } from "../../auth/AuthUtils";
import { useNavigate } from "react-router";

const LoginPage = (props) => {
  let navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(credentials["username"], credentials["password"], (result) => {
      setCredentials(null);
      navigate("/jobrec");
    });
  };

  return (
    <div className="m-auto w-25">
      <h2 className="my-3 fw-bold">Login</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className="my-3"
          controlId="signup.username"
        >
          <Form.Label className="fw-bold">Username</Form.Label>
          <Form.Control
            className="rounded-3 px-3 py-3"
            type="text"
            value={credentials.username}
            onChange={(e) =>
              setCredentials({ ...credentials, username: e.target.value })
            }
            required
          />
        </Form.Group>
        <Form.Group
          className="my-3"
          controlId="signup.password"
        >
            <div className="d-flex justify-content-between">
            <Form.Label className="fw-bold">Password</Form.Label>
            <a className="mx-2 text-secondary" href="/password_reset">Forgot?</a>
            </div>
          <Form.Control
            className="rounded-3 px-3 py-3"
            type="password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            required
          />
        </Form.Group>
          <Button
            className="w-100 py-3 my-3 rounded-pill"
            variant="dark"
            type="submit"
          >
            Sign In
          </Button>
          <p className="text-center">
            Don't have an account? 
            <a className="mx-2" href="/register">Sign up</a>
          </p>
      </Form>
    </div>
  );
};

export default LoginPage;
