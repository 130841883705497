import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../styles/Global.css";

/*
 * To-do:
 * ☐ Only use tooltip when text content is actually being truncated
 */

/*
 * Props
 * - text: String of content
 */

function TruncatedText(props) {
	function isEllipsisActive(element) {
		return element.offsetWidth <= element.scrollWidth;
	}

	return (
		<OverlayTrigger
			placement="top"
			overlay={<Tooltip>{props.text}</Tooltip>}
		>
			<h5 className="trunkatedText">{props.text}</h5>
		</OverlayTrigger>
	);
}

export default TruncatedText;
