import FormArrow from "./FormArrow";
import Form from "react-bootstrap/Form";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import MultiTextInput from "../../components/MultiTextInput";
import MultiSelectSearchDropdown from "../../components/MultiSelectSearchDropdown";
import * as Unicons from "@iconscout/react-unicons";
import * as Templates from "../../components/FOLTemplates";
import { useNavigate } from 'react-router-dom';
import "../../styles/AccountSetUp.css";
import "../../styles/Global.css";
import { useState } from "react";
const languages = [
  "Afrikaans",
  "Albanian",
  "Arabic",
  "Armenian",
  "Basque",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cambodian",
  "Chinese (Mandarin)",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Fiji",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Quechua",
  "Romanian",
  "Russian",
  "Samoan",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Tibetan",
  "Tonga",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Xhosa",
];

function AccountSetUpPage(props) {
  const { accessToken, uuid } = props;
  const navigate = useNavigate();

  const [sectionKey, setSectionKey] = useState("basics");
  const [previousSectionKey, setPreviousSectionKey] = useState("");
  const [nextSectionKey, setNextSectionKey] = useState("identity");
  const [input, setInput] = useState({
    name: { first: "", middle: "", last: "" },
    email: { primary: "" },
    phone: { mobile: "" },
    addresses: [
      {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        workAuthorization: "Authorized to Work",
        requireSponsorship: "No Sponsorship Required",
      },
    ],
    identity: {
      gender: "",
      firstGen: "",
      financialAidEligibility: "",
      orientation: "",
      veteranStatus: "",
    },
    pronouns: [],
    ethnicity: [],
    disabilities: [],
    degrees: [
      {
        university: "",
        degreeLevel: "",
        major: "",
        gpa: "",
        transcript: "",
      },
    ],
    testScores: [],
    experiences: [
      {
        company: "",
        location: "",
        jobCategory: "",
        jobTitle: "",
        level: "",
        type: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    resume: null,
    resume_link: "",
    achievements: "",
    spokenLanguages: [""],
    linkedin: "",
    projects: [
      {
        title: "",
        startDate: "",
        endDate: "",
        description: "",
        skillsUsed: "",
      },
    ],
    otherSkills: [],
    roles: [],
    locations: [],
    jobtypes: [],
    preferences: {
      interestedLevels: [],
      interestedModalities: "",
      desiredSalaryRange: "",
      companySize: "",
      companyStage: "",
      industry: "",
      culture: "",
      militaryInvolvement: "",
      mostImportantValues: [],
      mostImportantPersonalityTraits: [],
    },
  });
  const handleChange = (e) => {
    const { value, name } = e.target;

    if (e.target.attributes["data-group"] === undefined) {
      setInput({ ...input, [name]: value });
    } else {
      const group = e.target.attributes["data-group"].value;
      const category = { ...input[group] };
      category[name] = value;
      setInput({ ...input, [group]: category });
    }
  };
  const handleResumeChange = (e, i) => {
    const { files } = e.target;
    let file = files[0];
    setInput({
      ...input,
      resume: file,
    });
  };
  const handleFOL = (e, i, group) => {
    const { value, name } = e.target;
    console.log(value, name, group, i);
    const newGroup = input[group];

    if (i < 0 || i > newGroup.length) return console.error();
    else {
      setInput({
        ...input,
        [group]: newGroup.map((obj, index) => {
          if (index === i) {
            return { ...obj, [name]: value };
          } else {
            return obj;
          }
        }),
      });
    }
  };
  const setSelections = (group, data) => {
    setInput({ ...input, [group]: data });
  };
  const setPreferenceSelections = (group, data) => {
    setInput({
      ...input,
      ["preferences"]: { ...input.preferences, [group]: data },
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const handleComplete = async (e) => {
	e.preventDefault();

	var myHeaders = new Headers();
	myHeaders.append("Authorization", accessToken);
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({
		first_name: input["name"].first,
		last_name: input["name"].last,
		middle_name: input["name"].middle,
		phone_number: input["phone"].mobile,
		pronouns: input["pronouns"],
		financial_aid: input["identity"].financialAidEligibility,
		addresses: input["addresses"],
		degrees: input["degrees"],
		experiences: input["experiences"],
		scores: input["testScores"],
		gender: input["identity"].gender,
		orientation: input["identity"].orientation,
		ethnicity: input["ethnicity"],
		veteran: input["identity"].veteranStatus,
		first_gen_college: input["identity"].firstGen,
		disability: input["disabilities"],
	});

	var requestOptions = {
		method: "PATCH",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	fetch(`https://api.opus.careers/user?id=${uuid}`, requestOptions)
		.then((response) => response.text())
		.then()
		.catch((error) => console.log("error", error));
	handleProfile();
};
const handleProfile = async() => {
	var myHeaders = new Headers();
	myHeaders.append("Authorization", accessToken);
	myHeaders.append("Content-Type", "text/plain");

	var raw = JSON.stringify({
		achievements: input["achievements"],
		spokenLanguages: input["spokenLanguages"],
		linkedin: input["linkedin"],
		projects: input["projects"],
		otherSkills: input["otherSkills"],
		preferences: input["preferences"],
		roles: input["roles"],
		locations: input["locations"],
		jobtypes: input["jobtypes"]
	});

	var requestOptions = {
		method: "PATCH",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};

	fetch(`https://api.opus.careers/profile?id=${uuid}`, requestOptions)
		.then((response) => response.json())
		.then((result) => console.log(result))
		.catch((error) => console.log("error", error));

	handleResume();
};
const handleResume = async() => {
	var myHeaders = new Headers();
	myHeaders.append("id", uuid);
	myHeaders.append("Authorization", accessToken);
	myHeaders.append("Content-Type", "application/pdf");

	var file = input["resume"];

	var requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: file,
		redirect: "follow",
	};

	fetch("https://api.opus.careers/profile/resume", requestOptions)
		.then((response) => response.text())
		.then((result) => {
			console.log(result)
			navigate("/jobrec")
		})
		.catch((error) => console.log("error", error));
};
  /*
   * To-do:
   * ☐ Add "key" prop to remove error
   * Consider Sign up acceleration by uploading LinkedIn profile or connecting with Opus Careers Page
   */
  /*
   * Each section has a title field and a content field
   * - name: String describing form section content
   * - title: String displayed at the top of the view
   * - content: Form fields for the section
   */
  const sections = {
    basics: {
      name: "Basics",
      title: "Let's start with the basics",
    },
    identity: {
      name: "Identity",
      title: "Now tell us about who you are",
    },
    address: {
      name: "Address",
      title: "Where are you searching from?",
    },
    education: {
      name: "Education",
      title: "Where did you go to school?",
    },
    work: {
      name: "Career",
      title: "Tell us about your work history",
    },
    highlights: {
      name: "Highlights",
      title: "What sets you apart?",
    },
    preferences: {
      name: "Preferences",
      title: "And lastly, share your interests",
    },
  };
  const basics = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 24,
        }}
      >
        {/* Name row */}
        <div style={{ display: "flex", gap: 24 }}>
          <Form.Group>
            <Form.Label className="text-white">First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              data-group="name"
              name="first"
              value={input["name"].first}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-white">Middle Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              data-group="name"
              name="middle"
              value={input["name"].middle}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-white">Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              data-group="name"
              name="last"
              value={input["name"].last}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        {/* Contact info row */}
        <div style={{ display: "flex", gap: 24 }}>
          <Form.Group style={{ flex: "1 1 0" }}>
            <Form.Label className="text-white">Email</Form.Label>
            <Form.Control
              className="form--email"
              type="email"
              placeholder=""
              data-group="email"
              name="primary"
              value={input["email"].primary}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
          <Form.Group style={{ flex: "1 1 0", width: 350 }}>
            <Form.Label className="text-white">Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="+1 123-456-789"
              data-group="phone"
              name="mobile"
              value={input["phone"].mobile}
              onChange={handleChange}
            />
          </Form.Group>
        </div>
        {/* Profile Picture */}
        {/* <Form.Group
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Form.Label className="text-white">Profile Picture</Form.Label>
					<div
						style={{
							width: 120,
							height: 120,
							backgroundColor: "white",
							borderRadius: "50%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							cursor: "pointer",
						}}
					>
						<Unicons.UilCameraPlus size="60" color="#D9D9D9" />
					</div>
				</Form.Group> */}
      </div>
    );
  };

  const identity = () => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "250px 250px",
          gridTemplateRows: "repeat(6, auto)",
          columnGap: 100,
          rowGap: 24,
        }}
      >
        <Form.Group>
          <Form.Label className="text-white">Gender</Form.Label>
          <Form.Select
            data-group="identity"
            name="gender"
            value={input["identity"].gender}
            onChange={handleChange}
          >
            <option>Unspecified</option>
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">
            1st Gen College Student
          </Form.Label>
          <Form.Select
            data-group="identity"
            name="firstGen"
            value={input["identity"].firstGen}
            onChange={handleChange}
          >
            <option>Unspecified</option>
            <option>No</option>
            <option>Yes</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white"> Sexual Orientation</Form.Label>
          <Form.Select
            data-group="identity"
            name="orientation"
            value={input["identity"].orientation}
            onChange={handleChange}
          >
            <option>Unspecified</option>
            <option>Heterosexual</option>
            <option>Homosexual</option>
            <option>Bisexual</option>
            <option>Queer</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">
            Financial Aid Eligibility
          </Form.Label>
          <Form.Select
            data-group="identity"
            name="financialAidEligibility"
            value={input["identity"].financialAidEligibility}
            onChange={handleChange}
          >
            <option>None</option>
            <option>Eligible</option>
            <option>Not Eligible</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">Disabilities</Form.Label>
          <MultiSelectDropdown
            options={["Yes", "No", "Decline to self-identify"]}
            selected={input["disabilities"]}
            group={"disabilities"}
            setSelections={setSelections}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">Veteran</Form.Label>
          <Form.Select
            data-group="identity"
            name="veteranStatus"
            value={input["identity"].veteranStatus}
            onChange={handleChange}
          >
            <option>Unspecified</option>
            <option>No</option>
            <option>Yes</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">Ethnicity</Form.Label>
          <MultiSelectDropdown
            options={[
              "Hispanic or Latino",
              "White",
              "Black or African American",
              "Native Indian or Alaska Native",
              "Asian",
              "Native Hawaiian or Other Pacific Islander",
              "Two or More Races",
              "Decline to self-identify",
            ]}
            selected={input["ethnicity"]}
            group={"ethnicity"}
            setSelections={setSelections}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="text-white">Pronouns</Form.Label>
          <MultiSelectDropdown
            options={[
              "He",
              "Him",
              "She",
              "Her",
              "They",
              "Them",
              "Other",
              "Unspecified",
            ]}
            selected={input["pronouns"]}
            group={"pronouns"}
            setSelections={setSelections}
          />
        </Form.Group>
      </div>
    );
  };

  const address = () => {
    return (
      <div>
        <Form.Label className="text-white">Addresses</Form.Label>
        <Templates.AddressList
          address={input["addresses"]}
          handleChange={handleFOL}
        />
      </div>
    );
  };

  const education = () => {
    return (
      <div>
        <Form.Label className="text-white">Degrees</Form.Label>
        <Templates.DegreeList
          degrees={input["degrees"]}
          handleChange={handleFOL}
          handleFileChange={() => {}}
        />
      </div>
    );
  };

  const work = () => {
    return (
      <div>
        <Form.Label className="text-white">Experiences</Form.Label>
        <Templates.ExperienceList
          experiences={input["experiences"]}
          handleChange={handleFOL}
        />
      </div>
    );
  };

  const highlights = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "250px 250px",
            gridTemplateRows: "repeat(1, auto)",
            justifyContent: "space-between",
            rowGap: 24,
          }}
        >
          <Form.Group>
            <Form.Label className="text-white">Resume</Form.Label>
            <Form.Control
              type="file"
              onChange={handleResumeChange}
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label className="text-white">Achievements</Form.Label>
            <Form.Control />
          </Form.Group> */}
          <Form.Group>
            <Form.Label className="text-white">Spoken Languages</Form.Label>
            <MultiSelectSearchDropdown
              options={languages}
              selected={input["spokenLanguages"]}
              group={"spokenLanguages"}
              setSelections={setSelections}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <Form.Label className="text-white">LinkedIn</Form.Label>
            <Form.Control
              type="text"
              name="linkedin"
              value={input["linkedin"]}
              onChange={handleChange}
              placeholder="https://www.linkedin.com/..."
            />
          </Form.Group>
        </div>
        <div>
          <Form.Label className="text-white">Projects</Form.Label>
          <Templates.ProjectList
            projects={input["projects"]}
            handleChange={handleFOL}
          />
        </div>
        <Form.Group>
          <Form.Label className="text-white">Other Skills</Form.Label>
          <MultiTextInput
            selected={input["otherSkills"]}
            group={"otherSkills"}
            setSelections={setSelections}
          />
        </Form.Group>
      </div>
    );
  };

  const preferences = () => {
    return (
      <div>
        {/* Bandage spacing solution for auto grid column gap*/}
        <div style={{ width: 600 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 48,
          }}
        >
          <div>
            <Form.Label
              as="h4"
              className="text-white"
              style={{ marginBottom: 12, fontWeight: "bold" }}
            >
              The Job
            </Form.Label>
            <div>
              <Form.Group>
                <Form.Label className="text-white">Interested Roles</Form.Label>
                <MultiTextInput
                  selected={input["roles"]}
                  group={"roles"}
                  setSelections={setSelections}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label className="text-white">Desired Location</Form.Label>
                <MultiTextInput
                  selected={input["locations"]}
                  group={"locations"}
                  setSelections={setSelections}
                />
              </Form.Group>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "250px 250px",
                gridTemplateRows: "repeat(2, auto)",
                justifyContent: "space-between",
                rowGap: 24,
              }}
            >
              <Form.Group>
                <Form.Label className="text-white">Interested Type</Form.Label>
                <MultiSelectDropdown
                  options={[
                    "Full-time",
                    "Part-time",
                    "Contract",
                    "Temporary",
                    "Volunteer",
                  ]}
                  selected={input["jobtypes"]}
                  group={"jobtypes"}
                  setSelections={setSelections}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">
                  Interested Modalities
                </Form.Label>
                <MultiSelectDropdown
                  options={["Remote", "On-Site", "Hybrid"]}
                  selected={input["preferences"].interestedModalities}
                  group={"interestedModalities"}
                  setSelections={setPreferenceSelections}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">
                  Interested Levels
                </Form.Label>
                <MultiSelectDropdown
                  options={[
                    "Intern",
                    "Entry",
                    "Associate",
                    "Senior",
                    "Management",
                    "Director",
                    "Executive",
                  ]}
                  selected={input["preferences"].interestedLevels}
                  group={"interestedLevels"}
                  setSelections={setPreferenceSelections}
                />
              </Form.Group>
              {/* <Form.Group>
                <Form.Label className="text-white">
                  Desired Salary Range
                </Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  placeholder="Disabled"
                />
              </Form.Group> */}
            </div>
          </div>
          {/* <div>
            <Form.Label
              as="h4"
              className="text-white"
              style={{ marginBottom: 12, fontWeight: "bold" }}
            >
              The Company
            </Form.Label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "250px 250px",
                gridTemplateRows: "repeat(2, auto)",
                justifyContent: "space-between",
                rowGap: 24,
              }}
            >
              <Form.Group>
                <Form.Label className="text-white">Company Size</Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  placeholder="Disabled"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">Company Stage</Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  placeholder="Disabled"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">Industry</Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  placeholder="Disabled"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">Culture</Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  placeholder="Disabled"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">
                  Military Involvement
                </Form.Label>
                <Form.Control
                  disabled
                  readOnly
                  placeholder="Disabled"
                />
              </Form.Group>
            </div>
          </div> */}
          {/* <div>
            <Form.Label
              as="h4"
              className="text-white"
              style={{ marginBottom: 12, fontWeight: "bold" }}
            >
              About You
            </Form.Label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "250px 250px",
                gridTemplateRows: "repeat(2, auto)",
                justifyContent: "space-between",
                rowGap: 24,
              }}
            >
              <Form.Group>
                <Form.Label className="text-white">
                  Most Important Values
                </Form.Label>
                <MultiSelectDropdown
                  options={["Option A", "Option B", "Option C"]}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-white">
                  Most Important Personality Traits
                </Form.Label>
                <MultiSelectDropdown
                  options={["Option A", "Option B", "Option C"]}
                />
              </Form.Group>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  function handleForwards() {
    console.log(input);
    const sectionKeys = Object.keys(sections);
    const currentIndex = sectionKeys.indexOf(sectionKey);

    // Check if we are not at the last section
    if (currentIndex < sectionKeys.length - 1) {
      const nextIndex = currentIndex + 1;
      const nextSectionKey = sectionKeys[nextIndex];
      setPreviousSectionKey(sectionKey);
      setSectionKey(nextSectionKey);
      setNextSectionKey(sectionKeys[nextIndex + 1] || ""); // Set nextSectionKey or an empty string if at the end
    }
  }

  function handleBackwards() {
    const sectionKeys = Object.keys(sections);
    const currentIndex = sectionKeys.indexOf(sectionKey);

    // Check if we are not at the first section
    if (currentIndex > 0) {
      const previousIndex = currentIndex - 1;
      const previousSectionKey = sectionKeys[previousIndex];
      setNextSectionKey(sectionKey);
      setSectionKey(previousSectionKey);
      setPreviousSectionKey(sectionKeys[previousIndex - 1] || ""); // Set previousSectionKey or an empty string if at the beginning
    }
  }

  function renderSection() {
    switch (sectionKey) {
      case "basics":
        return basics();
      case "identity":
        return identity();
      case "address":
        return address();
      case "education":
        return education();
      case "work":
        return work();
      case "highlights":
        return highlights();
      case "preferences":
        return preferences();
      default:
        return null; // Handle unknown section keys or provide a default behavior
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        background: "#19186B",
      }}
    >
      {/* Section */}
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          gap: 100,
        }}
      >
        {/* Back Navigation */}
        <div style={{ width: 200 }}>
          {sectionKey !== "basics" ? (
            <FormArrow
              back
              text={"Return to " + sections[previousSectionKey].name}
              onClick={handleBackwards}
            />
          ) : null}
        </div>
        {/* Content */}
        <div
          style={{
            width: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <h2
            className="text-white"
            style={{ fontWeight: "bold", marginBottom: 48 }}
          >
            {sections[sectionKey].title}
          </h2>
          <div
            style={{
              overflowX: "visible",
              overflowY: "auto",
            }}
          >
            {renderSection()}
          </div>
        </div>
        {/* Forward Navigation */}
        <div style={{ width: 200 }}>
          {sectionKey !== "preferences" ? (
            <FormArrow
              text={"Continue to " + sections[nextSectionKey].name}
              onClick={handleForwards}
            />
          ) : (
            <FormArrow
              text={"Complete"}
              onClick={handleComplete}
            />
          )}
        </div>
      </div>
      {/* Progress bar */}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          height: 6,
          width: `calc(${
            Object.keys(sections).indexOf(sectionKey) /
            Object.keys(sections).length
          } * 100vw)`,
          backgroundColor: "white",
        }}
      />
    </div>
  );
}

export default AccountSetUpPage;
