import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';

import React, { useEffect, useState, useRef } from "react";

function MultiSelectSearchDropdown(props) {
  const [options, setOptions] = useState(props.options);
  const [selected, setSelected] = useState(props.selected ? props.selected : []);
  const { group, setSelections } = props;
  const [searchText, setSearchText] = useState(""); // For searchable dropdown
  const [isDropdownOpen, setDropdownOpen]= useState(false)
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected);
      setOptions(options.filter(option => !props.selected.includes(option)));
    }
  }, []);
  const handleOutsideClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSelect = (event, option) => {
    const newOptions = options.filter(item => item !== option);
    setOptions(newOptions);
    const newSelected = [...selected, option];
    newSelected.sort((a, b) => a.localeCompare(b));
    setSelected(newSelected);
    setSelections(group, newSelected);
    setSearchText("")
  };
  const handleAddOther = () => {
    const newSelected = [...selected, searchText];
    newSelected.sort((a, b) => a.localeCompare(b));
    setSelected(newSelected);
    setSelections(group, newSelected);
    setSearchText("")
  }
  const handleDeselect = (event, option) => {
    const newSelected = selected.filter(item => item !== option);
    setSelected(newSelected);
    setSelections(group, newSelected);

    const newOptions = [...options, option];
    newOptions.sort((a, b) => a.localeCompare(b));
    setOptions(newOptions);
  };

  const handleClear = () => {
    const newOptions = [...options, ...selected];
    newOptions.sort((a, b) => a.localeCompare(b));
    setOptions(newOptions);
    setSelected([]);
    setSelections(group, []);
    setSearchText("")
  };

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchText.toLowerCase())
  );

  const dropdownOptions = filteredOptions.map((option, index) => (
    <Dropdown.Item
      key={index}
      title={option}
      onClick={event => handleSelect(event, option)}
      style={{
        display: "block",
        width: "100%",
        padding: "2px 10px",
        clear: "both",
        fontWeight: 400,
        color: "black",
        textAlign: "inherit",
        textDecoration: "none",
        whiteSpace: "nowrap",
        backgroundColor: "transparent",
        border: "0"
          }}
    >
      {option}
    </Dropdown.Item>
  ));

  const badges = selected.map((badge, index) => (
    <div key={index}>
      <Badge
        pill
        bg="primary"
        title={badge}
        onClick={event => handleDeselect(event, badge)}
        style={{ cursor: "pointer", marginRight: "4px" }}
      >
        {badge}
      </Badge>
    </div>
  ));

  return (
    <div ref={dropdownRef}>
      <div
        style={{
          position: "relative",
          display: "inline-block"
        }}
      >
        <InputGroup>
        <Form.Control
          type="text"
          placeholder="English..."
          onClick={() => setDropdownOpen(true)}
          onChange={event => setSearchText(event.target.value)}
          value={searchText}
          style={{borderRightWidth: "0px"}}
        />
        <InputGroup.Text style={{
          backgroundColor: "white"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ced4da" className="bi bi-search" viewBox="0 0 16 16">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
        </InputGroup.Text>
        </InputGroup>
        
        {isDropdownOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "100%",
              maxHeight: "150px",
              overflowY: "auto",
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              marginTop: "5px",
              boxSizing: "content-box", /* Add this line */
              paddingTop: "8px"
            }}
          >
            {filteredOptions.length > 0 ? (
              dropdownOptions
            ) : (
              <Dropdown.ItemText style={{
                display: "block",
                width: "100%",
                padding: "2px 10px",
                clear: "both",
                fontWeight: 400,
                color: "black",
                textAlign: "inherit",
                textDecoration: "none",
                whiteSpace: "nowrap",
                backgroundColor: "transparent",
                border: "0"
              }} onClick={handleAddOther}>
                Other: {searchText}
              </Dropdown.ItemText>
            )}
            <div style={{
              position: "-webkit-sticky", /* Safari */
              position: "sticky",
              bottom: "0",
              background: "white",
              paddingBottom: "8px"
            }}>
            <Dropdown.Divider style={{
              borderTop: "1px solid #ced4da",
              marginTop: "5px",
              marginBottom: "5px"
              }} />
            <Dropdown.Item onClick={handleClear}
              style={{
                display: "block",
                width: "100%",
                padding: "2px 10px",
                clear: "both",
                fontWeight: 400,
                color: "black",
                textAlign: "inherit",
                textDecoration: "none",
                whiteSpace: "nowrap",
                backgroundColor: "transparent",
                border: "0"
              }}>
            Clear selection
          </Dropdown.Item>
          </div>
          </div>
        )}
        
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "6px",
          marginTop: "6px"
        }}
      >
        {selected.length > 0 ? (
          badges
        ) : (
          <Badge pill bg="primary">
            None
          </Badge>
        )}
      </div>
    </div>
  );
}

export default MultiSelectSearchDropdown;
