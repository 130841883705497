import "../../styles/UserInfo.css";

import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import MultiSelectSearchDropdown from "../../components/MultiSelectSearchDropdown";
import { Nav, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";

import * as Templates from "../../components/FOLTemplates";
import SubmitToast from "../../components/SubmitToast";
import MultiTextInput from "../../components/MultiTextInput";

const languages = [
    "Afrikaans",
    "Albanian",
    "Arabic",
    "Armenian",
    "Basque",
    "Bengali",
    "Bulgarian",
    "Catalan",
    "Cambodian",
    "Chinese (Mandarin)",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Fiji",
    "Finnish",
    "French",
    "Georgian",
    "German",
    "Greek",
    "Gujarati",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Irish",
    "Italian",
    "Japanese",
    "Javanese",
    "Korean",
    "Latin",
    "Latvian",
    "Lithuanian",
    "Macedonian",
    "Malay",
    "Malayalam",
    "Maltese",
    "Maori",
    "Marathi",
    "Mongolian",
    "Nepali",
    "Norwegian",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romanian",
    "Russian",
    "Samoan",
    "Serbian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swahili",
    "Swedish",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tibetan",
    "Tonga",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Welsh",
    "Xhosa",
];

const UserInfoPage = (props) => {
    const { accessToken, uuid } = props;
    const [submitResult, setSubmitResult] = useState("");
    const [input, setInput] = useState({
        name: { first: "", middle: "", last: "" },
        email: { primary: "" },
        phone: { mobile: "" },
        addresses: [
            {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                workAuthorization: "Authorized to Work",
                requireSponsorship: "No Sponsorship Required",
            },
        ],
        identity: {
            gender: "",
            firstGen: "",
            financialAidEligibility: "",
            orientation: "",
            veteranStatus: "",
        },
        pronouns: [],
        ethnicity: [],
        disabilities: [],
        degrees: [
            {
                university: "",
                degreeLevel: "",
                major: "",
                gpa: "",
                transcript: "",
                startDate: "",
                endDate: ""            
            },
        ],
        testScores: [],
        experiences: [
            {
                company: "",
                location: "",
                jobCategory: "",
                jobTitle: "",
                level: "",
                type: "",
                startDate: "",
                endDate: "",
                description: "",
            },
        ],
        resume: null,
        resume_link: "",
        achievements: "",
        spokenLanguages: [""],
        linkedin: "",
        projects: [
            {
                title: "",
                startDate: "",
                endDate: "",
                description: "",
                skillsUsed: "",
            },
        ],
        otherSkills: [],
        roles: [],
        locations: [],
        jobtypes: [],
        preferences: {
            interestedLevels: [],
            interestedModalities: "",
            desiredSalaryRange: "",
            companySize: "",
            companyStage: "",
            industry: "",
            culture: "",
            militaryInvolvement: "",
            mostImportantValues: [],
            mostImportantPersonalityTraits: [],
        },
    });
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        fetch(`https://api.opus.careers/user?id=${uuid}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: accessToken,
            },
        })
            .then((response) => response.json())
            .then((body) => {
                let data = body[0];
                loadUserAPI(data);
            })
            .catch((error) => console.log(error));

        // eslint-disable-next-line
    }, []);

    const loadUserAPI = (data) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(`https://api.opus.careers/profile?id=${uuid}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setInput({
                    ...input,
                    name: {
                        first: data["first_name"],
                        middle: data["middle_name"],
                        last: data["last_name"],
                    },
                    email: { primary: data["public_email"] },
                    phone: { mobile: data["phone_number"] },
                    addresses:
                        data["addresses"] === undefined ||
                        data["addresses"].length === 0
                            ? [
                                  {
                                      addressLine1: "",
                                      addressLine2: "",
                                      city: "",
                                      state: "",
                                      country: "",
                                      zipcode: "",
                                      workAuthorization: "Authorized to Work",
                                      requireSponsorship:
                                          "No Sponsorship Required",
                                  },
                              ]
                            : data["addresses"],
                    identity: {
                        gender: data["gender"],
                        firstGen: data["first_gen_college"],
                        financialAidEligibility: data["financial_aid"],
                        orientation: data["orientation"],
                        veteranStatus: data["veteran"],
                    },
                    pronouns: data["pronouns"],
                    ethnicity: data["ethnicity"],
                    disabilities: data["disabilities"],
                    degrees:
                        data["degrees"] === undefined ||
                        data["degrees"].length === 0
                            ? [
                                  {
                                      university: "",
                                      degreeLevel: "",
                                      major: "",
                                      gpa: "",
                                      transcript: "",
                                      startDate: "",
                                      endDate: ""                                  
                                  },
                              ]
                            : data["degrees"],
                    testScores: data["scores"],
                    experiences:
                        data["experiences"] === undefined ||
                        data["experiences"].length === 0
                            ? [
                                  {
                                      company: "",
                                      location: "",
                                      jobCategory: "",
                                      jobTitle: "",
                                      level: "",
                                      type: "",
                                      startDate: "",
                                      endDate: "",
                                      description: "",
                                  },
                              ]
                            : data["experiences"],
                    achievements: result["achievements"],
                    linkedin: result["linkedin"],
                    spokenLanguages: result["spokenLanguages"],
                    projects: result["projects"],
                    otherSkills: result["otherSkills"],
                    preferences: result["preferences"],
                    roles: result["roles"],
                    locations: result["locations"],
                    jobtypes: result["jobtypes"],
                });
            })
            .catch((error) => console.log("error", error));

        loadResume();
    };

    const loadResume = () => {
        var myHeaders = new Headers();
        myHeaders.append("id", uuid);
        myHeaders.append("Authorization", accessToken);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch("https://api.opus.careers/profile/resume", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                input["resume_link"] = result;
            })
            .catch((error) => console.log("error", error));
    };

    const handleProfile = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);
        myHeaders.append("Content-Type", "text/plain");

        var raw = JSON.stringify({
            achievements: input["achievements"],
            spokenLanguages: input["spokenLanguages"],
            linkedin: input["linkedin"],
            projects: input["projects"],
            otherSkills: input["otherSkills"],
            preferences: input["preferences"],
            roles: input["roles"],
            locations: input["locations"],
            jobtypes: input["jobtypes"],
        });

        var requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`https://api.opus.careers/profile?id=${uuid}`, requestOptions)
            .then((response) => response.json())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));

        handleResume();
    };

    const handleResume = () => {
        var myHeaders = new Headers();
        myHeaders.append("id", uuid);
        myHeaders.append("Authorization", accessToken);
        myHeaders.append("Content-Type", "application/pdf");

        var file = input["resume"];

        var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: file,
            redirect: "follow",
        };

        fetch("https://api.opus.careers/profile/resume", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));

        loadResume();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("Authorization", accessToken);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            first_name: input["name"].first,
            last_name: input["name"].last,
            middle_name: input["name"].middle,
            phone_number: input["phone"].mobile,
            pronouns: input["pronouns"],
            financial_aid: input["identity"].financialAidEligibility,
            addresses: input["addresses"],
            degrees: input["degrees"],
            experiences: input["experiences"],
            scores: input["testScores"],
            gender: input["identity"].gender,
            orientation: input["identity"].orientation,
            ethnicity: input["ethnicity"],
            veteran: input["identity"].veteranStatus,
            first_gen_college: input["identity"].firstGen,
            disability: input["disabilities"],
        });

        var requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(`https://api.opus.careers/user?id=${uuid}`, requestOptions)
            .then((response) => response.text())
            .then((result) => showSuccessToast(result))
            .catch((error) => console.log("error", error));
        handleProfile();
    };
    const showSuccessToast = (result) => {
        setSubmitResult(result);
        console.log(result);
        setShowToast(true);
    };
    const toggleShowToast = () => {
        setShowToast(false);
    };
    const handleChange = (e) => {
        const { value, name } = e.target;

        if (e.target.attributes["data-group"] === undefined) {
            setInput({ ...input, [name]: value });
        } else {
            const group = e.target.attributes["data-group"].value;
            const category = { ...input[group] };
            category[name] = value;
            setInput({ ...input, [group]: category });
        }
    };

    const handleTranscriptFileChange = (e, i) => {
        const { files, name } = e.target;
        let file = files[0];
        const newDegrees = input["degrees"];

        if (i < 0 || i > newDegrees.length) return console.error();
        else {
            setInput({
                ...input,
                degrees: newDegrees.map((obj, index) => {
                    if (index === i) {
                        return { ...obj, [name]: file };
                    } else {
                        return obj;
                    }
                }),
            });
        }
    };

    const handleResumeChange = (e, i) => {
        const { files } = e.target;
        let file = files[0];
        setInput({
            ...input,
            resume: file,
        });
    };

    const handleFOL = (e, i, group) => {
        const { value, name } = e.target;
        console.log(value, name, group, i);
        const newGroup = input[group];

        if (i < 0 || i > newGroup.length) return console.error();
        else {
            setInput({
                ...input,
                [group]: newGroup.map((obj, index) => {
                    if (index === i) {
                        return { ...obj, [name]: value };
                    } else {
                        return obj;
                    }
                }),
            });
        }
    };

    const setSelections = (group, data) => {
        setInput({ ...input, [group]: data });
    };
    const setPreferenceSelections = (group, data) => {
        setInput({
            ...input,
            ["preferences"]: { ...input.preferences, [group]: data },
        });
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    console.log(input["resume_link"]);

    return (
        <div className="user--info__wrapper">
            {showToast ? (
                <SubmitToast
                    result={submitResult}
                    toggleShowToast={toggleShowToast}
                    showToast={showToast}
                    style={{
                        position: "absolute",
                        zIndex: 7,
                    }}
                    className="sticky-top"
                ></SubmitToast>
            ) : (
                <></>
            )}
            <Form
                className="user--info"
                onSubmit={handleSubmit}
                onKeyPress={handleKeyPress}
            >
                <div className="side--nav">
                    <Nav
                        defaultActiveKey="#"
                        variant="light"
                        className="flex-column sticky-top"
                        style={{
                            height: "100vh",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "white",
                        }}
                    >
                        <Nav.Link href="#account" className="side--nav__link">
                            Account
                        </Nav.Link>
                        <Nav.Link href="#education" className="side--nav__link">
                            Education
                        </Nav.Link>
                        <Nav.Link
                            href="#workhistory"
                            className="side--nav__link"
                        >
                            Work History
                        </Nav.Link>
                        <Nav.Link href="#profile" className="side--nav__link">
                            Profile
                        </Nav.Link>
                        <Nav.Link
                            href="#preferences"
                            className="side--nav__link"
                        >
                            Preferences
                        </Nav.Link>

                        <Button
                            type="submit"
                            style={{
                                position: "absolute",
                                bottom: "50px",
                                width: "125px",
                                height: "45px",
                                fontWeight: "700",
                                border: "none",
                                boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.2)",
                                backgroundColor: "#4466ee",
                            }}
                        >
                            Save
                        </Button>
                    </Nav>
                </div>
                <div className="contents">
                    <h2 className="contents--title">User Information</h2>

                    <section id="account" className="user--info__section">
                        <h3 className="section--title">Account</h3>
                        <div className="section--information">
                            <Form.Group
                                controlId="userInfo.FullName"
                                className="form--group"
                            >
                                <Form.Label className="form--label">
                                    Full Name
                                </Form.Label>

                                <div className="flex-row">
                                    <div className="form-floating">
                                        <Form.Control
                                            className="input--box"
                                            type="text"
                                            placeholder="First name"
                                            data-group="name"
                                            name="first"
                                            value={input["name"].first}
                                            onChange={handleChange}
                                        />
                                        <Form.Label>First name</Form.Label>
                                    </div>
                                    <div className="form-floating">
                                        <Form.Control
                                            className="input--box"
                                            type="text"
                                            placeholder="Middle Name"
                                            data-group="name"
                                            name="middle"
                                            value={input["name"].middle}
                                            onChange={handleChange}
                                        />
                                        <Form.Label>Middle name</Form.Label>
                                    </div>
                                    <div className="form-floating">
                                        <Form.Control
                                            className="input--box"
                                            type="text"
                                            placeholder="Last Name"
                                            data-group="name"
                                            name="last"
                                            value={input["name"].last}
                                            onChange={handleChange}
                                        />
                                        <Form.Label>Last name</Form.Label>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group
                                controlId="userInfo.email"
                                className="form--group"
                            >
                                <Form.Label className="form--label">
                                    Email
                                </Form.Label>
                                <Form.Control
                                    className="form--email"
                                    type="email"
                                    placeholder="Primary email address"
                                    data-group="email"
                                    name="primary"
                                    value={input["email"].primary}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group
                                style={{ flex: "1 1 0", width: 350 }}
                                className="form--group"
                            >
                                <Form.Label className="form--label">
                                    Phone Number
                                </Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="+1 123-456-789"
                                    data-group="phone"
                                    name="mobile"
                                    value={input["phone"].mobile}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <div className="addresses">
                                <Form.Label className="form--label">
                                    Addresses
                                </Form.Label>
                                <Templates.AddressList
                                    address={input["addresses"]}
                                    handleChange={handleFOL}
                                    modal={false}
                                />
                            </div>

                            <div className="row">
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Gender
                                    </Form.Label>
                                    <Form.Select
                                        data-group="identity"
                                        name="gender"
                                        value={input["identity"].gender}
                                        onChange={handleChange}
                                    >
                                        <option>Unspecified</option>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Other</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        1st Gen College Student
                                    </Form.Label>
                                    <Form.Select
                                        data-group="identity"
                                        name="firstGen"
                                        value={input["identity"].firstGen}
                                        onChange={handleChange}
                                    >
                                        <option>Unspecified</option>
                                        <option>No</option>
                                        <option>Yes</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Pronouns
                                    </Form.Label>
                                    <MultiSelectDropdown
                                        options={[
                                            "He",
                                            "Him",
                                            "She",
                                            "Her",
                                            "They",
                                            "Them",
                                            "Other",
                                            "Unspecified",
                                        ]}
                                        selected={input["pronouns"]}
                                        group={"pronouns"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Financial Aid Eligibility
                                    </Form.Label>
                                    <Form.Select
                                        data-group="identity"
                                        name="financialAidEligibility"
                                        value={
                                            input["identity"]
                                                .financialAidEligibility
                                        }
                                        onChange={handleChange}
                                    >
                                        <option>None</option>
                                        <option>Eligible</option>
                                        <option>Not Eligible</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Orientation
                                    </Form.Label>
                                    <Form.Select
                                        data-group="identity"
                                        name="orientation"
                                        value={input["identity"].orientation}
                                        onChange={handleChange}
                                    >
                                        <option>Unspecified</option>
                                        <option>Heterosexual</option>
                                        <option>Homosexual</option>
                                        <option>Bisexual</option>
                                        <option>Queer</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Veteran
                                    </Form.Label>
                                    <Form.Select
                                        data-group="identity"
                                        name="veteranStatus"
                                        value={input["identity"].veteranStatus}
                                        onChange={handleChange}
                                    >
                                        <option>Unspecified</option>
                                        <option>No</option>
                                        <option>Yes</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Ethnicity
                                    </Form.Label>
                                    <MultiSelectDropdown
                                        options={[
                                            "Hispanic or Latino",
                                            "White",
                                            "Black or African American",
                                            "Native Indian or Alaska Native",
                                            "Asian",
                                            "Native Hawaiian or Other Pacific Islander",
                                            "Two or More Races",
                                            "Decline to self-identify",
                                        ]}
                                        selected={input["ethnicity"]}
                                        group={"ethnicity"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Disabilities
                                    </Form.Label>
                                    <MultiSelectDropdown
                                        options={[
                                            "Yes",
                                            "No",
                                            "Decline to self-identify",
                                        ]}
                                        selected={input["disabilities"]}
                                        group={"disabilities"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </section>
                    <section id="education" className="user--info__section">
                        <h3 className="section--title">Education</h3>
                        <div className="section--information">
                            <div>
                                <Form.Label className="form--label">
                                    Degrees
                                </Form.Label>
                                <Templates.DegreeList
                                    degrees={input["degrees"]}
                                    handleChange={handleFOL}
                                    handleFileChange={
                                        handleTranscriptFileChange
                                    }
                                    modal={false}
                                />
                            </div>
                        </div>
                    </section>
                    <section id="workhistory" className="user--info__section">
                        <h3 className="section--title">Work History</h3>
                        <div className="section--information">
                            <div>
                                <Form.Label className="form--label">
                                    Experiences
                                </Form.Label>
                                <Templates.ExperienceList
                                    experiences={input["experiences"]}
                                    handleChange={handleFOL}
                                    modal={false}
                                />
                            </div>
                        </div>
                    </section>
                    <section id="profile" className="user--info__section">
                        <h3 className="section--title">Profile</h3>
                        <div className="section--information">
                            <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Resume
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleResumeChange}
                                />
                            </Form.Group>
                            <object
                                data={input["resume_link"]}
                                type="application/pdf"
                                width="100%"
                                height="1000"
                            >
                                <div>No PDF viewer available</div>
                            </object>
                            {/* <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Achievements
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="achievements"
                                    value={input["achievements"]}
                                    onChange={handleChange}
                                />
                            </Form.Group> */}
                            <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    LinkedIn
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="linkedin"
                                    value={input["linkedin"]}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Label className="form--label">
                                Projects
                            </Form.Label>
                            <Templates.ProjectList
                                projects={input["projects"]}
                                handleChange={handleFOL}
                                modal={false}
                            />
                            <div className="row mt-3">
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Other Skills
                                    </Form.Label>

                                    <MultiTextInput
                                        selected={input["otherSkills"]}
                                        group={"otherSkills"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Spoken Languages
                                    </Form.Label>
                                    {/* <Form.Control
									type="text"
									name="spokenLanguages"
									value={input["spokenLanguages"]}
									onChange={handleChange}
								/> */}
                                    <MultiSelectSearchDropdown
                                        options={languages}
                                        selected={[]}
                                        group={"spokenLanguages"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </section>
                    <section id="preferences" className="user--info__section">
                        <h3 className="section--title">Preferences</h3>
                        <div className="section--information">
                            <Form.Label
                                as="h4"
                                className="form--label"
                                style={{
                                    marginBottom: 12,
                                    fontWeight: "bold",
                                }}
                            >
                                The Job
                            </Form.Label>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "50% 50%",
                                    gridTemplateRows: "repeat(3, auto)",
                                    justifyContent: "space-between",
                                    rowGap: 24,
                                    columnGap: 24,
                                }}
                            >
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Interested Roles
                                    </Form.Label>
                                    <MultiTextInput
                                        selected={input["roles"]}
                                        group={"roles"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Interested Levels
                                    </Form.Label>
                                    {/* <Form.Control
                                        data-group="preferences"
                                        name="interestedLevels"
                                        value={
                                            input["preferences"]
                                                .interestedLevels
                                        }
                                        onChange={handleChange}
                                    /> */}
                                    <MultiSelectDropdown
                                        options={[
                                            "Intern",
                                            "Entry",
                                            "Associate",
                                            "Senior",
                                            "Management",
                                            "Director",
                                            "Executive",
                                        ]}
                                        selected={
                                            input["preferences"]
                                                .interestedLevels
                                        }
                                        group={"interestedLevels"}
                                        setSelections={setPreferenceSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Interested Type
                                    </Form.Label>
                                    {/* <Form.Control
                                        name="jobtypes"
                                        value={input["jobtypes"]}
                                        onChange={handleChange}
                                    /> */}
                                    <MultiSelectDropdown
                                        options={[
                                            "Full-time",
                                            "Part-time",
                                            "Contract",
                                            "Temporary",
                                            "Volunteer",
                                        ]}
                                        selected={input["jobtypes"]}
                                        group={"jobtypes"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Interested Modalities
                                    </Form.Label>
                                    {/* <Form.Control
                                        data-group="preferences"
                                        name="interestedModalities"
                                        value={
                                            input["preferences"]
                                                .interestedModalities
                                        }
                                        onChange={handleChange}
                                    /> */}
                                    <MultiSelectDropdown
                                        options={[
                                            "Remote",
                                            "On-Site",
                                            "Hybrid",
                                        ]}
                                        selected={
                                            input["preferences"]
                                                .interestedModalities
                                        }
                                        group={"interestedModalities"}
                                        setSelections={setPreferenceSelections}
                                    />
                                </Form.Group>
                                <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Desired Locations
                                    </Form.Label>
                                    <MultiTextInput
                                        selected={input["locations"]}
                                        group={"locations"}
                                        setSelections={setSelections}
                                    />
                                </Form.Group>
                                {/* <Form.Group className="form--group">
                                    <Form.Label className="form--label">
                                        Desired Salary Range
                                    </Form.Label>
                                    <Form.Control
                                        data-group="preferences"
                                        name="desiredSalaryRange"
                                        value={
                                            input["preferences"]
                                                .desiredSalaryRange
                                        }
                                        onChange={handleChange}
                                    />
                                </Form.Group> */}
                            </div>

                            {/* <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Company Size
                                </Form.Label>
                                <Form.Control
                                    data-group="preferences"
                                    name="companySize"
                                    value={input["preferences"].companySize}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Company Stage
                                </Form.Label>
                                <Form.Control
                                    data-group="preferences"
                                    name="companyStage"
                                    value={input["preferences"].companyStage}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Industry
                                </Form.Label>
                                <Form.Control
                                    data-group="preferences"
                                    name="industry"
                                    value={input["preferences"].industry}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Culture
                                </Form.Label>
                                <Form.Control
                                    data-group="preferences"
                                    name="culture"
                                    value={input["preferences"].culture}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="form--group">
                                <Form.Label className="form--label">
                                    Military Involvement
                                </Form.Label>
                                <Form.Control
                                    data-group="preferences"
                                    name="militaryInvolvement"
                                    value={
                                        input["preferences"].militaryInvolvement
                                    }
                                    onChange={handleChange}
                                />
                            </Form.Group> */}
                        </div>
                    </section>
                </div>
            </Form>
        </div>
    );
};

export default UserInfoPage;
