import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import * as Unicons from "@iconscout/react-unicons";
import DefaultLogo from "../../components/DefaultLogo";
import TruncatedText from "../../components/TruncatedText";

/*
 * To-do:
 * ☐ Update detail fields to read integers instead of strings
 * ☐ Build larger version of the job card for bigger screens
 */

/*
 * Props
 * - content: Structure containing all information to be displayed in a JobCard
 *   - title: String of job title
 *   - logo: Image link of company logo
 *   - description: HTML components describing position for card body
 *   - posted: Date or range job was first posted
 *   - details: Structure containing information displayed in left brief section
 *     - company: String name of company (optional)
 *     - industry: String name of industry (optional)
 *     - location: String pair of city, state where job is located (optional)
 *     - modality: String describing position's environment (optional)
 *     - level: String describing position's seniority (optional)
 *     - type: String describing position's commitment (optional)
 * - apply: Function call for the 'Apply' button
 * - save: Function call for the 'Save' button
 * - decline: Function call for the 'Decline' button
 * - disabled: Sets state of buttons for when card is/is not on top of the stack
 */

function JobCard(props) {
    function buildBrief(details) {
        let detailList = []; // React Element List

        if (details.company) {
            detailList.push(<Unicons.UilBuilding size="24" color="#000000" />);
            detailList.push(<TruncatedText text={details.company} />);
        }
        if (details.industry) {
            detailList.push(
                <Unicons.UilBriefcaseAlt size="24" color="#000000" />
            );
            detailList.push(<TruncatedText text={details.industry} />);
        }
        if (details.location) {
            detailList.push(
                <Unicons.UilLocationPoint size="24" color="#000000" />
            );
            detailList.push(<h5>{details.location}</h5>);
        }
        if (details.modality) {
            detailList.push(
                <Unicons.UilCarSideview size="24" color="#000000" />
            );
            detailList.push(<h5>{details.modality}</h5>);
        }
        if (details.level) {
            detailList.push(
                <Unicons.UilGameStructure size="24" color="#000000" />
            );
            detailList.push(<h5>{details.level}</h5>);
        }
        if (details.type) {
            detailList.push(<Unicons.UilClock size="24" color="#000000" />);
            detailList.push(<h5>{details.type}</h5>);
        }

        return detailList;
    }

    return (
        <Card className="border-0" style={{ maxWidth: 1000, margin: "auto" }}>
            {/* Header */}
            <Card.Header
                className="text-white"
                style={{
                    height: 90,
                    display: "flex",
                    alignItems: "center",
                    gap: 12,
                    background: "#19186B",
                }}
            >
                {/* Logo */}
                <div
                    style={{
                        height: 64,
                        width: 64,
                    }}
                >
                    {props.content.logo ? (
                        <Image
                            src={props.content.logo}
                            rounded
                            style={{ height: 64 }}
                        />
                    ) : (
                        <DefaultLogo title={props.content.details.company} />
                    )}
                </div>
                <h3 style={{ width: 895 }}>{props.content.title}</h3>
            </Card.Header>
            {/* Body */}
            <div
                style={{
                    height: 400,
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                {
                    /* Details Brief */
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "24px 210px",
                            gridTemplateRows: "repeat(6, 34px)",
                            gap: 6,
                            width: 270,
                            padding: 20,
                            background: "#EFEEFB",
                        }}
                    >
                        {buildBrief(props.content.details)}
                    </div>
                }
                <div
                    style={{
                        width: 730,
                        display: "flex",
                        flex: "1 0 0",
                        padding: 10,
                        overflowX: "visible",
                        overflowY: "auto",
                    }}
                >
                    {props.content.description}
                </div>
            </div>
            {/* Footer */}
            <Card.Footer
                className="text-white"
                style={{
                    background: "#19186B",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 6,
                        }}
                    >
                        <Unicons.UilCalender size="24" color="#FFFFFF" />
                        {"Posted " + props.content.posted}
                    </div>
                    {/* Action Buttons */}
                    <div style={{ display: "flex", gap: 10 }}>
                        <Button
                            variant="secondary"
                            className="applicationButton"
                            style={{
                                width: "115px",
                                borderRadius: 999,
                                background: "#fff",
                                color: "#1c1c1c",
                            }}
                            disabled={props.disabled}
                            onClick={() => props.decline(props.content.jobID)}
                        >
                            Decline
                        </Button>
                        <Button
                            variant="secondary"
                            className="applicationButton"
                            style={{
                                width: "115px",
                                borderRadius: 999,
                                background: "#fff",
                                color: "#1c1c1c",
                            }}
                            disabled={props.disabled}
                            onClick={() => props.save(props.content.jobID)}
                        >
                            Save
                        </Button>
                        <Button
                            variant="primary"
                            className="applicationButton"
                            style={{
                                width: "115px",
                                borderRadius: 999,
                                fontWeight: "700",
                                border: "none",
                                background:
                                    "linear-gradient(109.8deg, #8723BE 23.96%, #4169F0 100%)",
                            }}
                            disabled={props.disabled}
                            onClick={() => props.apply(props.content)}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Card.Footer>
        </Card>
    );
}

export default JobCard;
