import { Auth } from "aws-amplify";

const getUser = (userInfo) => {
  Auth.currentAuthenticatedUser().then(userInfo);
};

const signIn = (username, password, callback) => {
  Auth.signIn(username, password)
    .then(callback)
    .then((err) => console.log(err));
};

const signOut = (callback) => {
  Auth.signOut()
    .then(callback)
    .then((err) => console.log(err));
};

const getIdToken = async () => {
  let data = await Auth.currentSession();
  return data.getIdToken().jwtToken;
};

const getAccessToken = (callback) => {
  Auth.currentSession().then((data) => {
    callback(data.getAccessToken().jwtToken);
  });
};

const getCurrentSession = (callback) => {
  Auth.currentSession()
    .then((data) => callback(data))
    .then((err) => console.log(err));
};

const getRefreshToken = async () => {
  let data = await Auth.currentSession();
  return data.getRefreshToken().token;
};

async function signUp({ username, password, email }, updateFormType) {
  try {
    await Auth.signUp({
      username,
      password,
      attributes: { email },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: true,
      },
    });
    console.log("sign up success!");
    updateFormType("confirmSignUp");
  } catch (err) {
    console.log("error signing up..", err);
  }
}
async function confirmSignUp({ username, confirmationCode }, updateFormType) {
  try {
    await Auth.confirmSignUp(username, confirmationCode);
    updateFormType("successful");
  } catch (err) {
    console.log("error signing up..", err);
  }
}
async function forgotPassword({ username }, updateFormType) {
  try {
    await Auth.forgotPassword(username);
    updateFormType("forgotPasswordSubmit");
  } catch (err) {
    console.log("error submitting username to reset password...", err);
  }
}

async function forgotPasswordSubmit(
  { username, confirmationCode, password },
  updateFormType
) {
  try {
    await Auth.forgotPasswordSubmit(username, confirmationCode, password);
    updateFormType("successful");
  } catch (err) {
    console.log("error updating password... :", err);
  }
}

export {
  signIn,
  signOut,
  getUser,
  getIdToken,
  getAccessToken,
  getRefreshToken,
  getCurrentSession,
  signUp,
  confirmSignUp,
  forgotPassword,
  forgotPasswordSubmit,
};
