import { useEffect } from "react";
import { useNavigate } from "react-router";

const Redirect = (props) => {
    const { url } = props;
    let navigate = useNavigate();

    useEffect(() => {
        navigate(url);
    }, [navigate, url]);

    return (
        <div>Loading...</div>
    )
}

export default Redirect;
