import { ToastContainer } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";

function SubmitToast(props) {
    const { result, toggleShowToast, showToast } = props;
    return (
        <ToastContainer className="position-absolute top-0 end-0 p-3 mb-2 text-black rounded-5">
            <Toast
                className="d-inline-block m-1"
                show={showToast}
                bg={"success"}
                onClose={toggleShowToast}
                variant="success"
            >
                <Toast.Header className="bg-success text-black">
                    <strong className="me-auto">{result}</strong>
                </Toast.Header>
            </Toast>
        </ToastContainer>
    );
}

export default SubmitToast;
