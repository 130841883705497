import * as Unicons from "@iconscout/react-unicons";

/*
 * Props
 * - back: Sets arrow to left-facing (boolean, optional, default right-facing arrow)
 * - text: String displayed below icon (optional, default empty)
 */

function FormArrow(props) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 12,
			}}
		>
			<div
				onClick={props.onClick}
				style={{
					width: 60,
					height: 60,
					backgroundColor: "white",
					borderRadius: "50%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					cursor: props.onClick ? "pointer" : "",
				}}
			>
				{props.back ? (
					<Unicons.UilArrowLeft size="60" color="#19186B" />
				) : (
					<Unicons.UilArrowRight size="60" color="#19186B" />
				)}
			</div>
			<h6
				className="text-white"
				style={{ fontWeight: "bold", textAlign: "center" }}
			>
				{props.text ? props.text : ""}
			</h6>
		</div>
	);
}

export default FormArrow;
